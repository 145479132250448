import React, { useState, useEffect, useRef } from "react";
import ProductItemProgram from "./productItemProgram";
import { isUndefined } from "../../../../../state/helper/functions";

// ON SCROLL OR ON CLICK CLOSE PROGRAM ON PLP

function useComponentVisible(initialIsVisible, setProgram) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );

  const ref = useRef(null);

  useEffect(() => {
    // document.addEventListener("scroll", handleClickOutside, true);
    // document.addEventListener("click", handleClickOutside, true);
    // let programs = document.getElementsByClassName("wrapper-inside-program");
    // Object.keys(programs).map(program => {
    //   let currentProgram = programs[program];
    //   currentProgram.addEventListener(
    //     "mouseenter",
    //     e => {
    //       document.removeEventListener("scroll", handleClickOutside, true);
    //     },
    //     true
    //   );
    // });
    // return () => {
    //   document.removeEventListener("click", handleClickOutside, true);
    //   document.removeEventListener("scroll", handleClickOutside, true);
    //   let programs = document.getElementsByClassName("wrapper-inside-program");
    //   Object.keys(programs).map(program => {
    //     let currentProgram = programs[program];
    //     currentProgram.removeEventListener(
    //       "mouseenter",
    //       e => {
    //         document.addEventListener("scroll", handleClickOutside, true);
    //       },
    //       true
    //     );
    //   });
    // };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

const ProductItemProgramStateContainer = props => {
  let { setProgram } = props;
  const [name, setName] = useState("");
  const { ref, isComponentVisible } = useComponentVisible(true, setProgram);

  return (
    <div ref={ref}>
      {(isComponentVisible && (
        <ProductItemProgram {...props} name={name} setName={setName} />
      )) ||
        (!isComponentVisible && (
          <ProductItemProgram {...props} name={name} setName={setName} />
        ))}
    </div>
  );
};

export default ProductItemProgramStateContainer;
