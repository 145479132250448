import React from "react";
import { connect } from "react-redux";
import { isUndefined } from "../../../../../../../state/helper/functions";
import ReactLoader from "../../../../../../../utils/loader";
import { exportListActions } from "../../../../../../../state/redux/data/exportList";
import ProductItemStateContainer from "./productItem-state-container";
import { messagesActions } from "../../../../../../../state/redux/data/messages";

const ProductItemReduxContainer = props => {
  if (isUndefined(props.allCategories) || isUndefined(props.allCategories[2])) {
    return <ReactLoader />;
  }
  let allCategories = props.allCategories[2].data.category;
  return <ProductItemStateContainer {...props} allCategories={allCategories} />;
};

const mapStateToProps = state => ({
  category_data: state.queries,
  allCategories: state.queries.data
});

const mapDispatchToProps = dispatch => {
  return {
    addExportListData: data => {
      dispatch(exportListActions.exportListData(data));
    },
    addMessage: message => {
      dispatch(messagesActions.addMessage(message, "danger"));
    },
    toggleDownload: newStatus => {
      dispatch(
        exportListActions.toggleExportListDownloadAvailability(newStatus)
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductItemReduxContainer);
