import apolloClient from "../../../../graphql/apolloClient";
import customerActions from "../actions";
import queryLoader from "../../../../graphql/queryLoader";
import messagesActions from "../../messages/actions";
import commonActions from "../../common/actions";
import _validateCustomerToken from "./_validateCustomerToken";

const EDIT_CUSTOMER_ADDRESS = "editCustomerAddress";

export default async (store, action) => {
  try {
    let authorized = await _validateCustomerToken(store, action);

    if (authorized) {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader(EDIT_CUSTOMER_ADDRESS),
        variables: action.request
      });

      if (data && data.updateCustomerAddress) {
        // let token = store.getState().state.customer.token;
        await store
          .dispatch(
            customerActions._reduceCustomerAddress(data.updateCustomerAddress)
          )
          .then(
            store.dispatch(
              messagesActions.addMessage(
                "Successfully updated address information.",
                "success"
              )
            )
          );
      }
    }
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
