export function filterAddressById(props, id) {
  if (!props.customer && props.customer === []) return [];

  let addresses = props.customer.addresses;
  let editAddress = addresses.filter(function(address) {
    // eslint-disable-next-line
    return address.id == id;
  });

  return editAddress[0];
}

export const filterByCountryId = (country_id, countries) => {
  if (country_id && countries === []) return [];

  let selectedCountry = countries.filter(country => {
    //eslint-disable-next-line
    return country.value == country_id;
  });

  return selectedCountry[0];
};

export const getSelectedRegionIfCountryHasRegions = address => {
  return {
    label: address.region.region,
    value: address.region.region_id,
    code: address.region.region_code
  };
};
