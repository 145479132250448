import types from "./types";
import _reduceFreightInformation from "./reducers/_reduceFreightInformation";
import _reduceFreightStatus from "./reducers/_reduceFreightStatus";

// Main reducer
const freightQuoteReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }

  if (action.type === types._REDUCE_FREIGHT_INFORMATION) {
    return _reduceFreightInformation(state, action);
  }

  if (action.type === types._REDUCE_FREIGHT_STATUS) {
    return _reduceFreightStatus(state, action);
  }

  return state;
};

export default freightQuoteReducer;
