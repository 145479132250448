// Action types
const ADD_QUERY = "jola/queries/ADD_QUERY";
const ADD_QUERY_SUCCESS = "jola/queries/ADD_QUERY_SUCCESS";
const ADD_QUERY_DATA = "jola/queries/ADD_QUERY_DATA";
const ADD_QUERY_DATA_SUCCESS = "jola/queries/ADD_QUERY_DATA_SUCCESS";
const REMOVE_QUERY = "jola/queries/REMOVE_QUERY";
const REMOVE_SPECIFIC_QUERY = "jola/queries/REMOVE_SPECIFIC_QUERY";
const REMOVE_ALL_QUERIES = "jola/queries/REMOVE_ALL_QUERIES";
const HIDE_SPLASH = "jola/queries/HIDE_SPLASH";

export default {
  ADD_QUERY,
  ADD_QUERY_SUCCESS,
  ADD_QUERY_DATA,
  ADD_QUERY_DATA_SUCCESS,
  REMOVE_ALL_QUERIES,
  REMOVE_QUERY,
  REMOVE_SPECIFIC_QUERY,
  HIDE_SPLASH
};
