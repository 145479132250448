import { isUndefined } from "../../../state/helper/functions";

export const createRequestFromFilters = (filters, category_id, props) => {
  if (isUndefined(filters)) {
    if (window.location.href.indexOf("brand") > -1) {
      // return { filter: { brand: { eq: category_id } } };
    }
    return { filter: { category_id: { eq: category_id } } };
  }

  let request = { filter: { category_id: { eq: 0 } } };

  let multipleFilter = [];
  Object.keys(filters).map(filter => {
    if (filters[filter].filter_type === "multiple") {
      let filter_key = filter.split("_")[0];
      if (isUndefined(multipleFilter[filter_key]))
        multipleFilter[filter_key] = "";
      return (multipleFilter[filter_key] =
        multipleFilter[filter_key] +
        filters[filter].filter_value.toString() +
        ",");
    }
    return "";
  });

  Object.keys(filters).forEach(filter => {
    let _filter = remapFilterId(filter);
    if (!isUndefined(multipleFilter[filter.split("_")[0]])) {
      request["filter"][filter.split("_")[0]] = {
        in: multipleFilter[filter.split("_")[0]].split(",")
      };
    } else if (!isUndefined(filters[filter].filter_value)) {
      request["filter"][_filter] = { eq: filters[filter].filter_value };
    } else {
      let keys = Object.keys(filters[_filter]);

      // check to see if it's from/to filter
      if ("from" === keys[0] && "to" === keys[1]) {
        request["filter"][_filter] = {
          [keys[0]]: filters[_filter].from.filter_value,
          [keys[1]]: filters[_filter].to.filter_value
        };
      }
    }
    return true;
  });
  return request;
};

export const createSortVariable = sort => {
  if (isUndefined(sort)) {
    return { created_at: "ASC" };
  }

  return sort;
};

const remapFilterId = filter => {
  let _filter = filter;
  if (filter === "category") {
    _filter = "category_id";
  }
  return _filter;
};
