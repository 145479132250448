import React from "react";
import Image from "../../../../utils/image";
import Usp from "../../home/services/usp";
import Button from "../../../../utils/buttons";
import ContentHeader from "../../../../utils/contentHeader";
import "./services.scss";

const Services = props => {
  return (
    <div className="home-services">
      <div className="home-services-top">
        <div className="home-services-top-inner">
          <div className="home-services-left">
            <Image
              source={"What-We-Offer.jpg"}
              image_alt={`What Brandation offers`}
            />
          </div>
          <div className="home-services-right">
            <div className="home-services-right-inner">
              <ContentHeader
                heading={"Our Services"}
                subHeading={"What We Offer"}
              />
              <p>
                Brandation offers a wide range of products and services for
                companies of all sizes. Everything from premium promotional
                gifts, product for retail stores and online. Our services
                include private label design, marketing material print,
                logistical services and more!
              </p>
              <Button
                className={
                  "button btn-with-right-icon btn-white-border-shadow right-arrow"
                }
                text={"Register Now"}
                icon={"rightArrowOrange"}
                to={"/register"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="home-services-bottom">
        <div className="usps-cont">
          <div className="usps">
            <div className="top">
              <Usp i="giftBlack" title="PREMIUM AND CORPORATE GIFTS" />
              <Usp i="cupBlack" title="PROMOTIONAL AND EVENT MERCHANDISE" />
              <Usp i="shippingBlack" title="MANUFACTURING AND WHOLESALE" />
            </div>
            <div className="bottom">
              <Button
                className={"button btn-with-right-icon btn-orange right-arrow"}
                text={"Learn More "}
                icon={"rightArrow"}
                to={"/services"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
