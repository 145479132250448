import React from "react";
import ActiveFilter from "./activeFilter";
import { isUndefined } from "../../../state/helper/functions";

import "./sidebar.scss";
import { getFiltersRender } from "./functions";

const Sidebar = props => {
  let {
    category,
    filtered_category,
    selected_category,
    addFilter,
    removeFilter,
    categoryFilters,
    allCategories,
    filters
  } = props;
  if (isUndefined(filtered_category)) return "";

  //let hasItems = !isEmpty(filtered_category.items);

  if (isUndefined(category)) return "";
  //let itemCount = hasItems ? category.products.total_count : 0;

  return (
    <>
      <div id="sidebar" className={`sidebar`}>
        <div className="sidebar-inner-content">
          {/* <div className={`locked-${locked}`}></div> */}
          <div className="filters">
            <ActiveFilter
              removeFilter={props.removeFilter}
              clearFilters={props.clearFilters}
            />

            <>
              <h3>Filters</h3>
              {categoryFilters.filters &&
                getFiltersRender(
                  categoryFilters.filters,
                  selected_category,
                  filtered_category,
                  addFilter,
                  removeFilter,
                  allCategories,
                  filters
                )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
