export default {
    menuItems: [
      {
        active: false,
        id: 1,
        name: "Products",
        link: '/products'
      },
      {
        active: false,
        id: 2,
        name: "Brands",
        link: '/brands'
      },
      {
        active: false,
        id: 3,
        name: "Services",
        link: "/services"
      },
      {
        active: false,
        name: "About Us",
        id: 4,
        link: "/about-us"
      },
      {
        active: false,
        id: 5,
        name: "Contact",
        link: "/contact"
      }
    ]
  };
  