import React, { useState } from "react";
import { Link } from "react-router-dom";
import PricingInformation from "../../../products/pricingInformation";
import Image from "../../../../utils/image";
import Button from "../../../buttons";
import "./productItem.scss";
import { isUndefined } from "../../../../state/helper/functions";
import ProductItemProgram from "./productItemProgram";
import {
  createCategoryImageUrl,
  createProductImageUrl,
  getBrandImageByCategoryId
} from "../../../../state/helper/category";

const ProductItem = props => {
  let { product, addSkuToList, removeSkuFromList, program } = props;
  const [image, setImage] = useState(product.image.url);

  //let countrySelect = "";
  let exportListOptions = [];
  let productName = product.name;
  let productNumber = product.sku;
  let productItemStock = product.warehouse_qty;
  let productArrivalQty = product.arrival_qty;
  let productItemDate = product.last_updated_date;
  let productLeadTime = product.lead_time;
  let productMOQ = product.min_order_qty_stock;
  let productImageUrl = product.image.url;
  let productUrl = product.url_key;
  let productImageLabel = product.image.label;

  const [button, setButton] = useState(false);

  const onPress = () => {
    setButton(true);
    setTimeout(() => {
      setButton(false);
    }, 1000);
  };
  let brandImage = getBrandImageByCategoryId(
    product.categories,
    props.allCategories
  ).filter(item => {
    return item.path.split("/").length === 5;
  })[0];

  //check if exportListUndefined
  if (!isUndefined(props.exportList)) {
    //is empty
    if (!isUndefined(props.exportList.items)) {
      // go through list
      exportListOptions = props.exportList.items.map(item => {
        // check if skuList is not empty
        if (item.skuList !== null) {
          // split string into array
          let skuList = item.skuList.split(",");
          // check if product SKU exists in list if exists do nothing , if not return item.name as label and item.listId as value
          if (skuList.indexOf(props.productNumber) > -1) {
            return { label: item.name, value: item.listId };
          } else {
            return { label: item.name, value: item.listId };
          }
        } else {
          return { label: item.name, value: item.listId };
        }
      });
    }
    exportListOptions = exportListOptions.filter(function (el) {
      return el != null;
    });
  }

  // let isInExportList = false;
  // if (props.exportList.items[0].skuList !== null) {
  //   isInExportList = props.exportList.items[0].skuList.includes(productNumber);
  // }

  return (
    <div
      className="product-item-content"
      id={props.value}
      key={props.index}
      onClick={e => {
        if (!isUndefined(props.onClick)) {
          props.onClick();
        }
      }}
    >
      <div className="product-item-image product-item-part">
        <Link to={`/${productUrl}`}>
          <Image
            source={image}
            onMouseEnter={e => {
              if (!isUndefined(product.media_gallery_entries)) {
                if (!isUndefined(product.media_gallery_entries[1])) {
                  setImage(
                    createProductImageUrl(product.media_gallery_entries[1].file)
                  );
                }
              }
            }}
            onMouseLeave={e => {
              setImage(productImageUrl);
            }}
            imgContainer="product-image"
            image_alt={productName + " " + productImageLabel}
            className={`image-list`}
          />
        </Link>
      </div>
      <div className="product-item-buttons product-item-part">
        {/* {exportListOptions && (
          <div className={`save-action`}>
            <Button
              className={`button btn-with-left-icon btn-white-disabled btn-save plp-button ${(isInExportList &&
                "btn-orange btn-saved") ||
                ""}`}
              text={
                (button && "Saving") || (isInExportList && "Saved") || "Save"
              }
              icon={(isInExportList && "excelWhite") || "excelGray"}
              onClick={e => {
                if (!isInExportList) {
                  addSkuToList(exportListOptions[0].value, productNumber);
                } else {
                  removeSkuFromList(exportListOptions[0].value, productNumber);
                }

                onPress();
              }}
              Updated
            />
          </div>
        )} */}
        {/* <ProductItemProgram
          exportList={props.exportList}
          sku={productNumber}
          createExportList={props.createExportList}
          addMessage={props.addMessage}
          program={program}
          setProgram={props.setProgram}
        /> */}
      </div>
      <div className="product-item-info product-item-part">
        <div className="product-company-logo">
          {(!isUndefined(brandImage) && (
            <Image
              source={createCategoryImageUrl(brandImage.thumbnail_image)}
              image_alt={"Company brand logo"}
              imgContainer="company-logo"
            />
          )) || (
              <Image
                source={"badgelogo.jpg"}
                image_alt={"Brandation logo"}
                imgContainer="company-logo brandation-logo"
              />
            )}
        </div>
        <div>
          <p className="product-name">{productName}</p>
          <p className="product-number">{productNumber}</p>
        </div>
      </div>
      {/* <div className="product-item-part">
        <PricingInformation
          product={product}
          parentClass={props.parentClass}
          customer={props.customer}
        />
      </div> */}

      <div className="product-item-additional-info product-item-part">
        <p className="product-item-stock">
          {(productItemStock > 0 && "Stock | " + productItemStock) ||
            (productArrivalQty !== null && "Arrival | " + productArrivalQty) ||
            "Arrival | Soon"}
        </p>

        {productItemDate && (
          <p className="product-item-date">{"Updated | " + productItemDate}</p>
        )}
      </div>
      <div className="product-item-additional-info product-item-part">
        <p className="product-item-lead-time">
          {productLeadTime &&
            "Lead Time: " + productLeadTime.replace("Lead", "")}
        </p>

        {productMOQ && <p className="product-item-moq">MOQ | {productMOQ}</p>}
      </div>
    </div>
  );
};
export default ProductItem;
