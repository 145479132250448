// Middleware
import types from "./types";
import loginCustomer from "./middleware/loginCustomer";
import setCustomerToken from "./middleware/setCustomerToken";
import logoutCustomer from "./middleware/logoutCustomer";
import getCustomerInformation from "./middleware/getCustomerInformation";
import setCustomerInformation from "./middleware/setCustomerInformation";
import registerCustomer from "./middleware/registerCustomer";
import updateCustomerInformation from "./middleware/updateCustomerInformation";
import forgotCustomerPassword from "./middleware/forgotCustomerPassword";
import resetCustomerPassword from "./middleware/resetCustomerPassword";
import editCustomerAddress from "./middleware/editCustomerAddress";
import _validateCustomerToken from "./middleware/_validateCustomerToken";
// import _validateCustomerToken from "./middleware/_validateCustomerToken";

export default store => {
  return next => {
    return async action => {
      next(action);
      switch (action.type) {
        case types.LOGIN_CUSTOMER:
          return loginCustomer(store, action);

        case types.LOGOUT_CUSTOMER:
          return logoutCustomer(store, action);

        case types.SET_CUSTOMER_TOKEN:
          return setCustomerToken(store, action);

        case types.GET_CUSTOMER_INFORMATION:
          return getCustomerInformation(store, action);

        case types.SET_CUSTOMER_INFORMATION:
          return setCustomerInformation(store, action);

        case types.REGISTER_CUSTOMER:
          return registerCustomer(store, action);

        case types.UPDATE_CUSTOMER_INFORMATION:
          return updateCustomerInformation(store, action);

        case types.FORGOT_CUSTOMER_PASSWORD:
          return forgotCustomerPassword(store, action);

        case types.RESET_CUSTOMER_PASSWORD:
          return resetCustomerPassword(store, action);

        case types.EDIT_CUSTOMER_ADDRESS:
          return editCustomerAddress(store, action);

        case types._VALIDATE_CUSTOMER_TOKEN:
          return _validateCustomerToken(store, action);

        default:
          return;
      }
    };
  };
};
