import { applyMiddleware } from "redux";
import { customerMiddleware } from "./data/customer";
import { commonMiddleware } from "./data/common";
import { queriesMiddleware } from "./data/queries";
import { exportListMiddleware } from "./data/exportList";
import { filterMiddleware } from "./data/filters";
import { freightQuoteMiddleware } from "./data/freightQuote";

export const globalMiddleware = applyMiddleware(
  customerMiddleware,
  commonMiddleware,
  queriesMiddleware,
  exportListMiddleware,
  filterMiddleware,
  freightQuoteMiddleware
);
