import React from "react";
import Breadcrumbs from "../../../utils/breadcrumbs";
import ContactUsForm from "../../forms/contactPage";
import CmsBlock from "../../../utils/cmsBlock";
import HeroImage from "../../../utils/heroImage";
import themeConfiguration from "../../../state/config/themeConfiguration";
import { Helmet } from "react-helmet";
import "./contact.scss";

const ContactPage = props => {
  // let style = {
  //   width: "100%",
  //   height: "100%"
  // };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact - {themeConfiguration.name}</title>
        <link rel="canonical" href={`${themeConfiguration.app_url}/contact`} />
      </Helmet>
      <div className="contact-us-container">
        <div className="main-banner">
          <HeroImage source={"ContactUs.jpg"} alt={"About Us banner image"} />
        </div>
        <Breadcrumbs
          links={[
            { url: "/", title: "Home | " },
            { url: "#", title: "Contact Us" }
          ]}
        />
        <div className="contact-us-form">
          <ContactUsForm sendEmail={props.sendEmail} />
        </div>
        <div className="block-info-container">
          <CmsBlock dangHtml={true} id="contact_us_info" loader={false} />
        </div>
        <div className="block-map-container">
          <CmsBlock dangHtml={true} id="home-map" loader={false} />
        </div>
      </div>
    </>
  );
};

export default ContactPage;
