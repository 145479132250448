import React from "react";
import RegularMenu from "./regular-menu";
import { isEmpty, isUndefined } from "../../state/helper/functions";

const RegularMenuDataContainer = props => {
  let currentCategory;
  if (isUndefined(props.data) || isUndefined(props.data.category)) return "";
  if (!isEmpty(props.data)) {
    currentCategory = props.data.category.children.filter(child => {
      return child.id === props.item.category_id;
    })[0];
  }

  if (!currentCategory) {
    return "";
  }

  return <RegularMenu {...props} currentCategory={currentCategory} />;
};

export default RegularMenuDataContainer;
