import React from "react";
import "./priceBox.scss";
import PricingInformation from "../../../../../utils/products/pricingInformation";
// import { determineCurrency } from "./functions";
import { isUndefined } from "../../../../../state/helper/functions";
import { determineCurrency } from "../../../../../state/config/currency";

const PriceBox = props => {
  let { customer, tier_prices, price } = props;
  if (isUndefined(price)) return "";

  // const currency = determineCurrency(price.regularPrice.amount.currency);

  return (
    <div id="pricebox">
      <div className={"price-label"}>
        {/* <h4> Pricing: </h4> */}
      </div>
      <div className="price-info-wrapper">
        <PricingInformation
          productMsrp={"12"}
          productPriceRegular={price.regularPrice.amount.value}
          textMsrp={"Msrp"}
          textPriceRegular={"Regular"}
          parentClass={"pricing-info-color"}
          productMsrpExist={"show-product-msrp"}
          tierPrices={tier_prices}
          product={props}
          customer={customer}
        />
      </div>
    </div>
  );
};

export default PriceBox;
