export let responsiveData = {
  0: {
    items: 1
  },
  640: {
    items: 2
  },
  768: {
    items: 2
  },
  1024: {
    items: 4
  }
};

export default {};
