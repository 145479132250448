import React, { useState } from "react";
import CustomerInformation from "./information";

const CustomerInformationFormStateContainer = props => {
  const [firstname, setFirstname] = useState(props.customer.firstname);
  const [lastname, setLastname] = useState(props.customer.lastname);
  const [email, setEmail] = useState(props.customer.email);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [changeEmail, setChangeEmail] = useState(false);
  const [changePassword, setChangePassword] = useState(
    window.location.search === "?changepassword" ? true : false
  );
  return (
    <CustomerInformation
      {...props}
      firstname={firstname}
      setFirstname={setFirstname}
      lastname={lastname}
      setLastname={setLastname}
      email={email}
      setEmail={setEmail}
      currentPassword={currentPassword}
      setCurrentPassword={setCurrentPassword}
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      changeEmail={changeEmail}
      setChangeEmail={setChangeEmail}
      changePassword={changePassword}
      setChangePassword={setChangePassword}
    />
  );
};

export default CustomerInformationFormStateContainer;
