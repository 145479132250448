import React, { useState } from "react";
import Register from "./register";
import { CSSTransition } from "react-transition-group";

const RegisterStateContainer = props => {
  const [firstStep, setFirstStep] = useState(true);
  const [secondStep, setSecondStep] = useState(false);

  return (
    <CSSTransition
      key={"registration-container"}
      classNames={"fade"}
      appear={true}
      in={true}
      timeout={1000}
    >
      <Register
        {...props}
        firstStep={firstStep}
        setFirstStep={setFirstStep}
        secondStep={secondStep}
        setSecondStep={setSecondStep}
      />
    </CSSTransition>
  );
};

export default RegisterStateContainer;
