import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";

function transform(node, index) {
  if (node.type === "tag" && node.name === "a") {
    if (node.attribs.href.includes("http")) {
      return convertNodeToElement(node, index, transform);
    }
    let link = "";
    if (node.attribs.href) {
      link = node.attribs.href;
    }
    if (link.includes("mailto:") || link.includes("tel:"))
      return convertNodeToElement(node, index, transform);
    let linkComponent = (
      <Link
        to={`${node.attribs.href}`}
        {...node.attribs}
        key={node.attribs.href}
      >
        {node.children.map(item => {
          return convertNodeToElement(item, index, transform);
        })}
      </Link>
    );
    return linkComponent;
  }
  return convertNodeToElement(node, index, transform);
}
const Parser = props => {
  let { data, cssClass } = props;

  if (!data || !data.cmsBlocks || !data.cmsBlocks.items) {
    // console.log("Invalid data", props);
    return <></>;
  }

  const cmsBlock = data.cmsBlocks.items[0];

  let newContent = cmsBlock.content;
  return (
    <div
      id={"cmsblock-" + cmsBlock.identifier}
      className={`cmsblock ${cssClass && cssClass}`}
    >
      {ReactHtmlParser(newContent, {
        decodeEntities: true,
        transform: transform
      })}
    </div>
  );
};
export const PageParser = props => {
  let { data, cssClass } = props;
  if (
    !data ||
    !data.content ||
    !data.content_heading ||
    !data.page_layout ||
    !data.title ||
    !data.url_key
  ) {
    // console.log("Invalid data", props);
    return <></>;
  }

  const cmsPage = data;

  let newContent = cmsPage.content;
  return (
    <div
      id={"cmsPage-" + cmsPage.identifier}
      className={`cmsPage ${cssClass && cssClass}`}
    >
      {ReactHtmlParser(newContent, {
        decodeEntities: true,
        transform: transform
      })}
    </div>
  );
};

export default Parser;
