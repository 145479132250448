import React from "react";
import { isUndefined } from "../../state/helper/functions";
import "./heroImage.scss";

const HeroImage = props => {
  let hostname =
    window.location.hostname === "localhost"
      ? window.location.hostname + ":3000"
      : window.location.hostname;
  let protocol = window.location.protocol;

  let source = protocol + "//" + hostname + "/images/" + props.source;
  let alt = !isUndefined(props.alt) ? props.alt : "";
  return (
    <div className="main-banner" src={props.src}>
      <img src={source} alt={alt} style={props.style} />
    </div>
  );
};

export default HeroImage;
