import React from "react";
import CustomerAccountAddressBook from "../../pages/dashboard/account-addresses";

const CustomerAddressBook = props => {
  return (
    <div className={"content"}>
      <h1>ADDRESS BOOK</h1>
      <CustomerAccountAddressBook {...props} />
    </div>
  );
};

export default CustomerAddressBook;
