import React, { useState } from "react";
import RegisterReduxContainer from "./register-redux-container";
import { default as countries_data } from "../../../state/config/data/countries";
import {
  getCountries,
  getRegionData
} from "../../../state/config/data/dataMapping";
import { filterByCountryId } from "../../../state/helper/customer";

const RegisterDataContainer = props => {
  const [isRegistered, setIsRegistered] = useState(false);
  const countries = countries_data.countries;
  let countryDataObject = getCountries(countries);
  let countriesData;
  countriesData = Object.keys(countryDataObject).map(key => {
    return countryDataObject[key];
  });

  let selectedCountry = filterByCountryId("US", countriesData);
  let regionsDataObject = getRegionData(countriesData, selectedCountry);
  return (
    <RegisterReduxContainer
      isRegistered={isRegistered}
      setIsRegistered={setIsRegistered}
      countries={countries}
      regionsDataObject={regionsDataObject}
    />
  );
};

export default RegisterDataContainer;
