export let menuItemsContent = {
  mobileMenuActive: 0,
  mobileSubMenuActive: 0,
  activeTab: 0,
  account: [
    { id: 0, name: "Enter Portal", links: 0, link: "/login" },
    { id: 1, name: "Register", links: 0, link: "/register" }
  ],
  menuItems: [
    {
      active: false,
      id: 1,
      name: "Products",
      title: true,
      image: true,
      query: "fetchProductCategories",
      category_id: 13,
      link: "#",
      loggedInCustomer: true,
      all: "/products"
    },
    {
      active: false,
      id: 2,
      name: "Brands",
      title: false,
      image: false,
      brand: true,
      query: "fetchBrands",
      category_id: 10,
      house_brand_id: 11,
      national_brand_id: 12,
      link: "#",
      loggedInCustomer: true,
      all: "/brands"
    },
    {
      active: false,
      id: 3,
      name: "Services",
      alt: "Our Services",
      link: "/services"
    },
    {
      active: false,
      name: "About Us",
      id: 4,
      alt: "About Us",
      link: "/about-us"
    },
    {
      active: false,
      id: 5,
      name: "Contact",
      link: "/contact"
    }
  ]
};
export default menuItemsContent;
