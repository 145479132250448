import React from "react";
import { connect } from "react-redux";
import Search from "./search";
import searchActions from "../../../state/redux/data/search/actions";
import { isUndefined } from "../../../state/helper/functions";
import commonActions from "../../../state/redux/data/common/actions";

const SearchReduxContainer = props => {
  let show = props.show;
  let toggleSearch = props.toggleSearch;
  if (isUndefined(show)) {
    show = false;
  }
  let loggedIn = false;

  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }
  return (
    <Search
      {...props}
      toggleSearch={toggleSearch}
      show={show}
      loggedIn={loggedIn}
    />
  );
};

const mapStateToProps = state => ({
  show: state.search.show,
  customer: state.customer.customer
});

const mapDispatchToProps = dispatch => {
  return {
    toggleSearch: isShown => {
      dispatch(searchActions.toggleSearch(isShown));
    },
    showModal: (identifier, show) => {
      dispatch(commonActions.showModal(identifier, show));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchReduxContainer);
