export let NumberItems = {
  0: {
    items: 1
  },
  1024: {
    items: 1
  }
};

export let ThumbnailItems = {
  0: {
    items: 3
  },
  1024: {
    items: 4
  }
};
