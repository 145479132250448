import React from "react";
import CustomerDashboardAccountAddresses from "./account-addresses";
import {
  filterDefaultShippingAddress,
  filterDefaultBillingAddress
} from "./filter-functions";

const CustomerDashboardAccountAddressesState = props => {
  let defaultBillingAddress = filterDefaultBillingAddress(props);
  let defaultShippingAddress = filterDefaultShippingAddress(props);
  return (
    <CustomerDashboardAccountAddresses
      defaultBilling={defaultBillingAddress}
      defaultShipping={defaultShippingAddress}
      {...props}
    />
  );
};

export default CustomerDashboardAccountAddressesState;
