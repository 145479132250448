import React from "react";
import ConditionalWrapper from "./conditionalWrapper";
import Icon from "../../assets";
import "./buttons.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = props => {
  return (
    <>
      <ConditionalWrapper {...props}>
        <button
          className={"btn " + props.className}
          onClick={props.onClick}
          btnbackground={props.btnBackground}
          disabled={props.disabled}
        >
          {props.icon && <Icon id={props.icon} />}
          {!props.children && props.text}
          {props.children}
          {props.rightIcon && <FontAwesomeIcon icon={props.rightIcon} />}
        </button>
      </ConditionalWrapper>
    </>
  );
};

export default Button;
