import React, { useState, useEffect, useRef } from "react";
import SearchProducts from "../searchProducts";
import "./searchInput.scss";

const SearchInput = props => {
  const [search, setSearch] = useState("");
  let { loggedIn, showModal, show, toggleSearch } = props;
  const inputElement = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        inputElement.current &&
        !inputElement.current.contains(event.target)
      ) {
        if (show) toggleSearch(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [show, toggleSearch]);

  useEffect(() => {
    if (show) inputElement.current.focus();
  }, [show]);

  return (
    <>
      
        <div id="searchinput">
          <div className={"search-inner"}>
            <span
              className="search-icon"
              onClick={e => {
                // if (!loggedIn) {
                //   e.preventDefault();
                //   showModal("loginModal", true);
                //   return;
                // }
                props.changeSearch(search);
              }}
            ></span>
            <input
              autoComplete="off"
              ref={inputElement}
              name={props.name}
              type={props.type}
              placeholder={props.placeholder}
              value={search}
              onChange={e => {
                setSearch(e.target.value);
                if (e.target.value.length >= 3) {
                  props.changeSearch(e.target.value);
                }
              }}
              onKeyPress={e => {
                if (e.keyCode === "Enter" || e.which === 13) {
                  props.changeSearch(e.target.value);
                }
              }}
            />
          </div>
          <SearchProducts {...props} />
        </div>
    </>
  );
};

export default SearchInput;
