import apolloClient from "../../../../graphql/apolloClient";
import customerActions from "../../customer/actions";
import queryLoader from "../../../../graphql/queryLoader";
import messagesActions from "../../messages/actions";
import { loadState } from "../../../localStorage";

let dispatchError = false;
let localState = loadState();

export default async (store, action) => {
  try {
    let storeState = store.getState();
    let shouldAuthorize = storeState.common.authorize;
    let token = storeState.customer.customer.token;
    if (!token) token = localState.customer.customer.token;

    let data = [];

    if (shouldAuthorize) {
      data = await apolloClient.query({
        query: queryLoader("checkCustomerAuthorization"),
        variables: action
      });
      if (!data.customer || !data.customer.id) {
        if (token) {
          dispatchError = true;
          console.log("GraphQL response", data);
          throw new Error("Your session has expired.");
        }
      }
      // if(!data.customer || !data.customer.name)
      // {
      //     store.dispatch(customerActions.getCustomerInformation(token));
      // }
    } else {
      if ("id" in storeState.customer !== false) {
        store.dispatch(customerActions.getCustomerInformation(token));
      }
      return;
    }

    if (!data.customer.id) {
      store.dispatch(customerActions.getCustomerInformation(token));
    }
  } catch (error) {
    if (dispatchError) {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    }

    if (
      typeof localState !== "undefined" &&
      localState.customer !== "undefined" &&
      localState.customer.customer !== "undefined"
    ) {
      if (
        localState.customer.customer.token ||
        localState.customer.customer.id
      ) {
        store.dispatch(customerActions.clearCustomerInformation());
        store.dispatch(customerActions.logoutCustomer());
      }
    }

    return;
  }
};
