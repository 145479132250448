import React from "react";
import { ReactComponent as HeaderLogo } from "../../../header-logo.svg";
import { ReactComponent as BadgeLogo } from "../../../badgelogo.svg";
import { Link } from "react-router-dom";

const HeaderBottomLogo = () => {
  return (
    <div className="logo">
      <Link to={"/"}>
        <HeaderLogo className="header-logo" />
      </Link>
      <Link to={"/"}>
        <BadgeLogo className="mobile-logo" />
      </Link>
    </div>
  );
};

export default HeaderBottomLogo;
