import apolloClient from "../../../../graphql/apolloClient";
import commonActions from "../../common/actions";
import queryActions from "../../queries/actions";
import queryLoader from "../../../../graphql/queryLoader";
import { messagesActions } from "../../messages";

export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("sendEmail"),
      variables: action.data
    });

    if (!data.sendEmail) {
      console.log("GraphQL response", data);
      throw new Error("No cache information received");
    }
    store.dispatch(commonActions.unlock());
    store.dispatch(messagesActions.addMessage(data.sendEmail, "success"));
  } catch (error) {
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
