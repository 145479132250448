import React, { useState } from "react";
import "./select.scss";
import Icon from "../../assets";
import { isEmpty } from "../../state/helper/functions";
import { isUndefined } from "../../state/helper/functions";

const Select = props => {
  let additionalAttributes = {};
  if (!isUndefined(props.disabled) && props.disabled === true)
    additionalAttributes["disabled"] = true;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(props.value);
  const [searchValue, setSearchValue] = useState("");
  const [loaded, setLoaded] = useState(false);
  let items = props.options;

  const checkToggle = e => {
    if (e.keyCode === 13) e.preventDefault();
    if (!isOpen) {
      setIsOpen(true);
      setSelectedValue("");
      setSearchValue("");
    }
  };
  const closeToggle = e => {
    setTimeout(() => setIsOpen(false), 150);
  };

  const getOptions = () => {
    if (isUndefined(props.options) || isEmpty(props.options)) return [];
    let items = props.options;
    let newItems = [];
    let keys = Object.keys(props.options);

    items = items.filter((item, index) => {
      return (
        item.label.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.value
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    });
    if (isEmpty(items)) {
      keys.forEach((value, index) => {
        if (!isUndefined(props.options[value])) {
          if (
            props.options[value].label
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            props.options[value].value
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            newItems[index] = props.options[value];
          }
        }
      });
      return newItems;
    }

    return items;
  };

  const selectValueAction = e => {
    let selectedOption;
    if (isUndefined(e.target)) {
      selectedOption = e;
    } else {
      selectedOption = e.target.attributes.value.value;
    }
    setSelectedValue(selectedOption);

    if (isUndefined(items[selectedOption])) {
      let firstOption = items[Object.keys(items)[0]];
      setSelectedValue(firstOption.value);
      setSearchValue(firstOption.label);
      props.onChange(firstOption.value);
    } else {
      setSearchValue(items[selectedOption].label);
      props.onChange(selectedOption);
    }

    setIsOpen(false);
  };

  if (!isEmpty(items)) {
    if (!loaded) {
      if (selectedValue) {
        setLoaded(true);
        selectValueAction(selectedValue);
      }
    }
  }
  const setSelectedValueHandler = value => {
    let inputValue = value;
    setSelectedValue(inputValue);
    props.onChange(inputValue);
  };

  return (
    <div className={`select-wrapper ${props.wrapperClass}`}>
      <div
        className={`selector${isOpen ? " open" : ""}${
          selectedValue !== "" ? " filled" : ""
        }`}
      >
        {!isEmpty(items) && (
          <input
            {...additionalAttributes}
            type="text"
            placeholder={props.placeholder}
            value={searchValue}
            onKeyDown={checkToggle}
            onChange={e => {
              setSearchValue(e.target.value);
            }}
            onFocus={e => {
              checkToggle(e);
            }}
            autoComplete="off-input"
            autoCorrect="off-input"
            onBlur={e => {
              closeToggle(e);
            }}
          />
        )}
        {isEmpty(items) && (
          <input
            {...additionalAttributes}
            type="text"
            placeholder={props.placeholder}
            value={selectedValue}
            onKeyDown={checkToggle}
            onChange={e => {
              setSelectedValueHandler(e.target.value);
            }}
            onFocus={e => {
              checkToggle(e);
            }}
            autoComplete="off-input"
            autoCorrect="off-input"
          />
        )}
        <div className="selections">
          {getOptions().map((option, index) => {
            return (
              <div
                className="option"
                onClick={e => selectValueAction(e)}
                onChange={e => console.log(e)}
                key={index}
                value={option.value}
              >
                {option.label}
              </div>
            );
          })}
        </div>
        {props.icon && (
          <span className="icon">
            <Icon id={props.icon} />
          </span>
        )}
        {props.required && <div className="required">Required</div>}
      </div>
      {items && (
        <select
          className="hidden-select"
          name={props.name}
          id={props.id}
          onChange={e => {}}
          value={selectedValue}
          autoComplete="off-input"
          autoCorrect="off-input"
        >
          {items && (
            <option
              onClick={e => {
                selectValueAction(e);
              }}
              value={selectedValue}
            >
              {!isUndefined(items[selectedValue]) && items[selectedValue].label}
            </option>
          )}
        </select>
      )}
    </div>
  );
};

export default Select;
