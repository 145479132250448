import React from "react";
import { connect } from "react-redux";
// import Listing from "./listing";
import ListingQueryContainer from "./listing-query-container";
import { filterActions } from "../../../state/redux/data/filters";
import { commonActions } from "../../../state/redux/data/common";
import { customerActions } from "../../../state/redux/data/customer";

const ListingReduxContainer = props => {
  return (
    <ListingQueryContainer
      variables={props.variables}
      query={props.query}
      allCategories={props.allCategories}
      category={props.category}
      filters={props.filters}
      showFilters={props.showFilters}
      product_filter={props.product_filter}
      category_id={props.category_id}
      addFilteredData={props.addFilteredData}
      addInitialCategoryData={props.addInitialCategoryData}
      lock={props.lock}
      unlock={props.unlock}
      locked={props.locked}
      exportList={props.exportList}
      customer={props.customer}
      logout={props.logout}
      checkAuthorization={props.checkAuthorization}
    />
  );
};

const mapStateToProps = state => ({
  filters: state.filters,
  product_filters: state.filters.filter,
  category: state.filters.category,
  locked: state.common.locked,
  exportList: state.exportList,
  customer: state.customer.customer
});

const mapDispatchToProps = dispatch => {
  return {
    lock: () => {
      dispatch(commonActions.lock());
    },
    unlock: () => {
      dispatch(commonActions.unlock());
    },
    addFilteredData: data => {
      dispatch(filterActions.addFilteredData(data));
    },
    addInitialCategoryData: data => {
      dispatch(filterActions.addInitialCategoryData(data));
    },
    logout: message => {
      dispatch(customerActions.logoutCustomer(false, message));
    },
    checkAuthorization: token => {
      dispatch(customerActions.validateCustomerToken(token));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingReduxContainer);
