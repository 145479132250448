import React from "react";
import { useQuery } from "@apollo/react-hooks";
import ReactLoader from "../loader";
import Component from "./dynamicGraphQlWrapper";
import ErrorPage from "../../content/pages/error";

const DynamicGraphQlWrapperQueryFetchContainer = props => {
  const { loading, error, data } = useQuery(props.query, {
    variables: props.variables
  });

  if (loading && props.loader) {
    if (loading)
      return (
        <div>
          <ReactLoader />
        </div>
      );
  }

  if (error) return <div style={{padding: "40px",display:'flex',justifyContent: 'center',fontSize: "22px"}}>It appears that you are currently offline.<br /> Please try again when you are connected to the internet.</div>;

  const children = React.Children.map(props.children, child => {
    return React.cloneElement(child, { ...props, data: data });
  });
  if(data){
  if (typeof props.isCached !== 'undefined' && !props.isCached) {
    if ("urlResolver" in data) {
      if (data.urlResolver === null) {
        return <ErrorPage {...props} />;
      } else if (
        data.urlResolver.id &&
        data.urlResolver.type === "CMS_PAGE" &&
        typeof props.queries !== "undefined" &&
        typeof props.queries[props.variables.url] !== "undefined" &&
        typeof props.queries[props.variables.url].key !== "undefined"
      ) {
        props.addQueryData(
          data.urlResolver.data,
          props.variables.url,
          data.urlResolver.id
        );
      }
    }
  }
}
  return <Component {...props}>{children}</Component>;
};

export default DynamicGraphQlWrapperQueryFetchContainer;
