import React from "react";
import Button from "../../../utils/buttons";
import { isEmpty } from "../../../state/helper/functions";
import "./fullscreen.scss";
const MediaGalleryFullscreen = props => {
  let {
    thumbnails,
    media_gallery,
    setCurrentIndex,
    currentIndex,
    setFullScreen
  } = props;

  return (
    <div id={`media-gallery-fullscreen`} className={`slide`}>
      <div id={`selected-image`}>{media_gallery[currentIndex]}</div>
      {!isEmpty(thumbnails) && (
        <div className="carousel-thumbnails">
          {Object.keys(media_gallery).map((value, index) => {
            return thumbnails[index];
          })}
        </div>
      )}
      <div className="controls">
        <Button
          className={`btn button btn-orange`}
          text={`x`}
          onClick={e => {
            e.preventDefault();
            setFullScreen(false);
          }}
        />
      </div>
    </div>
  );
};

export default MediaGalleryFullscreen;
