import React from "react";
import { connect } from "react-redux";
import commonActions from "../../../../../../state/redux/data/common/actions";
import { exportListActions } from "../../../../../../state/redux/data/exportList";
import BodyContentState from "./bodycontent-state";

const ProductsInExportList = props => {
  return <BodyContentState {...props} />;
};

const mapStateToProps = state => ({
  locked: state.common.locked,
  skuList: state.exportList.items,
  sku: state.exportList.createName
});

const mapDispatchToProps = dispatch => {
  return {
    addSkuToList: (listId, sku) => {
      dispatch(exportListActions.addSkuToList(listId, sku));
    },
    removeSkuFromList: (listId, sku) => {
      dispatch(exportListActions.removeSkuFromList(listId, sku));
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsInExportList);
