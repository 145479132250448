import React from "react";
import { Link } from "react-router-dom";
import "./account-information.scss";

const CustomerDashboardAccountInformation = props => {
  return (
    <div
      id="dashboard-account-information"
      className={"customer-account-information customer-account-section"}
    >
      <h4 className={"account-labels account-labels"}>Account Information</h4>
      <div className={"container"}>
        <div className="contact-information account-inner">
          <h4 className="address-label">Contact Information</h4>
          <div className="address-greeting">
            <span>NAME</span> {props.customer.firstname}{" "}
            {props.customer.lastname}
            <br></br>
            <span>EMAIL</span> {props.customer.email}
          </div>
          <Link to={"/customer/information"}>EDIT</Link>
          <Link to={"/customer/information?changepassword"}>
            CHANGE PASSWORD
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboardAccountInformation;
