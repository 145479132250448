import React from "react";
import { connect } from "react-redux";
import customerActions from "../state/redux/data/customer/actions";
import HeaderQueryContainer from "./header-query-container";
import { commonActions } from "../state/redux/data/common";

const HeaderReduxContainer = props => {
  return <HeaderQueryContainer {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    login: (email, password, callback) => {
      dispatch(customerActions.loginCustomer(email, password));
    },
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    showModal: (identifier, status) => {
      dispatch(commonActions.showModal(identifier, status));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderReduxContainer);
