import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
// import Breadcrumbs from "../utils/breadcrumbs";
import Toolbar from "../utils/products/toolbar";
import Sidebar from "../utils/products/sidebar";
import Listing from "../utils/products/listing";
import "./category.scss";

const Category = props => {
  const { category, allCategories, categoryFilter } = props;
  const [showFilters, setShowFilters] = useState(false);

  let setListingFullWidth =
    props.showFilters === false ? "width80" : "width100";
  let setFiltersVisibility = showFilters === true ? "visible" : "hidden";

  return (
    <>
      <div
        id={`category-${props.id}`}
        className={`category-container page-main-content`}
      >
        <h1>{category && category.name}</h1>
        <Toolbar showFilters={showFilters} setShowFilters={setShowFilters} />
        <div
          className={`plp-container listing-${setListingFullWidth} sidebar-${setFiltersVisibility}`}
        >
          <CSSTransition
            key={"sidebar"}
            timeout={{
              appear: 1000,
              enter: 1000,
              exit: 1000
            }}
            classNames={"slide"}
            category_id={categoryFilter}
            in={showFilters}
          >
            <Sidebar allCategories={allCategories} />
          </CSSTransition>
          <CSSTransition
            key={"listing"}
            timeout={{
              appear: 2000,
              enter: 2000,
              exit: 2000
            }}
            classNames={"slide"}
            in={!showFilters}
            category_id={categoryFilter}
            allCategories={allCategories}
          >
            <Listing />
          </CSSTransition>
        </div>
      </div>
    </>
  );
};

export default Category;
