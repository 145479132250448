import React from "react";
import "./slider.scss";
import Button from "../../../../utils/buttons";

const Slider = props => {
  let { loggedIn, showModal } = props;
  return (
    <div className={`carousel-content`} style={props.backgroundImage}>
      <div className={`carousel-inner-content`}>
        <div className={`carousel-text`}>
          {/* <h4>{props.subheading}</h4>
          <h1>{props.heading}</h1>
          <p>{props.description}</p> */}
          <Button
            className={"button btn-with-right-icon btn-orange right-arrow"}
            text={props.buttonText}
            icon={"rightArrow"}
            to={props.buttonUrl}
          // onClick={e => {
          //   if (!loggedIn) {
          //     e.preventDefault();
          //     showModal("loginModal", true);
          //     return;
          //   }
          // }}
          />
        </div>
      </div>
    </div>
  );
};

export default Slider;
