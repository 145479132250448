import React from "react";
import Form from "../../../utils/forms";
import Input from "../../../utils/input";
import Button from "../../../utils/buttons";
import CheckButton from "../../../utils/checkButton";
import ValidationWrapper from "../../../utils/validationWrapper";
import { isUndefined } from "../../../state/helper/functions";
import { Link } from "react-router-dom";
import "./contactform.scss";

const ContactUsForm = props => {
  let {
    email,
    setEmail,
    firstname,
    setFirstname,
    lastname,
    setLastname,
    directOfficeNumber,
    setDirectOfficeNumber,
    mobile,
    setMobile,
    company,
    setCompany,
    privacyPolicy,
    setPrivacyPolicy,
    sendEmail,
    validator,
    validate,
    setValidate,
    message,
    setMessage,
    showModal,
    loggedIn,
    show
  } = props;

  return (
    <div className="contact-us-form-container">
      <h3>GET CONNECTED</h3>
      <h1>CONTACT US</h1>
      <div className="formContainer">
        <Form>
          <div className="form-container">
            <div className="column-fields one">
              <ValidationWrapper validate={validate} validator={validator}>
                <Input
                  name={"email"}
                  type="text"
                  className={""}
                  placeholder="Email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  required={true}
                  icon="email"
                  validation={validator.message(
                    "email",
                    email,
                    "required|email"
                  )}
                />
                <Input
                  name={"firstname"}
                  type="text"
                  className={""}
                  placeholder="First name"
                  value={firstname}
                  onChange={event => setFirstname(event.target.value)}
                  required={true}
                  icon="user"
                  validation={validator.message(
                    "firstname",
                    firstname,
                    "required"
                  )}
                />
                <Input
                  name={"lastname"}
                  type={"text"}
                  className={""}
                  value={lastname}
                  onChange={event => setLastname(event.target.value)}
                  required={true}
                  icon="user"
                  placeholder="Last name"
                  validation={validator.message(
                    "lastname",
                    lastname,
                    "required"
                  )}
                />
                <Input
                  name={"directOfficeNumber"}
                  type="number"
                  className={""}
                  placeholder="DIRECT OFFICE NUMBER"
                  value={directOfficeNumber}
                  onChange={event => setDirectOfficeNumber(event.target.value)}
                  required={true}
                  icon="phone"
                  validation={validator.message(
                    "directOfficeNumber",
                    directOfficeNumber,
                    "required"
                  )}
                />
                <Input
                  name={"mobile"}
                  type="number"
                  className={""}
                  placeholder="MOBILE PHONE NUMBER"
                  value={mobile}
                  onChange={event => setMobile(event.target.value)}
                  required={false}
                  icon="cell"
                />
                <Input
                  name={"company"}
                  type="text"
                  className={""}
                  placeholder="COMPANY"
                  value={company}
                  onChange={event => setCompany(event.target.value)}
                  required={true}
                  icon="business"
                  validation={validator.message("company", company, "required")}
                />
              </ValidationWrapper>
            </div>
            <div className="column-fields two">
              <ValidationWrapper validate={validate} validator={validator}>
                <Input
                  className={"contact-msg"}
                  type={"textarea"}
                  name={"message"}
                  value={message}
                  onChange={event => setMessage(event.target.value)}
                  placeholder={"Message"}
                  validation={validator.message("message", message, "required")}
                />
              </ValidationWrapper>
              <div className="privacy-btn">
                <CheckButton
                  name={"check"}
                  type={"checkbox"}
                  value={privacyPolicy}
                  defaultChecked={privacyPolicy}
                  className={"slider"}
                  wrapperClass={"switch-container"}
                  text={""}
                  onChange={e => {
                    let value = e.target.value;
                    if (value === false || value === "false") {
                      setPrivacyPolicy(true);
                    } else {
                      setPrivacyPolicy(false);
                    }
                  }}
                  validation={validator.message(
                    "privacyPolicy",
                    privacyPolicy,
                    "required|boolean"
                  )}
                />
                <span>
                  I have read the sites {"  "}
                  <Link
                    // onClick={e => {
                    //   if (!loggedIn) {
                    //     e.preventDefault(show);
                    //     showModal("loginModal", true);
                    //     return;
                    //   }
                    // }}
                    to={"/privacy-policy"}
                    target="_blank"
                  >
                    privacy policy
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="save-btn">
            <Button
              className="button btn-orange"
              onClick={e => {
                e.preventDefault();
                let formValidation = validator.allValid();
                if (formValidation) {
                  if (privacyPolicy)
                    sendEmail({
                      input: {
                        attributes: [
                          { code: "email", value: email },
                          { code: "firstname", value: firstname },
                          { code: "lastname", value: lastname },
                          { code: "mobile", value: mobile },
                          {
                            code: "directOfficeNumber",
                            value: directOfficeNumber
                          },
                          { code: "company", value: company },
                          { code: "message", value: message }
                        ]
                      }
                    });
                  setTimeout(() => {
                    document.getElementById("root").scrollIntoView({
                      behavior: "smooth",
                      block: "start"
                    });
                  }, 800);
                } else {
                  setValidate(true);
                }
              }}
              text="Send"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};
export default ContactUsForm;
