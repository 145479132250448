import React from "react";
import { connect } from "react-redux";
import commonActions from "../../../../../state/redux/data/common/actions";
import { exportListActions } from "../../../../../state/redux/data/exportList";
import SidebarListStateContainer from "./sidebar-state-container";
import { messagesActions } from "../../../../../state/redux/data/messages";
import { isEmpty } from "../../../../../state/helper/functions";

const SidebarReduxContainer = props => {
  return <SidebarListStateContainer {...props} />;
};

const mapStateToProps = state => ({
  listId: state.listId,
  name: state.name,
  export_list_data: state.exportList.export_list_data,
  csvData: state.exportList.csvData,
  downloadable: state.exportList.downloadable,
  pending: state.exportList.pending,
  customer: state.customer.customer
});

const mapDispatchToProps = dispatch => {
  return {
    exportList: (
      listId,
      productData,
      freightCalculation,
      markupValue,
      includeMsrp
    ) => {
      let malformedItems = [];
      let dataChecker = true;
      productData.map(item => {
        if (dataChecker) {
          if (item.qty === "" || item.zip === "") {
            dataChecker = false;
            malformedItems.push(item);
            if (!dataChecker) {
              document.getElementsByTagName("aside")[0].click();
              return dispatch(
                messagesActions.addMessage(
                  "The product should have a valid quantity and zip code. (" +
                    item.name +
                    ")",
                  "danger"
                )
              );
            }
          }
        }
      });
      if (isEmpty(malformedItems)) {
        let data = {
          productArray: productData,
          withFreight: freightCalculation,
          markup: markupValue,
          includeMsrp: includeMsrp
        };
        dispatch(exportListActions.exportList(listId, data));
      }
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
    showModal: (identifier, show) => {
      dispatch(commonActions.showModal(identifier, show));
    },
    closeModal: identifier => {
      dispatch(commonActions.closeModal(identifier));
    },
    removeAllItemsFromList: listId => {
      dispatch(exportListActions.removeAllItemsFromList(listId));
    },
    toggleDownload: newStatus => {
      dispatch(
        exportListActions.toggleExportListDownloadAvailability(newStatus)
      );
    },
    resetCsvData: data => {
      dispatch(exportListActions._reduceCSVData([]));
    },
    pendingExportList: status => {
      dispatch(exportListActions.pendingExportList(status));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarReduxContainer);
