import React from "react";
import CategoryBlock from "./categoryBlock";
import { findById } from "../../../../../state/helper/category";
import { isUndefined } from "../../../../../state/helper/functions";
import themeConfiguration from "../../../../../state/config/themeConfiguration";

const CategoryBlockWrapper = props => {
  let { allCategories, loggedIn, showModal, data } = props;

  if (isUndefined(data)) return "";
  return data.getDynamicContentByContentType.map(content => {
    let currentContent = findById(
      parseInt(content.content),
      allCategories.data.category
    );
    if (currentContent.thumbnail_image !== null) {
      return (
        <CategoryBlock
          key={content.content_id}
          categoryImage={
            themeConfiguration.magento_url +
            themeConfiguration.media_url +
            themeConfiguration.category_url +
            "/" +
            currentContent.thumbnail_image
          }
          categoryTitle={currentContent.name}
          categoryUrl={currentContent.url_path}
        // onClick={e => {
        //   if (!loggedIn) {
        //     e.preventDefault();
        //     showModal("loginModal", true);
        //     return;
        //   }
        // }}
        />
      );
    }
  });
};

export default CategoryBlockWrapper;
