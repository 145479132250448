import types from "./types";
import lockReducer from "./reducers/lock";
import unlockReducer from "./reducers/unlock";
import checkCustomerAuthorization from "./reducers/checkCustomerAuthorization";
import forceCheckCustomerAuthorization from "./reducers/forceCheckCustomerAuthorization";
import closeAllModals from "./reducers/closeAllModals";
import showModal from "./reducers/showModal";
import closeModal from "./reducers/closeModal";
import addQuery from "./reducers/addQuery";
import addQuerySuccess from "./reducers/addQuerySuccess";
import routeChange from "./reducers/routeChange";
import setCacheVersion from "./reducers/setCacheVersion";
import setFlag from "./reducers/setFlag";
import setAppVersion from "./reducers/setAppVersion";

// Main reducer
const commonReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }

  if (action.type === types.LOCK) {
    return lockReducer(state, action);
  }

  if (action.type === types.UNLOCK) {
    return unlockReducer(state);
  }

  if (action.type === types.CHECK_CUSTOMER_AUTHORIZATION) {
    return checkCustomerAuthorization(state, action);
  }

  if (action.type === types.FORCE_CHECK_CUSTOMER_AUTHORIZATION) {
    return forceCheckCustomerAuthorization(state, action);
  }

  if (action.type === types.CLOSE_ALL_MODALS) {
    return closeAllModals(state);
  }

  if (action.type === types.SHOW_MODAL) {
    return showModal(state, action);
  }

  if (action.type === types.CLOSE_MODAL) {
    return closeModal(state, action);
  }

  if (action.type === types.ADD_QUERY) {
    return addQuery(state);
  }

  if (action.type === types.ADD_QUERY_SUCCESS) {
    return addQuerySuccess(state);
  }

  if (action.type === types.ROUTE_CHANGE) {
    return routeChange(state, action);
  }

  if (action.type === types.ROUTE_CHANGE_SUCCESS) {
    return routeChange(state, action);
  }

  if (action.type === types.GET_CACHE_VERSION) {
    // return getCacheVersion(state, action);
  }

  if (action.type === types.SET_CACHE_VERSION) {
    return setCacheVersion(state, action);
  }

  if (action.type === types.SET_FLAG) {
    return setFlag(state, action);
  }

  if (action.type === types.SET_APP_VERSION) {
    return setAppVersion(state, action);
  }

  return state;
};

export default commonReducer;
