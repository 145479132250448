import React from "react";
import Input from "../../../../../../../utils/input";
import Image from "../../../../../../../utils/image";
import { Link } from "react-router-dom";
import {
  getBrandImageByCategoryId,
  createCategoryImageUrl
} from "../../../../../../../state/helper/category";
import { getPrice } from "../../../../../../../utils/products/pricingInformation/functions";
import { isUndefined } from "../../../../../../../state/helper/functions";
import { onlyNumbers } from "../../../../../../../state/helper/functions";
import { determineCurrency } from "../../../../../../../state/config/currency";
import "./productItem.scss";

const ProductItemContent = props => {
  let data = props;
  let tierPrices = props.item.tier_prices;
  let brandImage = getBrandImageByCategoryId(
    data.item.categories,
    data.allCategories
  ).filter(item => {
    return item.path.split("/").length === 5;
  })[0];
  let { item, shipQty, shipTo, manageItemState } = props;

  return (
    <>
      <td className={"item-wrapper"} data-th={"Item"}>
        <Link
          to={`/${props.item.url_key}`}
          target={"blank"}
          className="item-link"
        >
          <div className={"item-wrapper-image"}>
            <Image
              source={`${item.image.url}`}
              image_alt={props.item.image.label}
            />
          </div>
          <div className={"item-wrapper-info"}>
            <span className={"brand-img"}>
              {!isUndefined(brandImage) &&
                !isUndefined(brandImage.thumbnail_image) && (
                  <div className={"brand"}>
                    <Image
                      source={createCategoryImageUrl(
                        brandImage.thumbnail_image
                      )}
                      image_alt={brandImage.name}
                    />
                  </div>
                )}
            </span>
            <br />
            <span className={"name-item"}>{item.name}</span>
            <br />
            <span className={"sku"}>{item.sku}</span>
          </div>
        </Link>
      </td>
      <td className={"center inner-content price"} data-th={"Price"}>
        <div className={"price-wrap"}>
          <span className={"price"}>
            {(tierPrices && tierPrices[0].value > 0 && (
              <>
                {tierPrices.map((price, index) => {
                  if (price.value > 0) {
                    return (
                      <div className={"pricing-info"} key={index}>
                        <span className={"pricing-info-qty"}>{price.qty}+</span>
                        <span className={"pricing-info-price"}>
                          {getPrice(price.value)}
                        </span>
                      </div>
                    );
                  }
                })}
              </>
            )) ||
              (item.price.regularPrice.amount.value !== 0 &&
                determineCurrency(item.price.regularPrice.amount.currency) +
                  parseFloat(item.price.regularPrice.amount.value).toFixed(2))}
          </span>
        </div>
      </td>
      <td className={"center inner-content qty"} data-th={"Quantity"}>
        <Input
          name={`qty-input-${item.sku}`}
          type={"number"}
          wrapperClass={"range-field-wrapper"}
          className={"range-field"}
          inputClass={""}
          value={shipQty}
          onChange={e => {
            manageItemState(e.target.value, "qty");
            data.toggleDownload(false);
          }}
          onKeyPress={e => {
            onlyNumbers(e);
          }}
          onInput={e => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 5);
          }}
        />
      </td>
      <td className={"right inner-content zip"} data-th={"Ship to Zip"}>
        <Input
          name={"zip-info"}
          type={"number"}
          wrapperClass={"range-field-wrapper"}
          className={"range-field"}
          inputClass={""}
          value={shipTo}
          onChange={e => {
            manageItemState(e.target.value, "zip");
            data.toggleDownload(false);
          }}
          onInput={e => {
            e.target.value = e.target.value.slice(0, 5).toUpperCase();
          }}
        />
      </td>
    </>
  );
};
export default ProductItemContent;
