import React from "react";
import GraphQlWrapper from "../graphQlWrapper";
import "./splashScreen.scss";
import queryLoader from "../../state/graphql/queryLoader";
import HelmetWrapper from "../helmet";

const storeConfigQuery = queryLoader("storeConfig");
const SplashScreen = props => {
  return (
    <>
      <GraphQlWrapper
        query={storeConfigQuery}
        variables={{ id: "storeConfig" }}
      >
        {/* {props.splash && (
          <div className={"splash"}>
            {props.tasks > 0 && (
              <div
                id="splash"
                className={"splash-" + props.splash + " tasks-" + props.tasks}
              />
            )}

            {props.children}
          </div>
        )}
        {!props.splash && <div className="content">{props.children}</div>} */}
        <HelmetWrapper />
        {props.children}
      </GraphQlWrapper>
    </>
  );
};

export default SplashScreen;
