// Action Creators
import types from "./types";

const freightRequest = productInformation => {
  return {
    type: types.FREIGHT_REQUEST,
    productInformation: productInformation
  };
};

const _reduceFreightStatus = status => {
  return {
    type: types._REDUCE_FREIGHT_STATUS,
    status: status
  };
};

const _reduceFreightInformation = freightInformation => {
  return {
    type: types._REDUCE_FREIGHT_INFORMATION,
    freightInformation: freightInformation
  };
};

export default {
  freightRequest,
  _reduceFreightStatus,
  _reduceFreightInformation
};
