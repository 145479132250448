import React from "react";
import { connect } from "react-redux";
import { exportListActions } from "../../../state/redux/data/exportList";
import CustomerExportListDataContainer from "./exportList-data-container";

const CustomerReduxContainer = props => {
  return <CustomerExportListDataContainer {...props} />;
};

const mapStateToProps = state => ({
  locked: state.common.locked,
  exportList: state.exportList.items,
  createExportListName: state.exportList.createName
});

const mapDispatchToProps = dispatch => {
  return {
    getCustomerExportList: () => {
      dispatch(exportListActions.getCustomerExportList());
    },
    setCreateProjectName: name => {
      dispatch(exportListActions.createExportListName(name));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerReduxContainer);
