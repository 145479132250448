export default (state, action) => {
  let currentFilters = state.items;
  let removeFilter = action.filter;

  if (currentFilters[removeFilter]) {
    delete currentFilters[removeFilter];
  }

  return Object.assign({}, state, { items: currentFilters });
};
