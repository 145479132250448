import React from "react";
import Button from "../../../utils/buttons";
import HeroImage from "../../../utils/heroImage";
import themeConfiguration from "../../../state/config/themeConfiguration";
import { Helmet } from "react-helmet";
import "./registerSuccess.scss";

const RegisterSuccessPage = props => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Thank You For Registering- {themeConfiguration.name}</title>
        <link
          rel="canonical"
          href={`${themeConfiguration.app_url}/register/success}`}
        />
      </Helmet>
      <div className="main-banner">
        <HeroImage
          source={"REGISTERED-PAGE.jpg"}
          alt={"Register success banner image"}
        />
      </div>
      <div id="registersuccesspage" className={"page-main-content"}>
        <h1>THANK YOU FOR REGISTERING</h1>
        <hr className={"detail"} />
        <h2>
          ONCE YOU HAVE BEEN APPROVED BY BRANDATION YOU WILL RECEIVE AN EMAIL
          NOTIFICATION
        </h2>
        <p>
          If you have any questions or have not received an email confirmation,
          follow the contact link below.
        </p>
        <hr className={"accent"} />
        <div className="actions">
          <Button to={"/"} className={"btn button btn-orange"} text={"Home"} />
          <Button
            to={"/contact"}
            className={"btn button btn-orange"}
            text={"Contact"}
          />
        </div>
      </div>
    </>
  );
};

export default RegisterSuccessPage;
