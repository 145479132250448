import React from "react";
import GraphqlWrapper from "../graphQlWrapper";
import queryLoader from "../../state/graphql/queryLoader";
import Parser from "./parser/cmsBlockParserReact";
const query = queryLoader("cmsBlock");

const CmsBlockQueryContainer = props => {
  return (
    <GraphqlWrapper query={query} variables={{ id: props.id }} loader={true}>
      <Parser
        dangHtml={props.dangHtml}
        title={props.title}
        cssClass={props.scssClass}
      />
    </GraphqlWrapper>
  );
};

export default CmsBlockQueryContainer;
