import React from "react";
import apolloClient from "./state/graphql/apolloClient";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import store from "./state/redux/store";
import history from "./state/redux/history";
import Body from "./redux-container";
import ScrollToTop from "react-router-scroll-top";

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ApolloHooksProvider client={apolloClient}>
          <Router history={history}>
            <ScrollToTop>
              <Body />
            </ScrollToTop>
          </Router>
        </ApolloHooksProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
