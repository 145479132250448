import { initialState as customerInitialState } from "./data/customer/initialState";
import { commonInitialState } from "./data/common";
import { messagesInitialState } from "./data/messages";
import { queriesInitialState } from "./data/queries";
import { searchInitialState } from "./data/search";
import { isEmpty } from "../helper/functions";
import { exportListInitialState } from "./data/exportList";

// defining initial state
let customerState, commonState, messagesState, queriesState, exportListState;

export function getCustomerState(storeState) {
  if (!isEmpty(storeState) && !isEmpty(storeState.customer)) {
    customerState = storeState.customer;
  } else {
    customerState = customerInitialState;
  }
  return customerState;
}

export function getCommonState(storeState) {
  if (!isEmpty(storeState.common)) {
    commonState = storeState.common;
  } else {
    commonState = commonInitialState;
  }
  return commonState;
}

export function getMessagesState(storeState) {
  if (!isEmpty(storeState.messages)) {
    messagesState = storeState.messages;
  } else {
    messagesState = messagesInitialState;
  }
  return messagesState;
}

export function getQueriesState(storeState) {
  if (!isEmpty(storeState.queries)) {
    queriesState = storeState.queries;
  } else {
    queriesState = queriesInitialState;
  }

  return queriesState;
}

export function getExportListState(storeState) {
  if (!isEmpty(storeState.exportList)) {
    exportListState = storeState.exportList;
  } else {
    exportListState = exportListInitialState;
  }

  return exportListState;
}

export function getSearchState(storeState) {
  return searchInitialState;
}
