/**
 * FreightQuote integrations with Redux
 */

import freightQuoteReducer from "./reducer";
export { default as freightQuoteTypes } from "./types";
export { default as freightQuoteMiddleware } from "./middleware";
export { default as freightQuoteActions } from "./actions";
// export { default as freightQuoteInitialState } from "./initialState";
export default freightQuoteReducer;

// End
