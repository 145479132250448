import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./links.scss";
import { isEmpty } from "../../../state/helper/functions";

const getBrands = data => {
  if (!data || !data.id || !data.children) {
    return [];
  }

  return data.children;
};

const HeaderMenuLinks = props => {
  let {
    data,
    brand,
    title,
    loggedIn,
    showModal,
    changeCategoryImage,
    resetCategoryImage,
    activeTab,
    setActiveTabFn
  } = props;

  const getLinks = (data, brand) => {
    if (!data || !data.id || !data.children || isEmpty(data.children)) {
      return [];
    }
    if (brand) {
      const activeChild = data.children.filter(child => {
        return child.id === activeTab;
      })[0];
      return activeChild && activeChild.children;
    }

    return data.children.filter(item => item.include_in_menu);
  };

  return (
    <>
      {brand && (
        <div className="tabs">
          {getBrands(data).map(brand => {
            return (
              <div
                className={`tab${!brand.include_in_menu ? " disabled" : ""}${
                  activeTab === brand.id ? " active" : ""
                  }`}
                onMouseEnter={() => setActiveTabFn(brand)}
                key={brand.id}
              >
                {brand.name}
                {!brand.include_in_menu && <p>Coming soon!</p>}
              </div>
            );
          })}
        </div>
      )}
      <div className={`middle`}>
        {title && (
          <div className="title">
            <p>Shop By Category{title}</p>
          </div>
        )}
        <div className="items">
          {data &&
            //eslint-disable-next-line
            getLinks(data, brand).map(link => {
              if (link.include_in_menu) {
                return (
                  <Link
		    style={{cursor: "pointer"}}
                    to={"/" + link.url_path}
                    key={link.id}
                    className="link"
                    onMouseEnter={e => {
                      changeCategoryImage(link.thumbnail_image);
                      if(window.screen.width < 1024){props.closeMenu()}
		    }}
                    onMouseLeave={e => {
                      resetCategoryImage();
                    }}
		    onClick={e => {
			props.closeMenu();	 
		}}
                  // onClick={e => {
                  //   if (!loggedIn) {
                  //     e.preventDefault();
                  //     showModal("loginModal", true);
                  //   }
                  // }}
                  >
                    {link.name}
                  </Link>
                );
              }
            })}
        </div>
      </div>
    </>
  );
};

export default HeaderMenuLinks;
