import React, { useState } from "react";
import Breadcrumbs from "../../../utils/breadcrumbs";
import CmsBlock from "../../../utils/cmsBlock";
import CallToAction from "../../../utils/callToAction";
import ContentHeader from "../../../utils/contentHeader";
import OurServicesCoreTab from "./ourServicesCoreTab";
import { tabsData } from "./ourServices-data-container";
import HeroImage from "../../../utils/heroImage";
import themeConfiguration from "../../../state/config/themeConfiguration";
import { Helmet } from "react-helmet";
import "./ourServices.scss";

const OurServices = props => {
  const [selectedTab, setSelectedTab] = useState(0);
  let backgroundImageUrl = "./images/customer-service-call-out-full.jpg";
  let tabs = tabsData;
  let style = {
    customerService: {
      backgroundImage: "url(" + backgroundImageUrl + ")"
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Services - {themeConfiguration.name}</title>
        <link rel="canonical" href={`${themeConfiguration.app_url}/services`} />
      </Helmet>
      <div className="our-services-container">
        <div className="main-banner">
          <HeroImage
            source={"Services.jpg"}
            alt={"Our Services banner image"}
          />
        </div>
        <div className={`our-services-inner-content`}>
          <Breadcrumbs
            links={[
              { url: "/", title: "Home | " },
              { url: "#", title: "Our Services" }
            ]}
          />
          <div className="our-services-top-block">
            <CmsBlock dangHtml={true} id="our_services_top" />
          </div>

          <div className="our-services-core">
            <ContentHeader
              heading={"ADVANCE YOUR BUSINESS"}
              subHeading={"THE CORE SERVICES"}
            />
            <div className="our-services-core-inner-content">
              <div className="our-services-core-tab-container">
                <div className="our-services-core-tabs">
                  <div className="our-services-core-tabs-inner">
                    <div>
                      {Object.keys(tabs).map((value, index) => {
                        return (
                          <OurServicesCoreTab
                            key={index}
                            itemClass={
                              (selectedTab === index && "active") || ""
                            }
                            title={tabs[index].title}
                            icon={tabs[index].icon}
                            onClick={e => {
                              setSelectedTab(index);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="our-services-core-blocks">
                {Object.keys(tabs).map((value, index) => {
                  return (
                    <div
                      key={index}
                      className={`our-services-content our-services-content-${index} ${(index ===
                        selectedTab &&
                        "our-services-active") ||
                        ""}`}
                    >
                      <CmsBlock
                        dangHtml={true}
                        id={tabs[index].block_content}
                      />{" "}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="our-services-above-footer">
            <div className="our-services-above-footer-inner">
              <CallToAction
                title={"Customer Service"}
                subtitle={"Get Connected"}
                buttonText={"Contact Us"}
                backgroundImage={style.customerService}
                url={"/contact"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OurServices;
