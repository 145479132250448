import React from "react";
import { getPrice } from "./functions";
import { isUndefined } from "../../../state/helper/functions";
import "./pricingInformation.scss";

const PricingInformation = props => {
  let { product, parentClass, customer } = props;
  let productMsrp = 0; // some msrp retrieved from product
  let productPriceRegular = product.price.regularPrice.amount.value;
  let textMsrp = "MSRP";
  let textPriceRegular = "Regular";
  let productMsrpExist = "show-product-msrp";
  let tierPrices = product.tier_prices;

  return (
    <>
      {!isUndefined(customer) && (
        <>
          {customer.token !== false && (
            <div
              className={`pricing-info-button ${parentClass &&
                parentClass} ${productMsrpExist}`}
            >
              <div className={`pricing-info-top`}>
                {tierPrices && (
                  <>
                    {tierPrices.map((price, index) => {
                      if (price.value > 0) {
                        return (
                          <div className={"pricing-info"} key={index}>
                            <span className={"pricing-info-qty"}>
                              {price.qty}+
                            </span>
                            <span className={"pricing-info-price"}>
                              {getPrice(price.value)}
                            </span>
                          </div>
                        );
                      }
                      return "";
                    })}
                  </>
                )}
              </div>

              <div className={`pricing-info-bottom `}>
                {!isUndefined(productMsrp) && productMsrp > 0 && (
                  <div>
                    <span className={"pricing-info-text"}>{textMsrp}</span>
                    <span className={"pricing-info-price"}>
                      {getPrice(productMsrp)}
                    </span>
                  </div>
                )}
                {!isUndefined(productPriceRegular) && productPriceRegular > 0 && (
                  <div>
                    <span className={"pricing-info-text"}>
                      {textPriceRegular}
                    </span>

                    <span className={"pricing-info-price"}>
                      {getPrice(productPriceRegular)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PricingInformation;
