import { isUndefined } from "../../helper/functions";

export function getCountries(countries) {
  let countriesData = [];

  if (isUndefined(countries)) return [];
  Object.keys(countries).forEach(function(item, key) {
    countriesData[countries[key].id] = {
      label: countries[key].full_name_locale,
      value: countries[key].id,
      regions: countries[key].available_regions
    };
  });

  return countriesData;
}

export function getPrefferedPaymentMethod(customerPayment) {
  let paymentMethods = [];
  Object.keys(customerPayment).forEach(function(item, key) {
    paymentMethods[key] = {
      label: customerPayment[key],
      value: key
    };
  });
  return paymentMethods;
}

export const getRegionData = (countriesData, value) => {
  let regionsDataMock = [];

  if (value.regions !== null) {
    regionsDataMock = [];
    Object.keys(value.regions).forEach(function(item, key) {
      regionsDataMock[value.regions[key].id] = {
        label: value.regions[key].name,
        value: value.regions[key].id,
        code: value.regions[key].code
      };
    });
  }

  return regionsDataMock;
};
