export default (state, action) => {
  let currentItems = state.items;
  let newItems = [];

  Object.keys(currentItems).map((element, index) => {
    let thisItem = currentItems[index];
    return thisItem.listId === action.data.removeAllItemsFromList.listId
      ? newItems.push({
          listId: action.data.removeAllItemsFromList.listId,
          name: thisItem.name,
          skuList: action.data.removeAllItemsFromList.skuList
        })
      : newItems.push(thisItem);
  });

  return Object.assign({}, state, { items: newItems });
};
