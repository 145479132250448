import types from "./types";

import getExportList from "./reducers/getExportList";
import createExportList from "./reducers/createExportList";
import renameExportListSuccess from "./reducers/renameExportListSuccess";
import deleteExportListSuccess from "./reducers/deleteExportListSuccess";
import getCustomerExportList from "./reducers/getCustomerExportList";
import getCustomerExportListSuccess from "./reducers/getCustomerExportListSuccess";
import createExportListSuccess from "./reducers/createExportListSuccess";
import createExportListName from "./reducers/createExportListName";
import addSkuToList from "./reducers/addSkuToList";
import addSkuToListSuccess from "./reducers/addSkuToListSuccess";
import removeSkuFromList from "./reducers/removeSkuFromList";
import removeSkuFromListSuccess from "./reducers/removeSkuFromListSuccess";
import exportListData from "./reducers/exportListData";
import _reduceCSVData from "./reducers/_reduceCSVData";
import saveListBtnSuccess from "./reducers/saveListBtnSuccess";
import _reduceExportList from "./reducers/_reduceExportList";
import _reduceExportListLogout from "./reducers/_reduceExportListLogout";
import toggleExportListDownloadAvailability from "./reducers/toggleExportListDownloadAvailability";
import pendingExportList from "./reducers/pendingExportList";

const exportListReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }

  if (action.type === types.GET_EXPORT_LIST) {
    return getExportList(state, action);
  }

  if (action.type === types.CREATE_EXPORT_LIST) {
    return createExportList(state, action);
  }

  if (action.type === types.CREATE_EXPORT_LIST_SUCCESS) {
    return createExportListSuccess(state, action);
  }

  if (action.type === types.CREATE_EXPORT_LIST_NAME) {
    return createExportListName(state, action);
  }

  if (action.type === types.RENAME_EXPORT_LIST_SUCCESS) {
    return renameExportListSuccess(state, action);
  }

  if (action.type === types.DELETE_EXPORT_LIST_SUCCESS) {
    return deleteExportListSuccess(state, action);
  }

  if (action.type === types.GET_CUSTOMER_EXPORT_LIST) {
    return getCustomerExportList(state, action);
  }

  if (action.type === types.GET_CUSTOMER_EXPORT_LIST_SUCCESS) {
    return getCustomerExportListSuccess(state, action);
  }

  if (action.type === types.ADD_SKU_TO_LIST) {
    return addSkuToList(state, action);
  }

  if (action.type === types.ADD_SKU_TO_LIST_SUCCESS) {
    return addSkuToListSuccess(state, action);
  }

  if (action.type === types.REMOVE_SKU_FROM_LIST) {
    return removeSkuFromList(state, action);
  }

  if (action.type === types.REMOVE_SKU_FROM_LIST_SUCCESS) {
    return removeSkuFromListSuccess(state, action);
  }

  if (action.type === types.EXPORT_LIST_DATA) {
    return exportListData(state, action);
  }

  if (action.type === types._REDUCE_CSV_DATA) {
    return _reduceCSVData(state, action);
  }

  if (action.type === types.SAVE_EXPORT_LIST_BTN_SUCCESS) {
    return saveListBtnSuccess(state, action);
  }

  if (action.type === types._REDUCE_EXPORT_LIST) {
    return _reduceExportList(state, action);
  }

  if (action.type === types._REDUCE_EXPORT_LIST_LOGOUT) {
    return _reduceExportListLogout(state, action);
  }

  if (action.type === types.TOGGLE_EXPORT_LIST_DOWNLOAD_AVAILABILITY) {
    return toggleExportListDownloadAvailability(state, action);
  }

  if (action.type === types.PENDING_EXPORT_LIST) {
    return pendingExportList(state, action);
  }

  return state;
};

export default exportListReducer;
