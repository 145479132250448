import CustomerDashboard from "../../customer/pages/dashboard";
import CustomerInformation from "../../customer/pages/information";
import CustomerAddressBook from "../../customer/pages/addressBook";
import CustomerAddressBookEdit from "../../customer/pages/addressBook/pages/edit";
import CustomerExportList from "../../customer/pages/exportList";

export default {
  "customer/information": { component: CustomerInformation },
  "customer/address/edit": { component: CustomerAddressBookEdit },
  "customer/address/create": { component: CustomerAddressBookEdit },
  "customer/address": { component: CustomerAddressBook },
  "customer/export": { component: CustomerExportList }
};

export const pageTitle = {
  customer: { title: "Account Dashboard", useInDropdownMenu: true },
  "customer/information": { title: "Account Information" },
  "customer/address": { title: "Address Book" },
  "customer/export": { title: "Export List", useInDropdownMenu: true },
  logout: {
    title: "Logout",
    useInDropdownMenu: true,
    hideFromSidebar: true,
    onClick: "logout"
  }
};

export { default as ErrorPage } from "../../content/pages/error";

export const DefaultPage = CustomerDashboard;
