import React from "react";
import Carousel from "../../../../utils/carousel";

import { isEmpty } from "../../../../state/helper/functions";
const HomeCarousel = props => {
  let { slides, currentIndex, setCurrentIndex, responsiveData, items } = props;
  return (
    <>
      {!isEmpty(slides) && (
        <>
          {Object.keys(items).length !== 0 && (
            <div className={`home-carousel`}>
              <Carousel
                mouseDragEnabled
                onMouseDrag={e => {
                  e.preventDefault();
                }}
                responsive={responsiveData}
                dotsDisabled={false}
                buttonsDisabled={true}
                autoPlayInterval={5000}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                duration={750}
              >
                {slides}
              </Carousel>
              {/* <ul
                className={`carousel-navigation active-slide-${currentIndex}`}
              >
                <li>
                  {Object.keys(slides).map((element, index) => {
                    return (
                      <button
                        key={index}
                        onClick={e => {
                          setCurrentIndex(index);
                        }}
                        className={`btn-number ${(currentIndex === index &&
                          "btn-number-active") ||
                          ""}`}
                      >
                        {index + 1}
                      </button>
                    );
                  })}
                </li>
              </ul> */}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HomeCarousel;
