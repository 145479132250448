import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./alert.scss";

const Alert = props => {
  return (
    <div className="session-messages-inner">
      <div className={"session " + props.variant}>
        {props.variant === "success" ? (
          <FontAwesomeIcon icon="check-circle" />
        ) : (
          <FontAwesomeIcon icon="times-circle" />
        )}
        {props.children}
      </div>
    </div>
  );
};

export default Alert;
