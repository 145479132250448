import React from "react";
import { isUndefined } from "../../state/helper/functions";

const Form = props => {
  let formId = props.formId;
  let autoCompletion = isUndefined(props.allowCompletion)
    ? "on"
    : `-${formId}-completion`;

  return (
    <div
      id={`form-container-${props.formId}`}
      className={`form-container ${props.className}`}
    >
      <form
        id={props.formId}
        onSubmit={props.submitHandle}
        autoComplete={Math.ceil(Math.random() * 500) + autoCompletion}
      >
        {props.children}
      </form>
    </div>
  );
};

export default Form;
