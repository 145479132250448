import React from "react";
import { connect } from "react-redux";
import { commonActions } from "../../../state/redux/data/common";
import Contact from "./contact";

const ContactReduxContainer = props => {
  return <Contact {...props} />;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    sendEmail: data => {
      dispatch(commonActions.sendEmail(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactReduxContainer);
