export function filterDefaultBillingAddress(props) {
  if (!props.customer && props.customer === []) return [];

  let addresses = props.customer.addresses;
  let defaultBillingAddress = addresses.filter(function(address) {
    return address.default_billing > 0;
  });

  return defaultBillingAddress[0];
}
export function filterDefaultShippingAddress(props) {
  if (!props.customer && props.customer === []) return [];
  let addresses = props.customer.addresses;
  let defaultShippingAddress = addresses.filter(function(address) {
    return address.default_shipping > 0;
  });

  return defaultShippingAddress[0];
}
