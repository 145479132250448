import React from "react";
import Input from "../../../../../utils/input";
import Button from "../../../../../utils/buttons";
import "./edit.scss";

const Edit = props => {
  let {
    exportLists,
    project,
    firstItem,
    projectName,
    setProjectName,
    lock,
    renameExportList,
    changeProjectHandler,
    deleteExportList,
    locked
  } = props;
  let btnAttributes = {};
  let editBtnAttributes = {};

  if (project === firstItem) {
    btnAttributes["disabled"] = true;
  }

  return (
    <>
      {project !== firstItem && (
        <div className={"edit-project"}>
          <Input
            name={"project-name"}
            type="text"
            className={""}
            placeholder="Enter New Program Name"
            value={projectName}
            onChange={event => setProjectName(event.target.value)}
            required={true}
          />
          <div className="edit-btn buttons">
            <Button
              {...editBtnAttributes}
              className="button btn-orange"
              onClick={e => {
                lock();
                renameExportList(project, projectName);
              }}
              disabled={locked}
              text={`${(locked && "Editing...") || "Edit"}`}
            />
          </div>
          <div className="delete-btn buttons">
            <Button
              {...btnAttributes}
              className={`button delete btn-orange ${project === firstItem &&
                "btn-orange-disabled"}`}
              onClick={e => {
                deleteExportList(project);
                changeProjectHandler(exportLists[0].listId);
              }}
              text="Delete"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Edit;
