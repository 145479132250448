import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import customerActions from "../../../../state/redux/data/customer/actions";
import CustomerDashboardAccountAddressesState from "./account-addresses-state-container";

const CustomerDashboardAccountAddressesReduxContainer = props => {
  if (!props.customer) return <Redirect to={"/"} />;
  return <CustomerDashboardAccountAddressesState {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer
});

const mapDispatchToProps = dispatch => {
  return {
    unLock: () => {
      dispatch(customerActions.unlock());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDashboardAccountAddressesReduxContainer);
