import React from "react";
import Button from "../../../buttons";

import ActiveFilterItem from "./activeFilterItem/activeFilterItem";
import { getActiveFilters } from "./functions";
import { isEmpty } from "../../../../state/helper/functions";

import "./activeFilter.scss";

const ActiveFilter = props => {
  let activeFilters = getActiveFilters(props.filters);
  // let multiSelectFilter = [];
  return (
    <>
      {isEmpty(activeFilters) === false && (
        <div id={"active-filter"} className={"selected-filter filter-content"}>
          <h4>Now Shopping By</h4>
          {Object.keys(activeFilters).map((index, value) => {
            let filter = activeFilters[index];
            // if (filter.filter_type !== "multiple") {
            return (
              <ActiveFilterItem
                key={`active-filter-${index}`}
                filter={filter}
                removeFilter={props.removeFilter}
              />
            );
            // } else {
            //   let filterKey = index.split("_")[0];
            //   if (isUndefined(multiSelectFilter[filterKey])) {
            //     multiSelectFilter[filterKey] = [];
            //   }
            //   multiSelectFilter[filterKey].push(filter);
            // }
          })}
          {/* {console.log("pass", Object.keys(multiSelectFilter).length)} */}
          {/* implement tomorrow*/}
          {/* {Object.keys(multiSelectFilter).length !== 0 &&
            Object.keys(multiSelectFilter).map((index, value) => {
              return (
                <ActiveFilterItem
                  key={`active-filter-${index}`}
                  filter={multiSelectFilter}
                  removeFilter={props.removeFilter}
                />
              );
            })} */}
          <Button
            className={`clear-selected`}
            text={`Clear all`}
            onClick={props.clearFilters}
          />
        </div>
      )}
    </>
  );
};

export default ActiveFilter;
