import React from "react";
import Loader from "react-loader-spinner";
import themeConfiguration from "../../state/config/themeConfiguration";
import "./loader.scss";

const ReactLoader = props => {
  return (
    <div className={"loading-container"}>
      <Loader
        type={themeConfiguration.loader.type}
        color={(props.color && props.color) || themeConfiguration.loader.color}
        height={themeConfiguration.loader.height}
        width={themeConfiguration.loader.width}
      />
    </div>
  );
};

export default ReactLoader;
