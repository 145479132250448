import React from "react";
import BrandItem from "../brands/brandItem";
import Button from "../../../utils/buttons";
import { isEmpty } from "../../../state/helper/functions";

export const getTabs = (brands, countTab, setCountTab) => {
  let tabs = [];

  Object.keys(brands).map((value, index) => {
    let brand = brands[index];
    if (isEmpty(brand.children)) return true;
    return tabs.push(
      <Button
        key={index}
        className={`button ${(index === countTab && "btn-orange") ||
          " btn-gray"}`}
        text={brand.name}
        onClick={e => setCountTab(index)}
      />
    );
  });

  return tabs;
};

export const getBrandItems = (brands, props) => {
  let brandItemsComponents = [];

  Object.keys(brands).map((value, index) => {
    let masterBrand = brands[index];

    brandItemsComponents[index] = [];
    if (!isEmpty(masterBrand.children)) {
      Object.keys(masterBrand.children).map((bvalue, bindex) => {
        let currentBrand = masterBrand.children[bindex];
        return (brandItemsComponents[index][bindex] = (
          <BrandItem
            {...props}
            key={`brand-item-${currentBrand.id}`}
            title={currentBrand.name}
            description={currentBrand.description}
            image={currentBrand.thumbnail_image}
            url={
              "brands" + "/" + masterBrand.url_key + "/" + currentBrand.url_key
            }
          />
        ));
      });
    }

    return true;
  });

  return brandItemsComponents;
};
