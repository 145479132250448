import React, { useState } from "react";
import Button from "../../../../utils/buttons";
import { isUndefined } from "../../../../state/helper/functions";
import ProductItemProgram from "../../../../../src/utils/products/listing/productItem/productItemProgram";
import "./productInfoRightBottom.scss";

const ProductInfoRightBottom = props => {
  let exportListOptions = [];
  //check if exportListUndefined
  if (!isUndefined(props.exportList)) {
    //is empty
    if (!isUndefined(props.exportList.items)) {
      // go through list
      exportListOptions = props.exportList.items.map(item => {
        // check if skuList is not empty
        if (item.skuList !== null) {
          // split string into array
          let skuList = item.skuList.split(",");
          // check if product SKU exists in list if exists do nothing , if not return item.name as label and item.listId as value
          if (skuList.indexOf(props.productNumber) > -1) {
          } else {
            return { label: item.name, value: item.listId };
          }
        } else {
          return { label: item.name, value: item.listId };
        }
        return false;
      });
    }
    exportListOptions = exportListOptions.filter(function (el) {
      return el != null;
    });
  }
  function scrollToFreightQuote() {
    document.getElementById("productfreight").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  }

  const [program, setProgram] = useState("");

  const [button, setButton] = useState(false);
  const onPress = () => {
    setButton(true);
    setTimeout(() => {
      setButton(false);
    }, 1000);
  };

  // let isInExportList =
  //   (typeof props.exportList.items[0].skuList === "string" &&
  //     props.exportList.items[0].skuList.includes(props.sku)) ||
  //   false;

  return (
    <div id="productinforightbottom">
      {/* <p>SAMPLE NOTES: Without packaging. Sample lead time is 1 week</p> */}
      <div className={"bottom-buttons"}>
        {/* <Button
          className={`button add-to-program one btn-with-left-icon btn-white-orange-border btn-save ${(isInExportList &&
            "btn-orange btn-saved") ||
            ""}`}
          text={(button && "Saving") || (isInExportList && "Saved") || "Save"}
          icon={(isInExportList && "excelWhite") || "excelOrange"}
          onClick={e => {
            props.addSkuToList(exportListOptions[0].value, props.sku);
            onPress();
          }} */}
        {/* /> */}
        {/* <div className={"add-to-program two"}>
          <ProductItemProgram
            {...props}
            program={program}
            setProgram={setProgram}
            product={props}
            currentPage={`pdp`}
            text={"Add to Program"}
          />
        </div> */}
      </div>
      {/* <div className="freight-quote-link-wrapper" style={props.freightStyle}>
        <div className={"freight-qoute"} onClick={scrollToFreightQuote}>
          <h4>GET AN INSTANT FREIGHT QUOTE</h4>
        </div>
        <Button
          className={"button btn-with-left-icon scroll-button"}
          icon={"rightArrowOrange"}
          onClick={scrollToFreightQuote}
        />
      </div> */}
    </div>
  );
};

export default ProductInfoRightBottom;
