import React from "react";
import Create from "./create";
import ToolbarListing from "./toolbar-listing";
import Edit from "./edit";
import MiddleBlock from "./middleblock";
import "./toolbar.scss";

const Toolbar = props => {
  return (
    <div id="toolbar">
      <div className="toolbar-wrapper">
        <Create {...props} />
        <ToolbarListing {...props} />
        <Edit {...props} projectName={props.projectName} />
        <MiddleBlock {...props} />
      </div>
    </div>
  );
};

export default Toolbar;
