import React from "react";
import ErrorPage from "./pages/error";
import ReactLoader from "../utils/loader";
import { default as PageTypes } from "../state/config/pageTypes";
import { isUndefined } from "../state/helper/functions";

const DynamicRouter = props => {
  if (!props.data || !props.data.urlResolver) {
    if (typeof props.isCached !== 'undefined' && !props.isCached)
      return (
        <div>
          <ReactLoader />
        </div>
      );
  }
  console.log(props.data)
  if (!isUndefined(props.data.urlResolver)) {
    const urlResolver = props.data.urlResolver;
    if (urlResolver !== null && PageTypes.hasOwnProperty(urlResolver.type)) {
      const Page = PageTypes[urlResolver.type];
      return (
        <Page
          {...props}
          id={urlResolver.id}
          url={props.variables.url}
          variables={{ id: urlResolver.id, url: props.variables.url }}
        />
      );
    }
  }

  return <ErrorPage />;
};

export default DynamicRouter;
