import apolloClient from "../../../../graphql/apolloClient";

import commonActions from "../../common/actions";
import queryLoader from "../../../../graphql/queryLoader";
import messagesActions from "../../messages/actions";
import exportListActions from "../actions";
import { isUndefined } from "../../../../helper/functions";

export default async (store, action) => {
  try {
    const { data } = await apolloClient.query({
      query: queryLoader("customerExportList")
    });

    if (isUndefined(data) || !data) {
      store.dispatch(
        messagesActions.addMessage(
          "No data found for this customers export list.",
          "danger"
        )
      );
    }
    store.dispatch(exportListActions.getCustomerExportListSuccess(data));
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
