import React from "react";
import "./input.scss";
import Icon from "../../assets";
import { isUndefined } from "../../state/helper/functions";

const Input = props => {
  let additionalAttributes = {};
  if (!isUndefined(props.disabled) && props.disabled === true)
    additionalAttributes["disabled"] = true;

  if (props.type === "textarea")
    return (
      <textarea
        style={props.style}
        placeholder={props.placeholder}
        name={props.name}
        className={`input-field input-textarea input-${props.className}`}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onClick={props.onClick}
      ></textarea>
    );
  return (
    <>
      {!props.min && !props.max && (
        <input
          {...additionalAttributes}
          id={props.id}
          style={props.style}
          name={props.name}
          type={props.type}
          className={`input-field input-${props.className}`}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onClick={props.onClick}
          onFocus={props.onFocus}
          onKeyPress={props.onKeyPress}
          onKeyDown={props.onKeyDown}
          pattern={props.pattern}
          tabIndex={props.tabIndex}
          maxLength={props.maxLength}
          onInput={props.onInput}
        />
      )}
      {props.min && props.max && (
        <input
          style={props.style}
          name={props.name}
          type={props.type}
          className={`input-field-range input-${props.className}`}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onClick={props.onClick}
          onFocus={props.onFocus}
          min={props.min}
          max={props.max}
          step={props.step}
          tabIndex={props.tabIndex}
        />
      )}
      {props.icon && (
        <span className="icon">
          <Icon id={props.icon} />
        </span>
      )}
      {props.required && <span className="required">required</span>}
    </>
  );
};

export default Input;
