import React from "react";
import { connect } from "react-redux";
import { customerActions } from "../../../state/redux/data/customer";
import RegisterSuccessPage from "./registerSuccess";

const CustomerRegisterReduxContainer = props => {
  if (props.status) props.onRegisterCallback(false);
  return <RegisterSuccessPage {...props} />;
};

const mapStateToProps = state => ({
  status: state.customer.status
});

const mapDispatchToProps = dispatch => {
  return {
    onRegisterCallback: status => {
      dispatch(customerActions.registerCustomerSuccess(status));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerRegisterReduxContainer);
