import React from "react";
import { Link } from "react-router-dom";
import "./breadcrumbs.scss";

const Breadcrumbs = props => {
  return (
    <div className={`breadcrumbs`}>
      <ul>
        <li>
          {props.links.map(link => (
            <Link key={link.url} to={link.url}>
              {link.title}
            </Link>
          ))}
        </li>
      </ul>
    </div>
  );
};

export default Breadcrumbs;
