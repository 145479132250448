import React, { useState } from "react";
import ProductItem from "../../utils/products/listing/productItem";
import { isUndefined } from "../../state/helper/functions";
import "./productRelated.scss";
import ReactLoader from "../../utils/loader";
import Carousel from "../../utils/carousel";
import { NumberItems } from "./media-items";
// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";

const ProductRelated = props => {
  const [program, setProgram] = useState("");

  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsInSlide] = useState(4);
  if (isUndefined(props.data)) {
    return <ReactLoader />;
  }

  let items = [];
  let _items = props.data;
  let _itemCount = _items.products.items.length;

  if (_itemCount < 4) {
    return <></>;
  }

  Object.keys(_items.products.items).map((elem, index) => {
    let product = _items.products.items[index];
    return items.push(
      // <div key={`related-list ${index}`} onClick={e => setCurrentIndex(index)}>
      <ProductItem
        key={`product-list-${product.id}`}
        productName={product.name}
        productNumber={product.sku}
        productItemStock={product.warehouse_qty}
        productArrivalQty={product.arrival_qty}
        productItemDate={product.last_updated_date}
        productLeadTime={product.lead_time}
        productMOQ={product.moq}
        productImage={product.image}
        productImageUrl={product.image.url}
        productUrl={product.url_key}
        tierPrices={product.tier_prices}
        productSmallImage={product.small_image}
        product={product}
        exportList={props.exportList}
        program={program}
        setProgram={setProgram}
        sku={product.sku}
      />
      // </div>
    );
  });

  const slidePrevSlide = () => {
    let newIndex = currentIndex - itemsInSlide;
    if (newIndex < 0) newIndex = 0;
    setCurrentIndex(newIndex);
  };

  const slideNextSlide = () => {
    let newIndex = currentIndex + itemsInSlide;
    if (newIndex > items.length - 1) newIndex = items.length - itemsInSlide;
    setCurrentIndex(newIndex);
  };

  return (
    <div id="productrelated">
      <h2>MORE FROM BRAND {program}</h2>
      {items && (
        <Carousel
          {...props}
          responsive={NumberItems}
          carouselId={"related"}
          itemsInSlide={itemsInSlide}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          slideNextSlide={slideNextSlide}
          slidePrevSlide={slidePrevSlide}
          dotsDisabled={true}
          infinite={false}
          stopAutoPlayOnHover={true}
          duration={500}
          autoPlay={false}
          autoPlayInterval={3000}
          onSlideChangedOverwrite={true}
          items={items}
        />
      )}
    </div>
  );
};

export default ProductRelated;
