// Middleware
import types from "./types";
import createExportList from "./middleware/createExportList";
import exportList from "./middleware/exportList";
import getCustomerExportList from "./middleware/getCustomerExportList";
import renameExportList from "./middleware/renameExportList";
import addSkuToList from "./middleware/addSkuToList";
import removeSkuFromList from "./middleware/removeSkuFromList";
import deleteExportList from "./middleware/deleteExportList";
import saveListBtn from "./middleware/saveListBtn";
import removeAllItemsFromList from "./middleware/removeAllItemsFromList";

export default store => {
  return next => {
    return async action => {
      next(action);
      switch (action.type) {
        case types.CREATE_EXPORT_LIST:
          return createExportList(store, action);

        case types.EXPORT_LIST:
          return exportList(store, action);
        case types.GET_CUSTOMER_EXPORT_LIST:
          return getCustomerExportList(store, action);

        case types.RENAME_EXPORT_LIST:
          return renameExportList(store, action);

        case types.ADD_SKU_TO_LIST:
          return addSkuToList(store, action);

        case types.REMOVE_SKU_FROM_LIST:
          return removeSkuFromList(store, action);

        case types.DELETE_EXPORT_LIST:
          return deleteExportList(store, action);

        case types.SAVE_EXPORT_LIST_BTN:
          return saveListBtn(store, action);

        case types.REMOVE_ALL_ITEMS_FROM_LIST:
          return removeAllItemsFromList(store, action);

        default:
          return;
      }
    };
  };
};
