import React from "react";
import ContentHeader from "../../../../utils/contentHeader";
import Button from "../../../../utils/buttons";
import "./about.scss";

const About = props => {
  return (
    <div className="home-about-block">
      <ContentHeader heading={props.title} subHeading={props.subtitle} />
      <p>{props.description}</p>
      <Button
        className={"button btn-with-right-icon btn-orange right-arrow"}
        text={"Learn More "}
        icon={"rightArrow"}
        to={props.buttonUrl}
      />
    </div>
  );
};

export default About;
