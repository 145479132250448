import React from "react";
import ListingQueryContainer from "./listing-query-container";
import { isUndefined } from "../../../../state/helper/functions";

const ListingDataContainer = props => {
  let activeProject;
  let items;
  props.skuList.map(list => {
    if (list !== null) {
      if (list.listId === props.project) {
        return (activeProject = list);
      }
    }
  });
  if (!isUndefined(activeProject)) {
    items = activeProject.skuList;
  } else {
    return "";
  }

  if (items === null || items === "") return <h2>This list is empty.</h2>;
  let itemsArray = items.split(",");

  let variables = { filters: { sku: { in: itemsArray } } };
  return (
    <ListingQueryContainer
      {...props}
      variables={variables}
      itemsArray={itemsArray}
    />
  );
};

export default ListingDataContainer;
