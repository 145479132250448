import React from "react";
import { connect } from "react-redux";
import commonActions from "../../../../../../../state/redux/data/common/actions";
import exportListActions from "../../../../../../../state/redux/data/exportList/actions";
import ActionToolbar from "./product-action-toolbar";

const ActionToolbarReduxContainer = props => {
  return <ActionToolbar {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer,
  skuList: state.exportList.items,
  exportList: state.exportList
});

const mapDispatchToProps = dispatch => {
  return {
    removeSkuFromList: (listId, sku) => {
      dispatch(exportListActions.removeSkuFromList(listId, sku));
    },
    addSkuToList: (listId, sku) => {
      dispatch(exportListActions.addSkuToList(listId, sku));
    },
    showModal: (identifier, show) => {
      dispatch(commonActions.showModal(identifier, show));
    },
    // closeModal: identifier => {
    //   dispatch(commonActions.closeModal(identifier));
    // }
    toggleDownload: newStatus => {
      dispatch(
        exportListActions.toggleExportListDownloadAvailability(newStatus)
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionToolbarReduxContainer);
