import React from "react";
import Breadcrumbs from "../../../utils/breadcrumbs";
import CmsBlock from "../../../utils/cmsBlock";
import CallToAction from "../../../utils/callToAction";
import Button from "../../../utils/buttons";
import ContentHeader from "../../../utils/contentHeader";
import GraphqlWrapper from "../../../utils/graphQlWrapper";
import Brands from "../home/brands";
import themeConfiguration from "../../../state/config/themeConfiguration";
import queryLoader from "../../../state/graphql/queryLoader";
import HeroImage from "../../../utils/heroImage";
import { Helmet } from "react-helmet";
import "./aboutUs.scss";
const query = queryLoader("getDynamicContent");
const AboutUs = props => {
  let backgroundImageUrl1 = "./images/customer-service-call-out-full.jpg";
  let backgroundImageUrl2 = "./images/our-services-call-out-half.jpg";
  let style = {
    customerService: {
      backgroundImage: "url(" + backgroundImageUrl1 + ")"
    },
    ourServices: {
      backgroundImage: "url(" + backgroundImageUrl2 + ")"
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - {themeConfiguration.name}</title>
        <link rel="canonical" href={`${themeConfiguration.app_url}/about-us`} />
      </Helmet>
      <div className="about-us-container">
        <div className="main-banner">
          <HeroImage source={"AboutUs.jpg"} alt={"About Us banner image"} />
        </div>
        <div className={`about-us-inner-content`}>
          <Breadcrumbs
            links={[
              { url: "/", title: "Home | " },
              { url: "#", title: "About Us" }
            ]}
          />
          <div className="about-us-top-block">
            <CmsBlock dangHtml={true} id="about_us_top" />
          </div>
          <div className="about-us-brands">
            <ContentHeader
              heading={"OUR BRANDS"}
              subHeading={
                "Hundreds of brands, thousands of products, all in one place."
              }
            />
            <div className="about-us-brands-bottom">
              <div className="about-us-brands-images-dynamic">
                <GraphqlWrapper
                  query={query}
                  variables={{ id: 3, identifier: "dynamic-content" }}
                  saveQuery={true}
                >
                  <Brands />
                </GraphqlWrapper>
              </div>
              <div className="about-us-brands-button">
                <Button
                  className={
                    "button btn-with-right-icon btn-orange right-arrow"
                  }
                  text={"View Brands"}
                  icon={"rightArrow"}
                  to={"/brands"}
                />
              </div>
            </div>
          </div>
          <div className="about-us-above-footer">
            <div className="about-us-above-footer-inner">
              <CallToAction
                title={"Customer Service"}
                subtitle={"Get Connected"}
                buttonText={"Contact Us"}
                backgroundImage={style.customerService}
                url={"/contact"}
              />
              <CallToAction
                title={"Our Service"}
                subtitle={"What We Offer"}
                buttonText={"Learn More"}
                backgroundImage={style.ourServices}
                url={"/services"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutUs;
