let color = "#e88602";

export default {
  magento_url: "https://magento.jolapwa.com/",
  // magento_url: "https://brandation.joladev.com/", // dev
  graphql_url: "graphql",
  media_url: "/pub/media",
  catalog_url: "/catalog/product",
  category_url: "/catalog/category",
  app_url: "https://magento.jolapwa.com/",
  // app_url: "https://brandation.joladev.com/", //dev
  name: "Brandation",
  root_category_id: 2,
  sessionMessageTime: 4000,
  colors: {
    primary: color
  },
  loader: {
    color: color,
    height: 100,
    width: 100,
    type: "MutatingDots"
  },
  mediaGallery: {
    nav: "thumbs",
    desktop: {
      width: 800,
      height: 600
    },
    mobile: {
      width: "auto",
      height: "auto"
    }
  },
  pricing: {
    defaultCurrency: "USD"
  }
};
