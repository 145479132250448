import React, { useState } from "react";
import CompoundSlider from "./compoundSlider";

const CompoundSliderState = props => {
  let { initialValues } = props;
  const { useInputs, valueChangeHandler, addFilter, attribute_code } = props;
  const [currentValues, setCurrentValues] = useState();

  if (initialValues.min === null) initialValues.min = "0";
  if (initialValues.max === null) initialValues.max = "0";
  if (typeof initialValues.min === "string") {
    initialValues = {
      min: parseInt(initialValues.min),
      max: parseInt(initialValues.max)
    };
  }
  if (initialValues.min === initialValues.max) return "";

  return (
    <CompoundSlider
      min={initialValues.min}
      max={initialValues.max}
      step={1}
      minId={`min-${attribute_code}-slider`}
      maxId={`max-${attribute_code}-slider`}
      useInputs={useInputs}
      valueChangeHandler={valueChangeHandler}
      currentValues={currentValues}
      setCurrentValues={setCurrentValues}
      addFilter={addFilter}
    />
  );
};

export default CompoundSliderState;
