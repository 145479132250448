import React from "react";
import "./contentHeader.scss";

const ContentHeader = props => {
  return (
    <div className={`contentheader ${props.className}`}>
      <h3>{props.heading}</h3>
      <h1>{props.subHeading}</h1>
    </div>
  );
};

export default ContentHeader;
