import { isUndefined } from "../state/helper/functions";

export function getAllCategories(category_data, category_id) {
  if (isUndefined(category_data)) return [];
  let items = [];
  return mapCategories(category_data, category_id, items);
}
function mapCategories(current_category, category_id, items) {
  let selectedCategory = current_category;
  let selectedId = category_id;
  let itemsData = items;
  Object.keys(selectedCategory).map((element, index) => {
    itemsData[selectedCategory[element].id] = {
      id: selectedCategory[element].id,
      status: false
    };
    if (!isUndefined(selectedCategory[element].children)) {
      mapCategories(selectedCategory[element].children, selectedId, itemsData);
    }
    return true;
  });

  return items;
}
