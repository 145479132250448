import React from "react";
import { connect } from "react-redux";
import commonActions from "../../../../state/redux/data/common/actions";
import exportListActions from "../../../../state/redux/data/exportList/actions";
import ToolbarStateContainer from "./toolbar-state-container";

const ToolbarReduxContainer = props => {
  return <ToolbarStateContainer {...props} />;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    createExportList: name => {
      dispatch(exportListActions.createExportList(name));
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
    toggleDownload: newStatus => {
      dispatch(
        exportListActions.toggleExportListDownloadAvailability(newStatus)
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToolbarReduxContainer);
