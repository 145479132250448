import React, { useState } from "react";
import FilterWrapper from "./filterWrapper";
import CloseButton from "../../../../closeButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./filterItem.scss";
const FilterItem = props => {
  const [toggle, setToggle] = useState(true);
  const toggleDropdown = () => {
    let currentToggle = toggle;
    setToggle(!currentToggle);
  };

  return (
    <>
      <FilterWrapper {...props}>
        <li
          id={props.value}
          key={props.index}
          className={`children-${toggle} open-dropdown category-dropdown${(props.children &&
            " brand-dropdown") ||
            ""} filter-list ${props.className} ${props.activeFilter &&
            "category-with-close-btn"}`}
        >
          {props.activeFilter && (
            <CloseButton className="close-btn" onClick={props.removeFilter} />
          )}
          {(props.children && (
            <span className="filter-list-name" onClick={toggleDropdown}>
              {props.categoryName}
            </span>
          )) || (
            <span className="filter-list-name" onClick={props.onClick}>
              {props.categoryName}
            </span>
          )}
          {props.children && (
            <span className="icon" onClick={toggleDropdown}>
              {(toggle && <FontAwesomeIcon icon="arrow-down" />) || (
                <FontAwesomeIcon icon="arrow-up" />
              )}
            </span>
          )}
          {props.children && (
            <FilterWrapper wrapper={true} toggle={toggle}>
              {props.children}
            </FilterWrapper>
          )}
        </li>
      </FilterWrapper>
    </>
  );
};

export default FilterItem;
