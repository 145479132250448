const GET_EXPORT_LIST = "jola/exportList/GET_EXPORT_LIST";
const GET_CUSTOMER_EXPORT_LIST = "jola/exportlist/GET_CUSTOMER_EXPORT_LIST";
const GET_CUSTOMER_EXPORT_LIST_SUCCESS =
  "jola/exportlist/GET_CUSTOMER_EXPORT_LIST_SUCCESS";
const CREATE_EXPORT_LIST = "jola/exportList/CREATE_EXPORT_LIST";
const CREATE_EXPORT_LIST_SUCCESS = "jola/exportList/CREATE_EXPORT_LIST_SUCCESS";
const RENAME_EXPORT_LIST = "jola/exportList/RENAME_EXPORT_LIST";
const RENAME_EXPORT_LIST_SUCCESS = "jola/exportList/RENAME_EXPORT_LIST_SUCCESS";
const DELETE_EXPORT_LIST = "jola/exportList/DELETE_EXPORT_LIST";
const DELETE_EXPORT_LIST_SUCCESS = "jola/exportList/DELETE_EXPORT_LIST_SUCCESS";
const REMOVE_ITEM_FROM_LIST = "jola/exportlist/REMOVE_ITEM_FROM_LIST";
const EXPORT_LIST = "jola/exportList/EXPORT_LIST";
const CREATE_EXPORT_LIST_NAME = "jola/exportList/CREATE_EXPORT_LIST_NAME";
const ADD_SKU_TO_LIST = "jola/exportList/ADD_SKU_TO_LIST";
const ADD_SKU_TO_LIST_SUCCESS = "jola/exportList/ADD_SKU_TO_LIST_SUCCESS";
const REMOVE_SKU_FROM_LIST = "jola/exportList/REMOVE_SKU_FROM_LIST";
const REMOVE_SKU_FROM_LIST_SUCCESS =
  "jola/exportList/REMOVE_SKU_FROM_LIST_SUCCESS";
const EXPORT_LIST_DATA = "jola/exportList/EXPORT_LIST_DATA";
const _REDUCE_CSV_DATA = "jola/exportList/_REDUCE_CSV_DATA";
const SAVE_EXPORT_LIST_BTN = "jola/exportList/SAVE_EXPORT_LIST_BTN";
const SAVE_EXPORT_LIST_BTN_SUCCESS =
  "jola/exportList/SAVE_EXPORT_LIST_BTN_SUCCESS";
const REMOVE_ALL_ITEMS_FROM_LIST = "jola/exportList/REMOVE_ALL_ITEMS_FROM_LIST";
const _REDUCE_EXPORT_LIST = "jola/exportList/_REDUCE_EXPORT_LIST";
const _REDUCE_EXPORT_LIST_LOGOUT = "jola/exportList/_REDUCE_EXPORT_LIST_LOGOUT";
const TOGGLE_EXPORT_LIST_DOWNLOAD_AVAILABILITY =
  "jola/exportlist/TOGGLE_EXPORT_LIST_DOWNLOAD_AVAILABILITY";
const PENDING_EXPORT_LIST = "jola/exportlist/PENDING_EXPORT_LIST";

export default {
  GET_EXPORT_LIST,
  GET_CUSTOMER_EXPORT_LIST,
  CREATE_EXPORT_LIST,
  CREATE_EXPORT_LIST_SUCCESS,
  CREATE_EXPORT_LIST_NAME,
  RENAME_EXPORT_LIST,
  RENAME_EXPORT_LIST_SUCCESS,
  DELETE_EXPORT_LIST,
  DELETE_EXPORT_LIST_SUCCESS,
  REMOVE_ITEM_FROM_LIST,
  EXPORT_LIST,
  GET_CUSTOMER_EXPORT_LIST_SUCCESS,
  ADD_SKU_TO_LIST,
  ADD_SKU_TO_LIST_SUCCESS,
  REMOVE_SKU_FROM_LIST,
  REMOVE_SKU_FROM_LIST_SUCCESS,
  EXPORT_LIST_DATA,
  _REDUCE_CSV_DATA,
  SAVE_EXPORT_LIST_BTN,
  SAVE_EXPORT_LIST_BTN_SUCCESS,
  REMOVE_ALL_ITEMS_FROM_LIST,
  _REDUCE_EXPORT_LIST,
  _REDUCE_EXPORT_LIST_LOGOUT,
  TOGGLE_EXPORT_LIST_DOWNLOAD_AVAILABILITY,
  PENDING_EXPORT_LIST
};
