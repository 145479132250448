import React from "react";
import "./validation.scss";

const ValidationWrapper = props => {
  const children = React.Children.map(props.children, child => {
    return React.cloneElement(child, { ...props });
  });
  return <>{children}</>;
};

export default ValidationWrapper;
