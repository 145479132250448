import React from "react";
import { useQuery } from "@apollo/react-hooks";
import queryLoader from "../../../../state/graphql/queryLoader";
import SearchProducts from "./searchProducts";
import ReactLoader from "../../../../utils/loader";
import { isUndefined } from "../../../../state/helper/functions";

const query = queryLoader("searchProducts");

const SearchProductsQueryContainer = props => {
  let { sort, search, loadMoreItemsRedux } = props;
  if (isUndefined(sort)) {
    sort = { name: "ASC" };
  }
  let variables = { search: search, sort: sort };
  variables["pageSize"] = 20;
  variables["currentPage"] = 1;

  const loadMoreItems = currentPage => {
    variables["currentPage"] = currentPage + 1;
    fetchMore({
      variables: variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        let items = [...prev.products.items, ...fetchMoreResult.products.items];
        fetchMoreResult.products.items = items;
        return {
          ...fetchMoreResult
        };
      }
    });
  };

  const { loading, error, data, refetch, fetchMore } = useQuery(query, {
    variables: variables,
    skip: !variables
  });

  if (loading) {
    return (
      <>
        <div id="search-products">
          <div className="search-products-content">
            <ReactLoader />
          </div>
        </div>
      </>
    );
  }

  if (error) return `Error! ${error}`;
  return (
    <SearchProducts
      {...props}
      refetch={refetch}
      data={data}
      showFilters={props.showFilters}
      loadMoreItems={loadMoreItems}
      loadMoreItemsRedux={loadMoreItemsRedux}
    />
  );
};

export default SearchProductsQueryContainer;
