import React from "react";
import { connect } from "react-redux";
import customerActions from "../../../state/redux/data/customer/actions";
import { filterActions } from "../../../state/redux/data/filters";
import ToolbarStateContainer from "./toolbar-state-container";
import { isUndefined } from "../../../state/helper/functions";
import exportListActions from "../../../state/redux/data/exportList/actions";

const ToolbarReduxContainer = props => {
  let { data } = props;

  if (isUndefined(props.onlySort)) {
    if (isUndefined(props.data)) return "";
  } else {
    props.addInitialCategoryData(undefined);
    data = props.searchData;
  }
  return <ToolbarStateContainer {...props} data={data} />;
};
const mapStateToProps = state => ({
  data: state.filters.data,
  category: state.filters.category,
  exportLists: state.exportList.items
});

const mapDispatchToProps = dispatch => {
  return {
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    changeSortFilter: sort => {
      dispatch(filterActions.changeSortFilter(sort));
    },
    addInitialCategoryData: data => {
      dispatch(filterActions.addInitialCategoryData(data));
    },
    saveListBtn: data => {
      dispatch(exportListActions.saveListBtn(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToolbarReduxContainer);
