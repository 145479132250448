import React from "react";
import Button from "../../../../../utils/buttons";
import "./toolbar-listing.scss";

const ToolbarListing = props => {
  let { toggleDownload, changeProjectHandler } = props;
  return (
    <div id="toolbar-listing">
      {props.projectsList["data"].map((project, index) => {
        if (props.project === project.id)
          return (
            <Button
              key={index}
              className={"button btn-orange"}
              text={project.name}
              id={project.id}
            />
          );
        return (
          <Button
            key={index}
            className={"button btn-white-disabled"}
            text={project.name}
            id={project.id}
            onClick={e => {
              changeProjectHandler(project.id);
              toggleDownload(false);
            }}
          />
        );
      })}
    </div>
  );
};

export default ToolbarListing;
