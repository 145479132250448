import React, { useEffect } from "react";
import Component from "./graphQlWrapper";
import ReactLoader from "../loader";
import { isUndefined, isEmpty } from "../../state/helper/functions";

const GraphQlQueryWrapper = props => {
  let {
    data,
    variables,
    query,
    type,
    addQuery,
    saveQuery,
    customer,
    thisTemp,
    loading,
    finalChildren
  } = props;

  useEffect(() => {
    if (isEmpty(data) && saveQuery && !isEmpty(thisTemp)) {
      if (
        !isUndefined(variables) &&
        !isUndefined(variables.id) &&
        !isUndefined(variables.url)
      ) {
        addQuery(variables.id, variables.url, query, variables, type);
      } else {
        if (!isUndefined(variables.identifier)) {
          addQuery(variables.identifier, "query", query, variables, type);
        } else {
          addQuery(null, "query", query, variables, type);
        }
      }
    } else if (isEmpty(data) && isEmpty(thisTemp) && loading === true) {
      if (
        !isUndefined(variables) &&
        !isUndefined(variables.id) &&
        !isUndefined(variables.url)
      ) {
        addQuery(variables.id, variables.url, query, variables, type);
      } else {
        if (!isUndefined(variables.identifier)) {
          addQuery(variables.identifier, "query", query, variables, type);
        } else {
          addQuery(null, "query", query, variables, type);
        }
      }
    } else if (data === thisTemp && saveQuery === true) {
      if (
        !isUndefined(variables) &&
        !isUndefined(variables.id) &&
        !isUndefined(variables.url)
      ) {
        addQuery(variables.id, variables.url, query, variables, type);
      } else {
        if (!isUndefined(variables.identifier)) {
          addQuery(variables.identifier, "query", query, variables, type);
        } else {
          addQuery(null, "query", query, variables, type);
        }
      }
    } else if (!saveQuery) {
      if (
        !isUndefined(variables) &&
        !isUndefined(variables.id) &&
        !isUndefined(variables.url)
      ) {
        addQuery(variables.id, variables.url, query, variables, type);
      } else {
        if (!isUndefined(variables.identifier)) {
          addQuery(variables.identifier, "query", query, variables, type);
        } else {
          addQuery(null, "query", query, variables, type);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  if (isEmpty(data) && !isEmpty(thisTemp)) {
    data = thisTemp;
  }

  let error;

  if (loading) {
    return <div>{props.children}</div>;
  }

  if (error) return <div>Error: {error.message}</div>;

  const children = React.Children.map(finalChildren, child => {
    if (!loading && data) {
      return React.cloneElement(child, { data: data });
    }
  });

  return <Component {...props}>{children}</Component>;
};

export default GraphQlQueryWrapper;
