import Login from "../../content/pages/login";
import Register from "../../content/pages/register";
import Homepage from "../../content/pages/home";
import Customer from "../../customer";
import Typography from "../../content/pages/typography";
import ContactPage from "../../content/pages/contact";
import AboutUs from "../../content/pages/aboutUs";
import OurServices from "../../content/pages/ourServices";
import Brands from "../../content/pages/brands";
import ForgotPasswordPage from "../../content/pages/forgotPassword";
import RegisterSuccessPage from "../../content/pages/registerSuccess";
import SearchPage from "../../content/pages/search";

export default {
  home: { component: Homepage, exact: false },
  login: { component: Login, exact: false },
  "register/success": { component: RegisterSuccessPage, exact: false },
  "forgot-password": { component: ForgotPasswordPage, exact: false },
  register: { component: Register, exact: false },
  "about-us": { component: AboutUs, exact: false },
  customer: { component: Customer, exact: false },
  typography: { component: Typography, exact: false },
  contact: { component: ContactPage, exact: false },
  services: { component: OurServices, exact: false },
  brands: { component: Brands, exact: true },
  "search-page": { component: SearchPage, exact: false }
};

export { default as ErrorPage } from "../../content/pages/error";

export const DefaultPage = Homepage;
