import React from "react";
import "./usp.scss";
import Icon from "../../../../../assets";

const Usp = props => {
  return (
    <div className="usp">
      <Icon id={props.i} />
      <div className="title">
        <h3>{props.title}</h3>
      </div>
    </div>
  );
};

export default Usp;
