import types from "./types";
import loginCustomerReducer from "./reducers/loginCustomer";
import logoutCustomerReducer from "./reducers/logoutCustomer";
import setCustomerTokenReducer from "./reducers/setCustomerToken";
import getCustomerInformationReducer from "./reducers/getCustomerInformation";
import setCustomerInformation from "./reducers/setCustomerInformation";
import clearCustomerInformation from "./reducers/clearCustomerInformation";
import loginCustomerFinish from "./reducers/loginCustomerFinish";
import registerCustomer from "./reducers/registerCustomer";
import registerCustomerSuccess from "./reducers/registerCustomerSuccess";
import resetCustomerPasswordSuccess from "./reducers/resetCustomerPasswordSuccess";
import editCustomerAddress from "./reducers/editCustomerAddress.js";
import _reduceCustomerAddress from "./reducers/_reduceCustomerAddress";
import _reduceCustomer from "./reducers/_reduceCustomer";

// Main reducer
const customerReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }

  if (action.type === types.LOGIN_CUSTOMER) {
    return loginCustomerReducer(state, action);
  }

  if (action.type === types.LOGOUT_CUSTOMER) {
    return logoutCustomerReducer(state, action);
  }

  if (action.type === types.SET_CUSTOMER_TOKEN) {
    return setCustomerTokenReducer(state, action);
  }

  if (action.type === types.SET_CUSTOMER_TOKEN_REGISTRATION) {
    return setCustomerTokenReducer(state, action);
  }

  if (action.type === types.GET_CUSTOMER_INFORMATION) {
    return getCustomerInformationReducer(state, action);
  }

  if (action.type === types.SET_CUSTOMER_INFORMATION) {
    return setCustomerInformation(state, action);
  }

  if (action.type === types.CLEAR_CUSTOMER_INFORMATION) {
    return clearCustomerInformation(state, action);
  }

  if (action.type === types.LOGIN_CUSTOMER_FINISH) {
    return loginCustomerFinish(state, action);
  }

  if (action.type === types.REGISTER_CUSTOMER) {
    return registerCustomer(state, action);
  }

  if (action.type === types.REGISTER_CUSTOMER_SUCCESS) {
    return registerCustomerSuccess(state, action);
  }
  if (action.type === types.EDIT_CUSTOMER_ADDRESS) {
    return editCustomerAddress(state, action);
  }

  if (action.type === types._REDUCE_CUSTOMER) {
    return _reduceCustomer(state, action);
  }

  if (action.type === types._REDUCE_CUSTOMER_ADDRESS) {
    return _reduceCustomerAddress(state, action);
  }

  if (action.type === types.RESET_CUSTOMER_PASSWORD_SUCCESS) {
    return resetCustomerPasswordSuccess(state, action);
  }

  return state;
};

export default customerReducer;
