import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Button from "../../utils/buttons";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pageTitle } from "../../state/config/customerPages";
import Icon from "../../assets";
import "./top.scss";
import { isUndefined } from "util";

const HeaderTop = props => {
  const [toggle, setToggle] = useState(false);
  let { skuList, loggedIn } = props;
  let itemCount = 0;
  const myAccountElement = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        myAccountElement.current &&
        !myAccountElement.current.contains(event.target)
      ) {
        setToggle(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  if (loggedIn) {
    if (!isUndefined(skuList)) {
      if (skuList[0]) {
        if (skuList[0].skuList !== null && skuList[0].skuList !== "") {
          let items = skuList[0].skuList.split(",");
          itemCount = items.length;
        } else {
          itemCount = 0;
        }
      }
    } else {
      itemCount = 0;
    }
  }
  if (window.screen.width > 768) {
    let prevScrollPos = window.pageYOffset;
    window.onscroll = function () {
      let currentScrollPos = window.pageYOffset;
      if (prevScrollPos > currentScrollPos) {
        document.getElementById("top-header").style.top = "0";
        document.getElementById("bottom-header").style.top = "39px";
      } else {
        document.getElementById("top-header").style.top = "-100px";
        document.getElementById("bottom-header").style.top = "0";
      }
      prevScrollPos = currentScrollPos;
    };
  }

  if (!props.phonenumber.match(/^\d+\.\d+\.\d+$/)) {
    // console.log("@todo: Phone number in unexpected format");
  }
  if (!props.customer) {
    // console.log("@todo: figure out what to do if customer is missing");
  }

  const toggleMyAccountDropdown = () => {
    let currentToggle = toggle;
    if (currentToggle === false) {
      window.addEventListener("scroll", function (e) {
        setToggle(currentToggle);
      });
    }
    setToggle(!currentToggle);
  };

  return (
    <div className={"header-top-container"} id="top-header">
      <div className="header-top">
        <div className="left">
          {props.phonenumber && (
            <div className="telephone" rel="phonenumber">
              <a href="tel:+16467917165">{props.phonenumber}</a>
            </div>
          )}
        </div>
        {!props.loggedIn && (
          <div className="right">
            <Button
              className="login"
              onClick={e => props.showModal("loginModal", true)}
            >
              Enter Portal
            </Button>
            <Link className="register" to={"/register"}>
              Register
            </Link>
          </div>
        )}
        {props.loggedIn && (
          <div className="right loggedin-customer">
            {props.customer.firstname && (
              <p id={"welcome-customer-msg"}>
                Welcome, {props.customer.firstname}
              </p>
            )}
            <ul
              className={`my-account my-account-${toggle}`}
              disabled={props.locked}
              ref={myAccountElement}
              onClick={e => {
                props.handleClick(e, props);
                toggleMyAccountDropdown();
              }}
              onMouseLeave={() => setToggle(false)}
            >
              My Account{" "}
              <span className="icon" onClick={toggleMyAccountDropdown}>
                {(toggle && <FontAwesomeIcon icon="arrow-up" />) || (
                  <FontAwesomeIcon icon="arrow-down" />
                )}
              </span>
              <div className="my-account-dropdown">
                {Object.keys(pageTitle).map(url => {
                  let link = pageTitle[url];
                  if (link.useInDropdownMenu) {
                    return (
                      <li key={`${url}-account-dropdown`}>
                        <Link
                          className="account-dashboard"
                          to={`/${url}`}
                          onClick={e => {
                            if (url === "logout") {
                              e.preventDefault();
                              if (!props.locked) {
                                props[link.onClick]();
                              }
                            }
                          }}
                        >
                          {link.title}
                        </Link>
                      </li>
                    );
                  } else {
                    return "";
                  }
                })}
              </div>
            </ul>
            <Link className="export-list-items-number" to={"/customer/export"}>
              <Icon id={"excelWhite"} />
              <p>{itemCount}</p>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

HeaderTop.propTypes = {
  phonenumber: PropTypes.string.isRequired
};

export default HeaderTop;
