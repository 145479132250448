import React, { useEffect } from "react";
import { isEmpty, isUndefined } from "../../state/helper/functions";
import "./modal.scss";

/* 
  customerRender is used when the customer token is present
*/
const Modal = props => {
  let {
    finalChildren,
    title,
    subHeading,
    footerCTA,
    footer,
    changeOnCustomerLogin,
    customerRender,
    customer
  } = props;
  let customerInformation;

  useEffect(() => {}, []);

  if (!isUndefined(customer)) {
    customerInformation = customer;
  }
  //Error TODO
  if (!isUndefined(finalChildren)) {
    customerInformation = finalChildren.props.customer;
  }

  if (changeOnCustomerLogin) {
    if (!isUndefined(customerInformation)) {
      if (isUndefined(customerInformation.token)) {
        customerInformation["token"] = false;
      } else {
        if (isEmpty(customerInformation)) {
          customerInformation["token"] = false;
        }
      }
    } else {
      customerInformation = {};
      customerInformation["token"] = false;
    }
  }

  return (
    <div>
      <section className={"modal-main"}>
        {(changeOnCustomerLogin && (
          <>
            {(customerInformation.token === false && (
              <>
                {title && (
                  <div className={"modal-heading"}>
                    <h2>{title}</h2>
                  </div>
                )}
                <div className={"modal-inner"}>
                  {subHeading && (
                    <section className="modal-subHeading">
                      <h3>{subHeading}</h3>
                    </section>
                  )}
                  {finalChildren && finalChildren}
                </div>

                {footer && (
                  <div className={"modal-footer"}>
                    {footerCTA && (
                      <div className={"footerCTA"}>
                        <h3>
                          {footer}
                          {footerCTA}
                        </h3>
                      </div>
                    )}
                  </div>
                )}
              </>
            )) || <>{customerRender}</>}
          </>
        )) || (
          <>
            {title && (
              <div className={"modal-heading"}>
                <h2>{title}</h2>
              </div>
            )}
            <div className={"modal-inner"}>
              {subHeading && (
                <section className="modal-subHeading">
                  <h3>{subHeading}</h3>
                </section>
              )}
              {finalChildren && finalChildren}
            </div>

            {footer && (
              <div className={"modal-footer"}>
                {footerCTA && (
                  <div className={"footerCTA"}>
                    <h3>
                      {footer}
                      {footerCTA}
                    </h3>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default Modal;
