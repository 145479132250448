// Action Creators
import types from "./types";

const lock = afterUnlockCallback => {
  return { type: types.LOCK, afterUnlockCallback: afterUnlockCallback };
};

const unlock = () => {
  return { type: types.UNLOCK };
};

const checkCustomerAuthorization = timestamp => {
  return { type: types.CHECK_CUSTOMER_AUTHORIZATION, timestamp: timestamp };
};

const forceCheckCustomerAuthorization = () => {
  return { type: types.FORCE_CHECK_CUSTOMER_AUTHORIZATION };
};

const closeAllModals = () => {
  return { type: types.CLOSE_ALL_MODALS };
};

const showModal = (identifier, show) => {
  return {
    type: types.SHOW_MODAL,
    identifier: identifier,
    show: show
  };
};

const closeModal = identifier => {
  return {
    type: types.CLOSE_MODAL,
    identifier: identifier
  };
};

const addQuery = () => {
  return {
    type: types.ADD_QUERY
  };
};

const addQuerySuccess = () => {
  return {
    type: types.ADD_QUERY_SUCCESS
  };
};

const routeChange = match => {
  return {
    type: types.ROUTE_CHANGE,
    match: match
  };
};

const routeChangeSuccess = match => {
  return {
    type: types.ROUTE_CHANGE_SUCCESS
  };
};

const getCacheVersion = (cache, flag) => {
  return {
    type: types.GET_CACHE_VERSION,
    version: cache,
    flag: flag
  };
};

const setCacheVersion = cache => {
  return {
    type: types.SET_CACHE_VERSION,
    version: cache
  };
};

const setFlag = flag => {
  return {
    type: types.SET_FLAG,
    flag: flag
  };
};

const sendEmail = data => {
  return {
    type: types.SEND_EMAIL,
    data: data
  };
};

const setAppVersion = version => {
  return {
    type: types.SET_APP_VERSION,
    version: version
  };
};

export default {
  lock,
  unlock,
  checkCustomerAuthorization,
  forceCheckCustomerAuthorization,
  closeAllModals,
  showModal,
  closeModal,
  addQuery,
  addQuerySuccess,
  routeChange,
  routeChangeSuccess,
  getCacheVersion,
  setCacheVersion,
  setFlag,
  sendEmail,
  setAppVersion
};
