import React, { useState, useEffect } from "react";
import "./productInfoRight.scss";
import ProductInfoRightTop from "./productInfoRightTop";
import ProductInfoRightBottom from "./productInfoRightBottom";

const ProductInfoRight = props => {
  let { customerCanSeeFreight } = props;
  const [offset, setOffset] = useState(0);
  const [productInfoContainer, setProductInfoContainer] = useState(0);
  const [newStyle, setNewStyle] = useState({});
  const [freightStyle, setFreightStyle] = useState({});

  useEffect(() => {
    if (customerCanSeeFreight !== false) {
      document.addEventListener("scroll", function (e) {
        let freight = document.getElementById("productfreight");
        let productinforight = document.getElementsByClassName(
          "productinforight-inner"
        )[0];
        if (document.getElementById("productinfocontainer") !== null) {
          let productInfoContainerHeight = document.getElementById(
            "productinfocontainer"
          ).offsetHeight;
          let currentOffset = window.pageYOffset;
          setProductInfoContainer(productInfoContainerHeight);
          if (window.innerWidth > 1025) {
            freight.style.height = productinforight.offsetHeight + "px";
          }

          if (
            window.innerWidth > 1025 &&
            currentOffset > productInfoContainerHeight
          ) {
            setNewStyle({
              top: productInfoContainerHeight,
              position: "absolute",
              left: "auto",
              minHeight: freight.offsetHeight
            });
            setFreightStyle({
              opacity: "0",
              pointerEvents: "none"
            });
          } else {
            setNewStyle({});
            setFreightStyle({});
          }
          setOffset(currentOffset);
        }
      });
    }
  }, []);

  return (
    <div id="productinforight">
      <div
        className={`productinforight-inner ${(offset > productInfoContainer &&
          "with-border") ||
          "without-border"} ${(customerCanSeeFreight && "with-freight") ||
          "without-freight"}`}
        style={newStyle}
      >
        <div>
          <ProductInfoRightTop {...props} />
          <ProductInfoRightBottom {...props} freightStyle={freightStyle} />
        </div>
      </div>
    </div>
  );
};

export default ProductInfoRight;
