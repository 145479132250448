import apolloClient from "../../../../graphql/apolloClient";

import commonActions from "../../common/actions";
import queryLoader from "../../../../graphql/queryLoader";
import messagesActions from "../../messages/actions";
import exportListActions from "../actions";
import { isUndefined } from "../../../../helper/functions";

const INFORMATION_MISSING_ERROR = "Please fill out all of the data necessary";
export default async (store, action) => {
  let { listId } = action;

  try {
    action.data.productArray.map(item => {
      if (item.qty === null || parseInt(item.qty) === 0 || item.zip === null)
        throw Error(INFORMATION_MISSING_ERROR);

      return true;
    });

    store.dispatch(exportListActions.pendingExportList(true));
    const { data } = await apolloClient.query({
      query: queryLoader("exportListQuery"),
      variables: {
        listId: listId,
        ...action.data
      }
    });

    let sanitizedProducts = [];
    // let sanitizedFreights = [];
    if (!isUndefined(data.exportList)) {
      let { exportList } = data;
      let products = !isUndefined(exportList.products)
        ? exportList.products
        : [];

      products.map(product => {
        let line_data = [];
        line_data = { ...product };
        if (!isUndefined(product.tier_prices) && product.tier_prices !== null) {
          product.tier_prices.map(tier => {
            line_data["qty_for_" + tier.qty.split(".")[0]] = tier.price;
          });
          delete line_data.tier_prices;
        }

        sanitizedProducts.push(line_data);
      });
    }

    store.dispatch(
      // exportListActions._reduceCSVData({
      //   products: sanitizedProducts,
      //   freights: sanitizedFreights
      // })
      exportListActions._reduceCSVData({
        link: data.exportList
      })
    );
    store.dispatch(
      exportListActions.toggleExportListDownloadAvailability(true)
    );
    store.dispatch(exportListActions.pendingExportList(false));
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    store.dispatch(exportListActions.pendingExportList(false));
    return;
  }
};
