import React from "react";
import { connect } from "react-redux";
import exportListActions from "../../../../state/redux/data/exportList/actions";
import ProductInfoRightBottom from "./productInfoRightBottom";
import { messagesActions } from "../../../../state/redux/data/messages";

const ProductInfoRightBottomReduxContainer = props => {
  return <ProductInfoRightBottom {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer,
  exportList: state.exportList
});

const mapDispatchToProps = dispatch => {
  return {
    addSkuToList: (listId, sku) => {
      dispatch(exportListActions.addSkuToList(listId, sku));
    },
    createExportList: name => {
      dispatch(exportListActions.createExportList(name));
    },
    addMessage: message => {
      dispatch(messagesActions.addMessage(message, "danger"));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductInfoRightBottomReduxContainer);
