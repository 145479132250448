import React, { useState, useEffect } from "react";
import About from "../home/about/about";
import Map from "../../../utils/map";
import Services from "../home/services";
import OurProducts from "./ourProducts";

import Carousel from "./carousel";
import GraphqlWrapper from "../../../utils/graphQlWrapper";

import { itemsData, responsiveData } from "./home-data-container";
import { getSlidesFromItems, defaultSlide } from "./home-functions";
import { isEmpty } from "../../../state/helper/functions";
import "./home.scss";
import queryLoader from "../../../state/graphql/queryLoader";
import Brands from "./brands";
const query = queryLoader("getDynamicContent");

const Homepage = props => {
  let showModal = props.showModal;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slides, setSlides] = useState([]);
  let items = itemsData;
  useEffect(() => {
    setSlides(getSlidesFromItems(items, currentIndex, setCurrentIndex));
  }, [items, currentIndex, setCurrentIndex]);

  if (isEmpty(slides)) {
    setSlides([defaultSlide(items)]);
  }
  return (
    <>
      <div id="homepage">
        {!isEmpty(slides) && (
          <>
            {Object.keys(items).length !== 0 && (
              <div className={`home-carousel`}>
                <Carousel
                  mouseDragEnabled
                  onMouseDrag={e => {
                    e.preventDefault();
                  }}
                  responsive={responsiveData}
                  dotsDisabled={true}
                  buttonsDisabled={true}
                  autoPlayInterval={5000}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                >
                  {slides}
                </Carousel>
              </div>
            )}
          </>
        )}
        <div className="home-brands">
          <GraphqlWrapper
            query={query}
            variables={{ id: 3, identifier: "dynamic-content" }}
            saveQuery={true}
          >
            <Brands />
          </GraphqlWrapper>
        </div>
        <About
          title={"ABOUT BRANDATION"}
          subtitle={"THE LEADING B2B ECOMMERCE EXPERIENCE "}
          description={
            "Leveraging a global network, Brandation delivers robust brand solutions paired with a customer centric approach that prioritizes quality and reduces prices. Covering virtually every industry, Brandation offers manufacturing and wholesale services, incentive programs, licensing, marketing opportunities and more to elevate your business."
          }
          buttonUrl={"/about-us"}
        />

        <OurProducts
          showModal={showModal}
          title={"OUR PRODUCTS"}
          subtitle={"A WIDE RANGE OF CATEGORIES"}
          description={``}
          buttonUrl={"/products"}
        />
        <Services />
        <Map />
      </div>
    </>
  );
};

export default Homepage;
