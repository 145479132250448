export let itemsData = {
  0: {
    subheading: "INTRODUCING",
    heading: "XOXO",
    backgroundImageUrl: "./images/Campout.jpg",
    backgroundImageUrlWebP: "./images/WebPFiles/Campout.webp",
    description:
      "Straight off the runway featuring designs that are feminine, flirty, and affordable, XOXO Dresses and Sportswear styles offer a confident contemporary look.",
    buttonUrl: "/brands/house-brands/campout",
    buttonText: "VIEW BRAND "
  },
  1: {
    subheading: "INTRODUCING",
    heading: "XOXO",
    backgroundImageUrl: "./images/Nox.jpg",
    backgroundImageUrlWebP: "./images/WebPFiles/Nox.webp",
    description:
      "Straight off the runway featuring designs that are feminine, flirty, and affordable, XOXO Dresses and Sportswear styles offer a confident contemporary look.",
    buttonUrl: "/brands/house-brands/nox",
    buttonText: "VIEW BRAND "
  },
  2: {
    subheading: "INTRODUCING",
    heading: "XOXO",
    backgroundImageUrl: "./images/Nucast.jpg",
    backgroundImageUrlWebP: "./images/WebPFiles/Nucast.webp",
    description:
      "Straight off the runway featuring designs that are feminine, flirty, and affordable, XOXO Dresses and Sportswear styles offer a confident contemporary look.",
    buttonUrl: "/brands/house-brands/nucast",
    buttonText: "VIEW BRAND "
  },
  3: {
    subheading: "INTRODUCING",
    heading: "XOXO",
    backgroundImageUrl: "./images/Paramount.jpg",
    backgroundImageUrlWebP: "./images/WebPFiles/Paramount.webp",
    description:
      "Straight off the runway featuring designs that are feminine, flirty, and affordable, XOXO Dresses and Sportswear styles offer a confident contemporary look.",
    buttonUrl: "/brands/house-brands/paramount",
    buttonText: "VIEW BRAND "
  },
  4: {
    subheading: "INTRODUCING",
    heading: "XOXO",
    backgroundImageUrl: "./images/FreedomTools.jpg",
    backgroundImageUrlWebP: "./images/WebPFiles/Paramount.webp",
    description:
      "Straight off the runway featuring designs that are feminine, flirty, and affordable, XOXO Dresses and Sportswear styles offer a confident contemporary look.",
    buttonUrl: "#",
    buttonText: "VIEW BRAND "
  }
};
export let responsiveData = {
  0: {
    items: 1
  },
  640: {
    items: 1
  },
  768: {
    items: 1
  },
  1024: {
    items: 1
  }
};

export default itemsData;
