import React from "react";
import { isUndefined } from "../../state/helper/functions";
import "./checkButton.scss";

const CheckButton = props => {
  let validator = props.validator;
  let validateFlag = props.validate;
  return (
    <label className={`check ${props.wrapperClass}`}>
      <input
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        onClick={props.onClick}
        defaultChecked={props.defaultChecked}
        disabled={props.disabled}
      />
      <span className={props.className}></span>
      <span className={`label`}>
        <span className="switch-button-text">{props.text}</span>
        <span className="text-hidden-filters">
          {props.textForHiddenFilters}
        </span>
        <span className="text-visible-filters">
          {props.textForVisibleFilters}
        </span>
      </span>
      {!isUndefined(validator) && (
        <div className={`validation-message validation-message-${props.name}`}>
          {validator.errorMessages[props.name] &&
            validateFlag &&
            validator.errorMessages[props.name]}
        </div>
      )}
    </label>
  );
};

export default CheckButton;
