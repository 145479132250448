import React from "react";
import "./removeAll.scss";
import Button from "../../../../../../utils/buttons";
import Modal from "./../../../../../../utils/modal";
import { Link } from "react-router-dom";

const RemoveAll = props => {
  let { showModal, closeModal, removeAllItemsFromList, project } = props;
  return (
    <div className={`remove-all`}>
      <Button
        className={"btn button btn-black remove-all-btn"}
        text={"Remove All Items"}
        onClick={e => showModal("remove", true)}
      />
      <Modal
        show={true}
        title={"DELETE ITEMS"}
        subHeading={"Are You Sure You Want To Remove All Items?"}
        modalId={"remove"}
        dismissable={false}
        stateIdentifier={"removeAllItems"}
        changeOnCustomerLogin={false}
        footer={
          <>
            <Link
              to={``}
              onClick={e => {
                e.preventDefault();
                closeModal("remove");
                removeAllItemsFromList(project);
              }}
            >
              Yes
            </Link>

            <Link
              to={``}
              onClick={e => {
                e.preventDefault();
                closeModal("remove");
              }}
            >
              No
            </Link>
          </>
        }
        footerCTA={<Link to={"#"} />}
      />
    </div>
  );
};

export default RemoveAll;
