import React from "react";
import "./maincontent.scss";
import BodyContent from "./bodyContent";

const MainContentList = props => {
  return (
    <div className={"main-export-wrapper col"}>
      <BodyContent {...props} />
    </div>
  );
};
export default MainContentList;
