import React from "react";
import { isUndefined } from "../../state/helper/functions";
import { Helmet } from "react-helmet";

const HelmetWrapper = props => {
  if (isUndefined(props.data)) return "";
  let { storeConfig } = props.data;

  if (isUndefined(storeConfig)) return "";
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{storeConfig.default_title}</title>
      <link rel="canonical" href={storeConfig.secure_base_link} />
      <meta name="description" content={storeConfig.default_description} />
    </Helmet>
  );
};

export default HelmetWrapper;
