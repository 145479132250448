import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Product from "./product";
import { isUndefined } from "../state/helper/functions";

const ProductReduxContainer = props => {
  // if (isUndefined(props.customer) || !props.customer.token)
  //   return <Redirect to={"/"} />;
  return <Product {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer
});

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductReduxContainer);
