import customerActions from "../actions";
import messagesActions from "../../messages/actions";
import commonActions from "../../common/actions";

export default async (store, action) => {
  try {
    store.dispatch(customerActions.getCustomerInformation(action.token));
    // history.push('/');
    store.dispatch(commonActions.unlock());
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
