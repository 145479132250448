import React from "react";
import GraphqlWrapper from "../utils/graphQlWrapper";
import queryLoader from "../state/graphql/queryLoader";
import Header from "./header-state-container";

const query = queryLoader("categoryTree");

const HeaderQueryContainer = props => {
  return (
    <GraphqlWrapper query={query} variables={{ id: 2 }} saveQuery={false}>
      <Header {...props} />
    </GraphqlWrapper>
  );
};

export default HeaderQueryContainer;
