import React, { useEffect, useState } from "react";
import ProductItem from "../../../../utils/products/listing/productItem";
import { isUndefined, isEmpty } from "../../../../state/helper/functions";
import ReactLoader from "../../../../utils/loader";
import Button from "../../../../utils/buttons";
import "./searchProducts.scss";

const SearchProducts = props => {
  const [program, setProgram] = useState("");
  let data = props.data.products;
  let saveSearchFn = props.saveSearch;
  let { loadMoreItems, loadMoreItemsRedux } = props;

  useEffect(() => {
    saveSearchFn(data);
    loadMoreItemsRedux(loadMoreItems);
  }, [data, saveSearchFn, loadMoreItemsRedux, loadMoreItems]);

  if (isUndefined(data)) {
    return <ReactLoader />;
  }

  let items = [];
  let _items = data;

  Object.keys(_items.items).map((elem, index) => {
    let product = _items.items[index];
    return items.push(
      <ProductItem
        key={`product-list-${product.id}`}
        onClick={e => {
          props.toggleSearch(false);
        }}
        product={product}
        program={program}
        setProgram={setProgram}
      />
    );
  });

  return (
    <div id="search-products">
      <div className="search-products-content">
        <p>Suggested</p>
        <div className="search-products-inner-content">
          {(!isEmpty(items) && items.slice(0, 4)) ||
            "No products found matching this criterium."}
        </div>

        {data.total_count > 0 && (
          <div className="search-results-button">
            <Button
              className={"button"}
              text={`Show ${data.total_count} results`}
              to={"/search-page"}
              onClick={e => {
                props.toggleSearch(false);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchProducts;
