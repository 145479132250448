import React from "react";
import { connect } from "react-redux";
import exportListActions from "../../../../state/redux/data/exportList/actions";
import ProductItem from "./productItem";
import ReactLoader from "../../../loader";
import { messagesActions } from "../../../../state/redux/data/messages";
import { isUndefined } from "../../../../state/helper/functions";

const ProductItemReduxContainer = props => {
  if (isUndefined(props.allCategories) || isUndefined(props.allCategories[2])) {
    return <ReactLoader />;
  }
  let allCategories = props.allCategories[2].data.category;
  return <ProductItem {...props} allCategories={allCategories} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer,
  exportList: state.exportList,
  allCategories: state.queries.data
});

const mapDispatchToProps = dispatch => {
  return {
    addSkuToList: (listId, sku) => {
      dispatch(exportListActions.addSkuToList(listId, sku));
    },
    removeSkuFromList: (listId, sku) => {
      dispatch(exportListActions.removeSkuFromList(listId, sku));
    },
    createExportList: name => {
      dispatch(exportListActions.createExportList(name));
    },
    addMessage: message => {
      dispatch(messagesActions.addMessage(message, "danger"));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductItemReduxContainer);
