import React from "react";
import CmsPage from "../cms";
import Button from "../../../utils/buttons";
import themeConfiguration from "../../../state/config/themeConfiguration";
import { Helmet } from "react-helmet";
import "./error.scss";

const ErrorPage = props => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 Not Found - {themeConfiguration.name}</title>
        <link
          rel="canonical"
          href={`${themeConfiguration.app_url}${props.variables &&
            props.variables.url &&
            props.variables.url}`}
        />
      </Helmet>
      <CmsPage dangHtml={true} id={1} />
      <div className="error-page-buttons">
        <Button className={"button btn-orange"} text={"Home"} to={"/home"} />
        <Button
          className={"button btn-orange"}
          text={"My account"}
          to={"/customer"}
        />
      </div>
    </>
  );
};

export default ErrorPage;
