// Middleware
import types from "./types";
import freightRequest from "./middleware/freightRequest";

const commonMiddleware = store => {
  return next => {
    return async action => {
      next(action);
      switch (action.type) {
        case types.FREIGHT_REQUEST:
          return freightRequest(store, action);

        default:
          return;
      }
    };
  };
};

export default commonMiddleware;
