import React from "react";
import { isUndefined, isFunction } from "../../state/helper/functions";
import { responsiveData } from "./carousel-data";
import "react-alice-carousel/lib/alice-carousel.css";
import "./carousel.scss";
import AliceCarousel from "react-alice-carousel";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Carousel = props => {
  let responsive;

  if (isUndefined(props.responsive)) {
    responsive = responsiveData;
  } else {
    responsive = props.responsive;
  }

  if (isUndefined(props.setCurrentIndex)) return "";

  return (
    <div id={`carousel-${props.carouselId}`} className={props.className}>
      <AliceCarousel
        {...props}
        mouseDragEnabled={props.mouseDragEnabled}
        onMouseDrag={e => {
          props.onMouseDrag();
        }}
        infinite={props.infinite}
        responsive={responsive}
        dotsDisabled={props.dotsDisabled}
        dotsEnabled={props.dotsEnabled}
        autoPlayInterval={props.autoPlayInterval}
        autoPlayDirection="ltr"
        autoPlay={props.autoPlay}
        fadeOutAnimation={true}
        playButtonEnabled={false}
        slideToIndex={props.currentIndex}
        onSlideChanged={e => {
          if (props.onSlideChangedOverwrite) return;
          props.setCurrentIndex(e.slide);
        }}
        buttonsDisabled={props.buttonsDisabled}
        stopAutoPlayOnHover={props.stopAutoPlayOnHover}
        duration={props.duration}
      >
        {props.children}
      </AliceCarousel>
      {!isUndefined(props.slidePrevSlide) &&
        isFunction(props.slidePrevSlide) &&
        !isUndefined(props.slideNextSlide) &&
        isFunction(props.slideNextSlide) &&
        props.itemsInSlide < props.children.length && (
          <div className={`carousel-actions`}>
            <button className={"btn-prev"} onClick={props.slidePrevSlide}>
              <FontAwesomeIcon icon="chevron-left" />
            </button>
            <button className={"btn-next"} onClick={props.slideNextSlide}>
              <FontAwesomeIcon icon="chevron-right" />
            </button>
          </div>
        )}
    </div>
  );
};

export default Carousel;
