import React, { useState } from "react";
import "./product-action-toolbar.scss";
import Button from "../../../../../../../utils/buttons";
import CheckButton from "../../../../../../../utils/checkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../../../../../utils/modal";
import { isUndefined } from "../../../../../../../state/helper/functions";

const ActionToolbar = props => {
  let { showModal, project, toggleDownload } = props;
  const [selectedProgram, setSelectedProgram] = useState(0);
  let modalId = "move-to-program-" + props.item.sku;

  let shouldShowMove = false;

  Object.keys(props.skuList).map((value, index) => {
    if (!shouldShowMove) {
      if (
        (!isUndefined(props.skuList[index]) &&
          props.skuList[index] !== null &&
          props.skuList[index].skuList !== null &&
          props.skuList[index].skuList.includes(props.item.sku) === false) ||
        props.skuList[index].skuList === null
      ) {
        shouldShowMove = true;
      }
      if (props.skuList[index] === null) {
        shouldShowMove = true;
      }
    }
  });

  return (
    <>
      <td className={"action-wrapper"} colSpan="100">
        {shouldShowMove && (
          <>
            <div className={"move-program-btn"}>
              <Button
                to={"#"}
                onClick={e => {
                  showModal(modalId, true);
                }}
              >
                Move to program
              </Button>
            </div>
            <Modal
              show={false}
              title={"MOVE PRODUCT"}
              subHeading={"Please choose a program for the selected product:"}
              modalId={modalId}
              dismissable={false}
              changeOnCustomerLogin={false}
              footer={<></>}
              footerCTA={
                <Button
                  to={"#"}
                  className={"btn button btn-orange"}
                  onClick={e => {
                    if (selectedProgram !== 0) {
                      props.removeSkuFromList(project, props.item.sku);
                      props.addSkuToList(selectedProgram, props.item.sku);
                      showModal(modalId, false);
                    }
                  }}
                >
                  Move product
                </Button>
              }
            >
              <div className="export-list-name">
                {Object.keys(props.skuList).map((value, index) => {
                  if (props.skuList[index].skuList === null) {
                    return (
                      <CheckButton
                        key={index}
                        name={"radio"}
                        type={"radio"}
                        value={props.skuList[index].listId}
                        onClick={e => {
                          setSelectedProgram(e.target.value);
                        }}
                        className={`radio ${selectedProgram}`}
                        wrapperClass={"radio-container"}
                        text={props.skuList[index].name}
                      />
                    );
                  } else if (
                    props.skuList[index].skuList.indexOf(props.item.sku) !== -1
                  ) {
                    return;
                  } else {
                    return (
                      <CheckButton
                        key={index}
                        name={"radio"}
                        type={"radio"}
                        value={props.skuList[index].listId}
                        onClick={e => {
                          setSelectedProgram(e.target.value);
                        }}
                        className={`radio ${selectedProgram}`}
                        wrapperClass={"radio-container"}
                        text={props.skuList[index].name}
                      />
                    );
                  }
                })}
              </div>
            </Modal>
          </>
        )}
        <div className={"actions-toolbar"}>
          <Button
            className={`remove-from-program`}
            to={"#"}
            onClick={e => {
              props.removeSkuFromList(project, props.item.sku);
              toggleDownload(false);
            }}
          >
            <FontAwesomeIcon icon="trash" />
          </Button>
        </div>
      </td>
    </>
  );
};
export default ActionToolbar;
