import React from "react";
import { Link } from "react-router-dom";
import { isUndefined } from "../../../../../state/helper/functions";

const CustomerDashboardAccountAddressesAddress = props => {
  let { address, type } = props;

  return (
    <>
      {(!isUndefined(address) && (
        <>
          <address>
            {address.firstname} {address.lastname} <br />
            {address.street}, {address.region.region} <br />
            {address.city}, {address.country_id} {address.postcode}
          </address>
          <Link to={"/customer/address/edit/" + address.id}>EDIT</Link>
        </>
      )) || (
        <>
          <p>No {type} address specified.</p>
          <p>To add an address contact customer support.</p>
        </>
      )}
    </>
  );
};

export default CustomerDashboardAccountAddressesAddress;
