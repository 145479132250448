import React, { useState } from "react";
import SidebarList from "./sidebar";

const SidebarListStateContainer = props => {
  const [showExportWithMarkup, setShowExportWithMarkup] = useState("no");
  const [calculateFreight, setCalculateFreight] = useState(true);
  const [msrp, setMsrp] = useState(false);
  const [exportDataFinal, setExportDataFinal] = useState([]);
  const [markup, setMarkup] = useState(0);

  return (
    <SidebarList
      {...props}
      setShowExportWithMarkup={setShowExportWithMarkup}
      showExportWithMarkup={showExportWithMarkup}
      markup={markup}
      setMarkup={setMarkup}
      exportDataFinal={exportDataFinal}
      setExportDataFinal={setExportDataFinal}
      calculateFreight={calculateFreight}
      setCalculateFreight={setCalculateFreight}
      msrp={msrp}
      setMsrp={setMsrp}
    />
  );
};

export default SidebarListStateContainer;
