export default (state, action) => {
  let { data } = action;
  let { customer } = state;

  customer.addresses.map((address, index) => {
    if (address.id === data.id) {
      return (customer.addresses[index] = data);
    }
  });

  return Object.assign({}, state, {
    customer: customer
  });
};
