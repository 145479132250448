// Action Creators
import types from "./types";

const clearFilters = () => {
  return {
    type: types.CLEAR_FILTERS
  };
};

const addFilter = filter => {
  return {
    type: types.ADD_FILTER,
    filter: filter
  };
};

const removeFilter = filter => {
  return {
    type: types.REMOVE_FILTER,
    filter: filter
  };
};

const addFilteredData = data => {
  return {
    type: types.ADD_FILTERED_DATA,
    data: data
  };
};

const addInitialCategoryData = data => {
  return {
    type: types.ADD_INITIAL_CATEGORY_DATA,
    data: data
  };
};

const changeSortFilter = sort => {
  return {
    type: types.CHANGE_SORT_FILTER,
    sort: sort
  };
};

export default {
  clearFilters,
  addFilter,
  removeFilter,
  addFilteredData,
  addInitialCategoryData,
  changeSortFilter
};
