import apolloClient from "../../../../graphql/apolloClient";
import commonActions from "../../common/actions";
import queryLoader from "../../../../graphql/queryLoader";
import exportListActions from "../../exportList/actions";
import messagesActions from "../../messages/actions";

export default async (store, action) => {
  try {
    if (action.name === "") {
      throw Error("Name is required");
    }

    const { data } = await apolloClient.mutate({
      mutation: queryLoader("addSkuToList"),
      variables: { listId: action.data.listId, sku: action.data.sku }
    });

    if (data) {
      await store.dispatch(exportListActions.saveListBtnSuccess(data));
      store.dispatch(
        messagesActions.addMessage(
          "Successfully added product to your main export list.",
          "success"
        )
      );
    }

    store.dispatch(commonActions.unlock());
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
