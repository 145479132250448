import React from "react";
import { connect } from "react-redux";
import customerActions from "../../../../../state/redux/data/customer/actions";
import Input from "../../../../../utils/input";
import Button from "../../../../../utils/buttons";
import Form from "../../../../../utils/forms";
import Select2 from "../../../../../utils/select2";
import CheckButton from "../../../../../utils/checkButton";
import { isUndefined, isEmpty } from "../../../../../state/helper/functions";
import { getRegionData } from "../../../../../state/config/data/dataMapping";
import "./edit.scss";

const CustomerAddressBookEdit = props => {
  let {
    address_id,
    firstname,
    setFirstname,
    lastname,
    setLastname,
    company,
    setCompany,
    website,
    setWebsite,
    mobile,
    setMobile,
    directOfficeNumber,
    setdirectOfficeNumber,
    address1,
    setAddress1,
    address2,
    setAddress2,
    country,
    setCountry,
    city,
    setCity,
    stateValue,
    setStateValue,
    zip,
    setZip,
    defaultBilling,
    setDefaultBilling,
    defaultShipping,
    setDefaultShipping,
    regionsData,
    setRegionsData,
    editCustomerAddress,
    countriesData,
    stateTextValue,
    setStateTextValue
  } = props;

  const setCountrySelectHandler = event => {
    let value = event;
    setCountry(value);
    updateRegion(value);
  };

  const updateRegion = value => {
    let data = getRegionData(countriesData, value);
    setRegionsData(data);
  };

  const submitHandle = e => {
    e.preventDefault();
    let regionObject = {};

    if (
      !isUndefined(stateValue) &&
      !isUndefined(regionsData[stateValue.value])
    ) {
      regionObject = {
        region_code: regionsData[stateValue.value].code,
        region: regionsData[stateValue.value].label,
        region_id: regionsData[stateValue.value].value
      };
    } else {
      regionObject = {
        region_code: "",
        region: stateTextValue,
        region_id: 0
      };
    }

    let request = {
      id: address_id,
      input: {
        firstname: firstname,
        lastname: lastname,
        company: company,
        mobile: mobile,
        website: website,
        directOfficeNumber: directOfficeNumber,
        street: [address1, address2],
        country_id: country.value,
        city: city,
        region: regionObject,
        postcode: zip,
        default_billing: defaultBilling,
        default_shipping: defaultShipping
      }
    };

    editCustomerAddress(request);
  };

  return (
    <div className="edit-address-container content">
      <h1>Edit Address</h1>
      <h4 className={"account-labels account-labels"}>Account Information</h4>
      <div className="formContainer">
        <Form>
          <div className="form-container">
            <div className="column-fields one">
              <h4 className="subtitle">CONTACT INFORMATION</h4>
              <br />
              <Input
                name={"firstname"}
                type="text"
                className={""}
                placeholder="First name"
                value={firstname}
                onChange={event => setFirstname(event.target.value)}
                required={true}
                icon="user"
              />
              <Input
                name={"lastname"}
                type={"text"}
                className={""}
                value={lastname}
                onChange={event => setLastname(event.target.value)}
                required={true}
                icon="user"
                placeholder="Last name"
              />
              <Input
                name={"directOfficeNumber"}
                type="text"
                className={""}
                placeholder="DIRECT OFFICE NUMBER"
                value={directOfficeNumber}
                onChange={event => setdirectOfficeNumber(event.target.value)}
                required={true}
                icon="phone"
              />
              <Input
                name={"mobile"}
                type="text"
                className={""}
                placeholder="MOBILE PHONE NUMBER"
                value={mobile}
                onChange={event => setMobile(event.target.value)}
                required={true}
                icon="cell"
              />
              <Input
                name={"company"}
                type="text"
                className={""}
                placeholder="COMPANY"
                value={company}
                onChange={event => setCompany(event.target.value)}
                required={true}
                icon="business"
              />
              <Input
                name={"website"}
                type="text"
                className={""}
                placeholder="WEBSITE"
                value={website}
                onChange={event => setWebsite(event.target.value)}
                required={true}
                icon="globe"
              />
            </div>
            <div className="column-fields two">
              <h4 className="subtitle">ADDRESS</h4>
              <br />
              <Input
                name={"address1"}
                type={"text"}
                wrapperClass={"partial"}
                value={address1}
                onChange={event => {
                  setAddress1(event.target.value);
                }}
                required={true}
                icon="address"
                placeholder="Shipping Address 1"
              />
              <Input
                name={"address2"}
                type={"text"}
                onChange={event => setAddress2(event.target.value)}
                value={address2}
                icon="address"
                placeholder="Shipping Address 2"
              />
              <Select2
                name={"country"}
                type={"text"}
                icon="address"
                value={country}
                onChange={event => setCountrySelectHandler(event)}
                required={true}
                wrapperClass="small"
                placeholder="Country"
                options={countriesData}
                useCustom={true}
                isSearchable={true}
              />
              {(!isEmpty(regionsData) && (
                <Select2
                  name={"state"}
                  type={"text"}
                  icon="address"
                  required={true}
                  value={stateValue}
                  onChange={event => {
                    setStateValue(event);
                  }}
                  wrapperClass="small"
                  placeholder="State"
                  options={regionsData}
                />
              )) || (
                <Input
                  name={"region_text"}
                  type={"text"}
                  wrapperClass={"small"}
                  required={true}
                  value={stateTextValue}
                  onChange={event => setStateTextValue(event.target.value)}
                  icon="address"
                  placeholder="State"
                />
              )}
              <Input
                name={"city"}
                type={"text"}
                wrapperClass={"small"}
                required={true}
                value={city}
                onChange={event => setCity(event.target.value)}
                icon="address"
                placeholder="City"
              />
              <Input
                name={"zip"}
                type={"text"}
                wrapperClass={"small"}
                required={true}
                value={zip}
                onChange={event => setZip(event.target.value)}
                icon="address"
                placeholder="ZIP Code"
              />
              <div className="edit-check-slider-wrapper">
                <div className="edit-check-slider">
                  <CheckButton
                    name={"check"}
                    type={"checkbox"}
                    value={defaultBilling}
                    className={"slider"}
                    defaultChecked={defaultBilling}
                    wrapperClass={"switch-container"}
                    text={"USE AS MY DEFAULT BILLING ADDRESS"}
                    onChange={e => {
                      let value = e.target.value;
                      if (value === false || value === "false") {
                        setDefaultBilling(true);
                      } else {
                        setDefaultBilling(false);
                      }
                    }}
                  />
                </div>
                <div className="edit-check-slider">
                  <CheckButton
                    name={"check"}
                    type={"checkbox"}
                    value={defaultShipping}
                    className={"slider"}
                    defaultChecked={defaultShipping}
                    wrapperClass={"switch-container"}
                    text={"USE AS MY DEFAULT SHIPPING ADDRESS"}
                    onChange={e => {
                      let value = e.target.value;
                      if (value === false || value === "false") {
                        setDefaultShipping(true);
                      } else {
                        setDefaultShipping(false);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="save-btn">
            <Button
              className="button btn-orange"
              onClick={e => submitHandle(e)}
              text="SAVE"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};
const mapState = state => {
  return state;
};
const actionCreators = {
  login: customerActions.login,
  logout: customerActions.logout
};

export default connect(mapState, actionCreators)(CustomerAddressBookEdit);
