import { getBrands, getCategoryFilter } from "./filter_values";
import { getMinMax } from "../../helper/category";
import {
  inventoryChangeHandler,
  priceChangeHandler
} from "./filter_change_handlers";
export const filterConfiguration = {
  category: {
    is_filter: true,
    attribute_code: "category",
    label: "Category",
    type: "category",
    values: getCategoryFilter
  },
  brand: {
    is_filter: true,
    attribute_code: "brand",
    label: "Brands",
    type: "select",
    values: getBrands
  },
  warehouse_qty: {
    is_filter: true,
    attribute_code: "warehouse_qty",
    label: "Inventory Range",
    type: "range",
    values: getMinMax,
    valueChangeHandler: inventoryChangeHandler
  },
  price: {
    is_filter: true,
    attribute_code: "price",
    label: "Price Range",
    type: "range",
    values: getMinMax,
    valueChangeHandler: priceChangeHandler
  }
};
