import React, { useState } from "react";
import CategoryQueryContainer from "./category";

const CategoryStateContainer = props => {
  const [categoryFilter, setCategoryFilter] = useState(props.variables.id);
  const [filters, setFilters] = useState(props.filters);

  if (props.variables.id !== categoryFilter) {
    setCategoryFilter(props.variables.id);
  }
  if (props.filters !== filters) {
    setFilters(props.filters);
  }

  return (
    <CategoryQueryContainer
      {...props}
      categoryFilter={categoryFilter}
      setCategoryFilter={setCategoryFilter}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

export default CategoryStateContainer;
