import React from "react";
import { connect } from "react-redux";
import CmsPageQueryContainer from "./cms-query-container";
import { default as queriesActions } from "../../../state/redux/data/queries/actions";
import { customerIsLoggedIn } from "../../../state/helper/customer";

const CmsPageReduxContainer = props => {
  let token = false;
  let data = false;
  let isCached = false;

  token = customerIsLoggedIn(props.customer);
  let loading = !(typeof props.data !== "undefined" && !props.data[props.id]);

  if (
    typeof props.data !== "undefined" &&
    typeof props.data[props.id] !== "undefined"
  )
    data = props.data[props.id].data;
  if (
    typeof props.isCached !== "undefined" &&
    typeof props.isCached.urlResolver !== "undefined" &&
    typeof props.isCached.urlResolver[props.url] !== "undefined" &&
    typeof props.isCached.urlResolver[props.url].data !== "undefined"
  )
    isCached = props.isCached.urlResolver[props.url];
  // if(typeof props.isCached !== "undefined" && typeof props.isCached.urlResolver !== 'undefined' && typeof props.isCached.urlResolver[props]) isCached = props.isCached.urlResolver[props.url];

  if (data || isCached) loading = false;
  if (isCached) data = isCached;

  return (
    <CmsPageQueryContainer
      {...props}
      token={token}
      loading={loading}
      data={data}
      isCached={isCached}
    />
  );
};

const mapStateToProps = state => ({
  thisState: state.customer,
  customer: state.customer.customer,
  modal: state.customer.modal,
  timestamp: state.common.timestamp,
  data: state.queries.data,
  isCached: state.queries
});

const mapDispatchToProps = dispatch => {
  return {
    addQueryData: (data, identifier, key) => {
      dispatch(queriesActions.addQueryData(data, identifier, key));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CmsPageReduxContainer);
