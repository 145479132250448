import React, { useState } from "react";
import CustomerAddressBookEdit from "./edit";
import {
  getCountries,
  getRegionData
} from "../../../../../state/config/data/dataMapping";
import {
  filterByCountryId,
  getSelectedRegionIfCountryHasRegions
} from "./functions";
import { isEmpty } from "../../../../../state/helper/functions";
import { default as countries_data } from "../../../../../state/config/data/countries";

const CustomerAddressBookEditState = props => {
  let { address } = props;

  const countries = countries_data.countries;
  let countryDataObject = getCountries(countries);
  let countriesData;
  countriesData = Object.keys(countryDataObject).map(key => {
    return countryDataObject[key];
  });

  let selectedCountry = filterByCountryId(address.country_id, countriesData);
  let regionsDataObject = getRegionData(countriesData, selectedCountry);
  let selectedRegion = "";

  if (!isEmpty(regionsDataObject)) {
    selectedRegion = getSelectedRegionIfCountryHasRegions(address);
  }

  const [firstname, setFirstname] = useState(address.firstname);
  const [addressId, setAddressId] = useState(address.id);
  const [lastname, setLastname] = useState(address.lastname);
  const [address1, setAddress1] = useState(address.street[0]);
  const [company, setCompany] = useState(address.company);
  const [directOfficeNumber, setdirectOfficeNumber] = useState(
    address.directnumber
  );
  const [website, setWebsite] = useState(address.website);
  const [mobile, setMobile] = useState(address.mobile);
  const [address2, setAddress2] = useState(address.street[1]);
  const [countrySelect, setCountrySelect] = useState(selectedCountry);
  const [city, setCity] = useState(address.city);
  const [stateValue, setStateValue] = useState(selectedRegion);
  const [stateTextValue, setStateTextValue] = useState(address.region.region);
  const [zip, setZip] = useState(address.postcode);
  const [defaultBilling, setDefaultBilling] = useState(address.default_billing);
  const [regionsData, setRegionsData] = useState(regionsDataObject);
  const [defaultShipping, setDefaultShipping] = useState(
    address.default_shipping
  );

  return (
    <CustomerAddressBookEdit
      {...props}
      firstname={firstname}
      setFirstname={setFirstname}
      lastname={lastname}
      setLastname={setLastname}
      address1={address1}
      setAddress1={setAddress1}
      company={company}
      setCompany={setCompany}
      address_id={addressId}
      setAddressId={setAddressId}
      directOfficeNumber={directOfficeNumber}
      setdirectOfficeNumber={setdirectOfficeNumber}
      website={website}
      setWebsite={setWebsite}
      mobile={mobile}
      setMobile={setMobile}
      address2={address2}
      setAddress2={setAddress2}
      country={countrySelect}
      setCountry={setCountrySelect}
      city={city}
      setCity={setCity}
      stateValue={stateValue}
      setStateValue={setStateValue}
      zip={zip}
      setZip={setZip}
      defaultBilling={defaultBilling}
      setDefaultBilling={setDefaultBilling}
      defaultShipping={defaultShipping}
      setDefaultShipping={setDefaultShipping}
      regionsData={regionsData}
      setRegionsData={setRegionsData}
      countries={countries}
      countriesData={countriesData}
      stateTextValue={stateTextValue}
      setStateTextValue={setStateTextValue}
    />
  );
};

export default CustomerAddressBookEditState;
