import React from "react";
import { connect } from "react-redux";
import commonActions from "../../../../state/redux/data/common/actions";
import ListingDataContainer from "./listing-data-container";

const ListingReduxContainer = props => {
  return <ListingDataContainer {...props} />;
};

const mapStateToProps = state => ({
  listId: state.listId,
  name: state.name,
  skuList: state.exportList.items
});

const mapDispatchToProps = dispatch => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingReduxContainer);
