const filter_type = { filter_type: "select" };

export const inventoryChangeHandler = (quantities, addFilter) => {
  return addFilter({
    warehouse_qty: {
      from: {
        filter_value: quantities.min,
        filter_label: "Inventory from",
        ...filter_type
      },
      to: {
        filter_value: quantities.max,
        filter_label: "Inventory to",
        ...filter_type
      }
    }
  });
};

export const priceChangeHandler = (prices, addFilter) => {
  return addFilter({
    price: {
      from: {
        filter_value: prices.min,
        filter_label: "Price from",
        ...filter_type
      },
      to: {
        filter_value: prices.max,
        filter_label: "Price to",
        ...filter_type
      }
    }
  });
};
