// Action types
const FREIGHT_REQUEST = "jola/freightQuote/FREIGHT_REQUEST";
const _REDUCE_FREIGHT_STATUS = "jola/freightQuote/_REDUCE_FREIGHT_STATUS";
const _REDUCE_FREIGHT_INFORMATION =
  "jola/freightQuote/_REDUCE_FREIGHT_INFORMATION";

export default {
  FREIGHT_REQUEST,
  _REDUCE_FREIGHT_STATUS,
  _REDUCE_FREIGHT_INFORMATION
};
