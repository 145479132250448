import apolloClient from "../../../../graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import queryLoader from "../../../../graphql/queryLoader";
import messagesActions from "../../messages/actions";
import queryActions from "../../queries/actions";
import { isEmpty } from "../../../../helper/functions";
import { exportListActions } from "../../exportList";

export default async (store, action) => {
  try {
    const { data } = await apolloClient.query({
      query: queryLoader("getCustomer"),
      variables: action
    });

    if (!data.customer || !data.customer.id) {
      console.log("GraphQL response", data);
      throw new Error("No customer information retrieved.");
    }

    if (
      data.customer &&
      !isEmpty(data.customer.addresses) &&
      action.isLoggedIn === false
    ) {
      store.dispatch(customerActions.setCustomerInformation(data.customer));
      store.dispatch(
        messagesActions.addMessage("You have logged in", "success")
      );
      // store.dispatch(queryActions.removeQuery());
    } else {
      if (action.email !== data.customer.email && action.email !== "") {
        data.customer.email = action.email;
      }
      store.dispatch(
        customerActions.setCustomerInformation(data.customer, action.isLoggedIn)
      );
    }
    store.dispatch(exportListActions.getCustomerExportList());
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
