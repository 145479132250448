import React, { useState, useEffect } from "react";
import Button from "../../../utils/buttons";
import SimpleReactValidator from "simple-react-validator";
import ValidationWrapper from "../../../utils/validationWrapper";
import HeroImage from "../../../utils/heroImage";
import Input from "../../../utils/input";
import themeConfiguration from "../../../state/config/themeConfiguration";
import { Helmet } from "react-helmet";
import "./forgotPassword.scss";

const ForgotPasswordPage = props => {
  let { locked, location, unlock } = props;
  // let location = props.location;
  let token =
    location.search !== "" && location.search.includes("token")
      ? location.search.split("=")[1]
      : "";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [validate, setValidate] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  const [inactiveButton, setInactiveButton] = useState(false);

  const [validator] = useState(
    new SimpleReactValidator({
      locale: "en",
      messages: {
        email: "Please enter a valid email address",
        confirmPassword: "Password & Confirm Password do not match."
      },
      validators: {
        match: {
          message: "Confirm password needs to match password.",
          rule: (val, params, validator) => params.includes(val),
          messageReplace: (message, params) =>
            message.replace(":values", validator.helpers.toSentence(params))
        }
      },
      autoForceUpdate: true
    })
  );
  const validateStep = event => {
    event.preventDefault();
    if (validator.allValid()) {
      props.onForgotPassword(email);
      setInactiveButton(true);
    } else {
      setValidate(true);
    }
  };

  const validatePasswordStep = event => {
    event.preventDefault();
    if (validator.allValid()) {
      props.resetPassword(token, password, confirmPassword);
    } else {
      setValidatePassword(true);
    }
  };

  useEffect(() => {
    unlock();
  }, [unlock]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Forgot Password - {themeConfiguration.name}</title>
        <link
          rel="canonical"
          href={`${themeConfiguration.app_url}/forgot-password}`}
        />
      </Helmet>
      <div className="main-banner">
        <HeroImage
          source={"FORGOT-PASSWORD.jpg"}
          alt={"Forgot password banner image"}
        />
      </div>
      <div id="forgotpasswordpage" className={"page-main-content"}>
        {(!token && (
          <>
            <h1>FORGOT PASSWORD</h1>
            <div className="content-with-bottom-border">
              <p>
                Please enter your email address below to receive a password
                reset link.
              </p>
              <ValidationWrapper validator={validator} validate={validate}>
                <Input
                  name={"email"}
                  type={"text"}
                  className={""}
                  value={email}
                  onChange={event => {
                    setEmail(event.target.value);
                    unlock();
                    setInactiveButton(false);
                  }}
                  required={true}
                  icon="email"
                  placeholder="Email"
                  validation={validator.message(
                    "email",
                    email,
                    "required|email"
                  )}
                />
              </ValidationWrapper>
            </div>
            <div className="actions">
              <Button
                to={"/"}
                className={`btn button ${locked ||
                  (inactiveButton && "btn-orange-disabled") ||
                  "btn-orange"}`}
                text={"Reset Password"}
                disabled={locked || inactiveButton}
                onClick={e => {
                  validateStep(e);
                }}
              />
            </div>
          </>
        )) || (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Reset Password - {themeConfiguration.name}</title>
              <link
                rel="canonical"
                href={`${themeConfiguration.app_url}/forgot-password}`}
              />
            </Helmet>
            <h1>Reset Password</h1>
            <div className="content-with-bottom-border">
              <p>Please enter your new password.</p>
              <ValidationWrapper
                validator={validator}
                validate={validatePassword}
              >
                <Input
                  name={"password"}
                  type={"password"}
                  className={""}
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  required={true}
                  icon="lock"
                  placeholder="Password"
                  validation={validator.message(
                    "password",
                    password,
                    "required|min:8,string"
                  )}
                  onBlur={e => {
                    validator.message(
                      "confirmpassword",
                      confirmPassword,
                      `required|min:8,string|match:${password}`
                    );
                  }}
                />
                <Input
                  name={"confirmPassword"}
                  type={"password"}
                  className={""}
                  value={confirmPassword}
                  onChange={event => setConfirmPassword(event.target.value)}
                  required={true}
                  icon="lock"
                  placeholder="Confirm Password"
                  validation={validator.message(
                    "confirmPassword",
                    confirmPassword,
                    `required|min:8,string|match:${password}`
                  )}
                />
              </ValidationWrapper>
            </div>
            <div className="actions">
              <Button
                to={"/"}
                className={`btn button ${(locked && "btn-orange-disabled") ||
                  "btn-orange"}`}
                text={"Reset Password"}
                disabled={locked}
                onClick={e => {
                  validatePasswordStep(e);
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ForgotPasswordPage;
