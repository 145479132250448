import React from "react";
import ProductItemContent from "./productItem";
import ActionToolbar from "./productActionToolbar";
import "./bodycontent.scss";
import { isEmpty } from "../../../../../../state/helper/functions";

const BodyContent = props => {
  if (isEmpty(props.items)) return "";
  return (
    <div className={"table-container"}>
      <table className={"table main-block-wrapper"}>
        <thead className={"main-block-heading"}>
          <tr>
            <th data-th={"Item"} scope="col" className={"left"} width="60%">
              Item
            </th>
            <th data-th={"Price"} scope="col" width="15%">
              Price
            </th>
            <th data-th={"Quantity"} scope="col" width="20%">
              Quantity
            </th>
            <th data-th={"Ship to Zip"} scope="col" width="15%">
              Ship to Zip
            </th>
          </tr>
        </thead>
        {Object.keys(props.items).map((element, index) => {
          let item = props.items[element];
          return (
            <tbody key={index}>
              <tr>
                <ProductItemContent {...props} item={item} />
              </tr>
              <tr>
                <ActionToolbar item={item} project={props.project} />
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
};

export default BodyContent;
