import apolloClient from "../../../../graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import queryActions from "../../queries/actions";
import queryLoader from "../../../../graphql/queryLoader";
import { exportListActions } from "../../exportList";
import { messagesActions } from "../../messages";

export default async (store, action) => {
  try {
    if (!action.email || !action.password) {
      store.dispatch(commonActions.unlock());
      throw new Error("Invalid email or password");
    }

    const { data } = await apolloClient.mutate({
      mutation: queryLoader("userLogin"),
      variables: { email: action.email, password: action.password }
    });

    if (!data.generateCustomerToken || !data.generateCustomerToken.token) {
      console.log("GraphQL response", data);
      throw new Error("No customer token received");
    }

    store.dispatch(
      customerActions.setCustomerToken(data.generateCustomerToken.token)
    );
    store.dispatch(exportListActions.getCustomerExportList());
  } catch (error) {
    store.dispatch(
      messagesActions.addMessage(error.toString(), "danger", "login")
    );
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    store.dispatch(customerActions._reduceCustomer());
    return;
  }
};
