import React from "react";
import Login from "../../forms/login";
import { Redirect } from "react-router-dom";
import "./login.scss";

const LoginPage = () => {
  if (window.screen.width > 768) return <Redirect to={"/"} />;
  return (
    <div className="login-page page-main-content">
      <section className="legend">
        <h1>Log In</h1>
        <h3>Welcome to the new Brandation site</h3>
      </section>
      <div className={"container"}>{<Login />}</div>
    </div>
  );
};
export default LoginPage;
