import React from "react";
import CheckButton from "../../checkButton";
import Button from "../../buttons";
import Icon from "../../../assets";
import "./toolbar.scss";
import { isUndefined, isObject } from "../../../state/helper/functions";
import Select2 from "../../../utils/select2";

const Toolbar = props => {
  let {
    data,
    category,
    showFilters,
    setShowFilters,
    sort,
    sortOrder,
    setSortOrder,
    changeSortFilterHandler,
    saveListBtn,
    exportLists
  } = props;
  if (isUndefined(data)) return "";
  let total_count = data.total_count;
  let sortByOptions = data.sort_fields.options;
  sortByOptions[0] = { label: "Newest", value: "created_at" };
  sortByOptions[1] = { label: "Brand", value: "name" };
  let exportListItems = "";
  data.items.map(item => {
    return (exportListItems = exportListItems + item.sku + ",");
  });
  exportListItems = exportListItems.slice(0, -1);
  const toggleYesNo = value => {
    return value === "false" || value === false ? true : false;
  };
  return (
    <div id="toolbar">
      <div className="filters-control">
        {!isUndefined(category) && category.products.total_count > 1 && (
          <>
            <div className={`show-hide-button show-${showFilters}`}>
              <CheckButton
                name={"check"}
                type={"checkbox"}
                value={showFilters}
                className={"slider"}
                wrapperClass={"switch-container"}
                text={"Filters"}
                textForHiddenFilters={"Hide Filters"}
                textForVisibleFilters={"Show Filters"}
                onClick={e => {
                  setShowFilters(toggleYesNo(e.target.value));
                }}
                // @TODO: Add OnChange
              />
            </div>
            {total_count !== 0 && (
              <p className="product-item-number">{total_count} Items</p>
            )}
          </>
        )}
      </div>
      {total_count !== 0 && (
        <div className="sort-control">
          <div className="sort-options toolbar-content">
            <div className="sort-options-content">
              {total_count > 1 && (
                <>
                  <span className="sorter-label">Sort By:</span>
                  <Select2
                    name={"sort"}
                    type={"text"}
                    onChange={event =>
                      changeSortFilterHandler(event, setSortOrder)
                    }
                    required={true}
                    wrapperClass="small"
                    placeholder={"SORT BY"}
                    options={sortByOptions}
                    useCustom={true}
                    // menuIsOpen={true}
                    isSearchable={true}
                    icon={props.icon}
                  />
                </>
              )}
            </div>
          </div>
          {total_count > 1 && (
            <div
              className={`arrow-down ${sortOrder === "DESC" && "arrow-up"}`}
              onClick={e => {
                let sortValue;
                setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
                if (isObject(sort)) {
                  sortValue = sort;
                } else {
                  sortValue = { value: sort };
                }
                changeSortFilterHandler(sortValue, sortOrder);
              }}
            >
              <Icon id={"rightArrow"} />
            </div>
          )}
          <div className="save-all toolbar-content">
            <Button
              className={
                "button btn-with-left-icon btn-white-orange-border btn-save"
              }
              text={"Save All"}
              icon={"excelOrange"}
              onClick={e => {
                e.preventDefault();
                saveListBtn({
                  listId: exportLists[0].listId,
                  sku: exportListItems
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Toolbar;
