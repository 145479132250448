import React from "react";
import { version } from "../../../../package.json";
global.appVersion = version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log("Clearing cache and hard reloading...");

        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          Promise.all(
            caches.keys().then(function(names) {
              for (let name of names) {
                // console.log(name);
                // caches.delete(name);
              }
            })
          ).then(window.location.reload(true));
        }

        // delete browser cache and hard reload

        //
      }
    };
  }

  dontCache() {
    this.setState({ loading: false, isLatestVersion: true });
  }

  componentDidMount() {
    fetch("/meta.json")
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        const shouldForceRefreshVersion =
          typeof this.props.appVersion !== "undefined"
            ? semverGreaterThan(currentVersion, this.props.appVersion)
            : false;

        if (shouldForceRefresh || shouldForceRefreshVersion) {
          if (
            typeof this.props.appVersion === "undefined" ||
            semverGreaterThan(currentVersion, this.props.appVersion)
          ) {
            console.log(
              `We have a new version - ${latestVersion}. Should force refresh`
            );
	    //console.log(currentVersion, this.props.appVersion,meta.version);
            this.props.setAppVersion(currentVersion);
            this.setState({ loading: false, isLatestVersion: false });
          } else {
            this.dontCache();
          }
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          this.props.setAppVersion(meta.version);
          this.dontCache();
        }
      });
  }
  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    const { children } = this.props;
    return children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}

export default CacheBuster;
