import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";

function transform(node, index) {
  if (node.type === "tag" && node.name === "a") {
    if (node.attribs.href.includes("http")) {
      return convertNodeToElement(node, index, transform);
    }
    let link = "";
    if (node.attribs.href) {
      link = node.attribs.href;
    }
    if (link.includes("mailto:") || link.includes("tel:"))
      return convertNodeToElement(node, index, transform);
    let linkComponent = (
      <Link
        to={`${node.attribs.href}`}
        {...node.attribs}
        key={node.attribs.href}
      >
        {node.children.map(item => {
          return convertNodeToElement(item, index, transform);
        })}
      </Link>
    );
    return linkComponent;
  }
  return convertNodeToElement(node, index, transform);
}

export const HtmlParser = props => {
  let { data, cssClass } = props;
  if (!data) {
    // console.log("Invalid data", props);
    return <></>;
  }

  const html = data;

  let newContent = html;
  return (
    <div className={`html-parsed ${cssClass && cssClass}`}>
      {ReactHtmlParser(newContent, {
        decodeEntities: true,
        transform: transform
      })}
    </div>
  );
};

export default HtmlParser;
