import React from "react";
import Item from "./item";

const Messages = props => {
  let { target } = props;
  if (!props.messages || !props.messages.length) {
    return <></>;
  }

  return (
    <>
      {props.messages.map((message, index) => {
        if (target === message.target) {
          if (message.text == "Error: GraphQL error: Please supply a customer token") {
            return "";
          }
          return (
            <Item
              key={index}
              text={message.text}
              type={message.type}
              onClose={() => props.onMessageClose(message.text)}
              closeTime={message.closeTime}
              autoClose
            />
          );
        }
        return "";
      })}
    </>
  );
};
export default Messages;
