import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ForgotPasswordPage from "./forgotPassword";
import { customerActions } from "../../../state/redux/data/customer";
import { isUndefined, isEmpty } from "../../../state/helper/functions";
import { commonActions } from "../../../state/redux/data/common";

const CustomerRegisterReduxContainer = props => {
  // if (props.status) props.onRegisterCallback(false);
  if (!isEmpty(props.customer)) {
    if (
      !isUndefined(props.customer) &&
      !isUndefined(props.customer.customer.resetPassword) &&
      props.customer.customer.resetPassword === true
    ) {
      props.resetPasswordSuccess(false);
      return <Redirect to={"/"} />;
    }
  }

  return <ForgotPasswordPage {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer,
  locked: state.common.locked
});

const mapDispatchToProps = dispatch => {
  return {
    onForgotPassword: email => {
      // alert("i am going to forget my password");
      dispatch(commonActions.lock());
      dispatch(customerActions.forgotCustomerPassword(email));
      // dispatch(customerActions.registerCustomerSuccess(status));
    },
    resetPassword: (token, password, confirmPassword) => {
      dispatch(commonActions.lock());
      dispatch(
        customerActions.resetCustomerPassword(token, password, confirmPassword)
      );
    },
    resetPasswordSuccess: value => {
      dispatch(customerActions.resetCustomerPasswordSuccess(value));
    },
    unlock: () => {
      dispatch(commonActions.unlock());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerRegisterReduxContainer);
