import React, { useState, useEffect } from "react";
import { itemsData, responsiveData } from "../home-data-container";
import { getSlidesFromItems } from "../home-functions";
import HomeCarousel from "./carousel";

const CarouselStateContainer = props => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    setSlides(getSlidesFromItems(items, currentIndex, setCurrentIndex));
    //eslint-disable-next-line
  }, []);
  let items = itemsData;

  return (
    <HomeCarousel
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      slides={slides}
      setSlides={setSlides}
      items={items}
      responsiveData={responsiveData}
    />
  );
};

export default CarouselStateContainer;
