import React, { useState } from "react";
import Input from "../../../../../utils/input";
import Button from "../../../../../utils/buttons";
import "./create.scss";
const Create = props => {
  const [createProjectName, setCreateProjectNames] = useState("");
  let exportListLimit = props.projectsList.data.length <= 5;

  return (
    <div className={"create-project"}>
      <Input
        name={"project-name"}
        type="text"
        className={""}
        placeholder="Enter New Program Name"
        value={createProjectName}
        onChange={event => setCreateProjectNames(event.target.value)}
        required={true}
      />
      <div className="save-btn">
        <Button
          className={`button btn-white-orange-border ${props.locked &&
            "btn-orange-disabled"}`}
          onClick={e => {
            if (exportListLimit) {
              props.lock();
              props.createExportList(createProjectName);
              setCreateProjectNames("");
            } else {
              props.addMessage(
                "You have reached the limit of available export list. (6)"
              );
            }
          }}
          disabled={props.locked}
          text={`${"Create"}`}
        />
      </div>
    </div>
  );
};

export default Create;
