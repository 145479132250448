export function filterProjectById(projectsList, projectId) {
  if (!projectsList && projectId) return [];

  let projects = projectsList;
  let filterResult = projects.filter(function(project) {
    // eslint-disable-next-line
    return project.id == projectId;
  });

  return filterResult[0];
}

export function changeProjectInformationFn(projectsList, project, name) {
  let projects = projectsList;
  let previousProject = filterProjectById(projectsList, project);

  for (var i in projects["data"]) {
    if (projects["data"][i].name === previousProject.name) {
      projects["data"][i].name = name;
    }
  }

  return { projects: projects, previousProject: previousProject };
}

export function deleteByIDFn(projectsList, project, id) {
  return;
  let previousProjectId = 0;
  let splice;
  let projects = projectsList;
  let previousProject = filterProjectById(projectsList, project);

  for (var i in projects["data"]) {
    // if the first item is List Export, then we just skip it
    // by design this should not be deletable
    // eslint-disable-next-line
    if (i == 0 && projects["data"][i].id === previousProject.id) {
      return {
        projectsList: projects,
        select: filterProjectById(projectsList, projects["data"][i].id).id,
        error: "You cannot delete the main List Export."
      };
    }
    if (projects["data"][i].id === previousProject.id) {
      splice = i;
    } else {
      previousProjectId = filterProjectById(
        projectsList,
        projects["data"][i].id
      ).id;
    }
  }
  projects["data"].splice(splice, 1);
  return { projectsList: projects, select: previousProjectId };
}

export function createProjectHandlerFn(projectsList, name) {
  if (Object.keys(projectsList["data"]).length === 6) return;
  let alteredProjectsList = projectsList;
  let newProjectId = Object.keys(alteredProjectsList["data"]).length + 1;

  alteredProjectsList["data"].push({
    id: newProjectId,
    name: name
  });

  return {
    projectsList: alteredProjectsList,
    id: newProjectId,
    name: projectsList["data"][newProjectId - 1].name
  };
}
