import React from "react";
import { Link } from "react-router-dom";
import "./conditionalWrapper.scss";

const ConditionalWrapper = props => {
  return (
    <>
      {(props.to && <Link to={props.to}>{props.children}</Link>) ||
        props.children}
    </>
  );
};

export default ConditionalWrapper;
