import apolloClient from "../../../../graphql/apolloClient";
import commonActions from "../../common/actions";
import queryActions from "../../queries/actions";
import queryLoader from "../../../../graphql/queryLoader";

export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("generateCache"),
      variables: { input: "fetch" }
    });

    if (!data.generateCache) {
      console.log("GraphQL response", data);
      throw new Error("No cache information received");
    }
    if (action.version !== data.generateCache && action.flag !== "wait") {
      store.dispatch(commonActions.setFlag("wait"));
      store.dispatch(queryActions.removeAllQueries());

      // figure out if we need to clear cacheStorage?
      // if (window.caches) {
      //   caches.keys().then(function(names) {
      //     for (let name of names) {
      //       console.log(name);
      //        caches.delete(name);
      //     }
      //   });
      //   console.log("Cleared service worker cache.");
      // }
      store.dispatch(commonActions.setCacheVersion(data.generateCache));
      store.dispatch(commonActions.setFlag("ready"));
    }
  } catch (error) {
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
