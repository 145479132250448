import React from "react";
import { connect } from "react-redux";
import { isUndefined } from "../../../../state/helper/functions";
import commonActions from "../../../../state/redux/data/common/actions";
import Slider from "./slider";

const SliderReduxContainer = props => {
  let { show } = props;
  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }
  if (isUndefined(show)) {
    show = false;
  }
  return <Slider {...props} loggedIn={loggedIn} />;
};

const mapStateToProps = state => ({ customer: state.customer.customer });

const mapDispatchToProps = dispatch => {
  return {
    showModal: (identifier, show) => {
      dispatch(commonActions.showModal(identifier, show));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SliderReduxContainer);
