import types from "./types";
import clearFilters from "./reducers/clearFilters";
import addFilter from "./reducers/addFilter";
import removeFilter from "./reducers/removeFilter";
import addFilteredData from "./reducers/addFilteredData";
import addInitialCategoryData from "./reducers/addInitialCategoryData";
import changeSortFilter from "./reducers/changeSortFilter";

const filterReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }

  if (action.type === types.CLEAR_FILTERS) {
    return clearFilters(state);
  }

  if (action.type === types.ADD_FILTER) {
    return addFilter(state, action);
  }

  if (action.type === types.REMOVE_FILTER) {
    return removeFilter(state, action);
  }

  if (action.type === types.ADD_FILTERED_DATA) {
    return addFilteredData(state, action);
  }

  if (action.type === types.ADD_INITIAL_CATEGORY_DATA) {
    return addInitialCategoryData(state, action);
  }

  if (action.type === types.CHANGE_SORT_FILTER) {
    return changeSortFilter(state, action);
  }

  return state;
};

export default filterReducer;
