import React from "react";
import CustomerSidebar from "./sidebar";
import CustomerPages from "./pages";
import "./customer.scss";

const Customer = props => {
  return (
    <div id={"customer"} className={"customer customer-container"}>
      <CustomerSidebar {...props} />
      <CustomerPages {...props} />
    </div>
  );
};

export default Customer;
