import React from "react";
import FooterLogo from "../header/bottom/logo";
import FooterBottom from "./bottom";
import FooterLinks from "./links";
import "./footer.scss";

const Footer = props => {
  return (
    <div id={"footer"}>
      <div className="footer-top-content">
        <FooterLogo />
        <FooterLinks {...props} className={"top-right"} id={"footer-links"} />
      </div>
      <div className="footer-bottom-content">
        <FooterBottom className={"bottom-full"} id={"footer-bottom"} />
      </div>
    </div>
  );
};

export default Footer;
