import React from "react";
import CloseButton from "../../../../closeButton";
import "./activeFilterItem.scss";

const ActiveFilterItem = props => {
  let filter = props.filter;
  let {
    filter_id,
    filter_value,
    filter_label,
    filter_attribute_label
  } = filter;

  return (
    (filter[Object.keys(filter)[0]].filter_type !== "select" && (
      <p>
        <CloseButton
          className={"close-btn"}
          onClick={e => {
            props.removeFilter(filter_id);
          }}
        />

        <span className={`filter-item filter-option-id-${filter_value}`}>
          <span className="category">{filter_attribute_label}:</span>

          <span className="category-name">{filter_label}</span>
        </span>
      </p>
    )) ||
    Object.keys(filter).map((value, index) => {
      let _filter = filter[value];
      return (
        <p key={`active-filter-${index}`}>
          <CloseButton
            className={"close-btn"}
            onClick={e => {
              props.removeFilter(_filter.filter_id);
            }}
          />
          <span className={`filter-item filter-option-id-${_filter.filter_id}`}>
            <span className="category">{_filter.filter_label}:</span>

            <span className="category-name">{_filter.filter_value}</span>
          </span>
        </p>
      );
    })
  );
};

export default ActiveFilterItem;
