import React from "react";
import { connect } from "react-redux";
import HeaderTop from "./top";
import customerActions from "../../state/redux/data/customer/actions";
import commonActions from "../../state/redux/data/common/actions";

const HeaderTopReduxContainer = props => {
  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }
  return <HeaderTop handleClick={handleClick} {...props} loggedIn={loggedIn} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer,
  locked: state.common.locked,
  skuList: state.exportList.items
});

const mapDispatchToProps = dispatch => {
  return {
    logout: callback => {
      dispatch(commonActions.lock());
      dispatch(customerActions.logoutCustomer());
    },
    showModal: (identifier, show) => {
      dispatch(commonActions.showModal(identifier, show));
    },
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    lock: () => {
      dispatch(customerActions.lock());
    }
  };
};

export function handleClick(event, props) {
  if (props.locked) return event.preventDefault();
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderTopReduxContainer);
