import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./search.scss";

const HeaderBottomSearch = props => {
  let show = props.show;
  let { loggedIn, showModal } = props;
  return (
    <div className={`search `}>
      <div
        onClick={e => {
          // if (!loggedIn) {
          //   e.preventDefault(show);
          //   showModal("loginModal", true);
          //   return;
          // }
          props.toggleSearch(!show);
        }}
        className={`search-bar ${show && "active"} `}
      >
        <div className="text">Search</div>
        <FontAwesomeIcon icon="search" />
      </div>
    </div>
  );
};

export default HeaderBottomSearch;
