import React, { useState } from "react";
import { isEmpty, isUndefined } from "../../../state/helper/functions";
import Input from "../../../utils/input";
import Button from "../../../utils/buttons";
import Icon from "../../../assets";
import Messages from "../../../utils/messages";
import "./productFreightInfo.scss";

const ProductFreightInfo = props => {
  let {
    freightRequest,
    data,
    freightInformation,
    freightStatus,
    removeFreightInformation,
    addMessage
  } = props;
  const product = data;
  const [quantity, setQuantity] = useState("");
  const [zip, setZip] = useState("");

  const calculateFreight = e => {
    if (!isEmpty(zip) && !isEmpty(quantity)) {
      if (zip.split("").length < 5 || zip.split("") > 5) {
        addMessage("Invalid US Zip code.", "danger", "pdpFreight");
      } else {
        freightRequest({
          class: 70,
          destination_postal_code: zip,
          origin_postal_code: "0",
          product_description: product.name,
          product_id: product.id,
          quantity: quantity
        });
      }
    } else {
      addMessage("Quantity and Zip are required!", "danger", "pdpFreight");
    }
  };

  const resetFreightCalculation = () => {
    setZip("");
    setQuantity("");
    removeFreightInformation();
  };

  const setZipEvent = event => {
    setZip(event.target.value);
  };

  const setQuantityEvent = event => {
    setQuantity(event.target.value);
  };

  return (
    <>
      <div id="productfreightinfo">
        <div className={"freight-wrapper"}>
          <h3>GET AN INSTANT</h3>
          <h1>FREIGHT QUOTE</h1>
          <div className={"freight-inner"}>
            <h4 className={"freight-info-label"}>FREIGHT INFO</h4>
            <div className={"freight-columns"}>
              <div className={"left-column columns"}>
                <p>
                  MASTER PACK QTY: <span>{product.master_pack_qty}</span>
                </p>
                <p>
                  MASTER PACK SIZE: <span>{product.master_pack_size}</span>
                </p>
              </div>
              <div className={"right-column columns"}>
                <p>
                  QTY PER PALLET: <span>{product.qty_per_pallet}</span>
                </p>
                <p>
                  MASTER PACK WEIGHT: <span>{product.master_pack_weight}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p className={"fill-field"}>
          Fill in the fields below to receive your freight estimate
        </p>

        <div className={"input-fields"}>
          <div className={"input-btn"}>
            <Input
              name={"quantity"}
              type={"number"}
              value={quantity}
              className={""}
              placeholder={"QTY"}
              onChange={event => setQuantityEvent(event)}
              onInput={e => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 5);
              }}
            />
            <Input
              name={"zip"}
              value={zip}
              type={"number"}
              placeholder={"ZIPCODE"}
              onChange={event => setZipEvent(event)}
              onInput={e => {
                e.target.value = e.target.value.slice(0, 5).toUpperCase();
              }}
            />
          </div>
          <Button
            className={`button btn-orange ${
              freightStatus ? "show-icon" : "remove-icon"
            }`}
            text={` ${(freightStatus && " ") ||
              (isUndefined(freightStatus) &&
                isUndefined(freightInformation) &&
                "Calculate") ||
              (!isUndefined(freightInformation) && "Reset") ||
              "Calculate"} `}
            onClick={e => {
              if (isUndefined(freightInformation)) {
                calculateFreight(e);
              }
              if (
                !isUndefined(freightInformation) &&
                freightInformation[0].error === null
              ) {
                resetFreightCalculation();
              }
            }}
            icon={`loading`}
          />
        </div>
      </div>
      <div className={`freightquote-session-messages`}>
        <Messages target={"pdpFreight"} />
      </div>
      {!isUndefined(freightInformation) && freightInformation[0].quote_id && (
        <div id="productfreightfinalcost" className={` ${"show-content"}`}>
          <h4 className={`animated pulse`}>LIVE QUOTE:</h4>
          <div className={"live column-one"}>
            <Icon id={"numberOfPallets"} />
            <p>
              NUMBER OF PALLETS:
              <br />
              <span>
                {(Math.ceil(quantity / product.qty_per_pallet) === 1 &&
                  `${Math.ceil(quantity / product.qty_per_pallet)} pallet`) ||
                  `${Math.ceil(quantity / product.qty_per_pallet)} pallets`}
              </span>
            </p>
          </div>
          <div className={"live column-two"}>
            <Icon id={"costEstimate"} />
            <p>
              COST ESTIMATE: <br />${freightInformation[0].quote_ammount}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductFreightInfo;
