import React from "react";
import { connect } from "react-redux";
import customerActions from "../state/redux/data/customer/actions";
import { commonActions } from "../state/redux/data/common";
import CategoryStateContainer from "./category-state-container";
import ReactLoader from "../utils/loader";
import { isUndefined } from "../state/helper/functions";

const CategoryReduxContainer = props => {
  if (isUndefined(props.allCategories) || isUndefined(props.allCategories[2])) {
    return <ReactLoader />;
  }
  let allCategories = props.allCategories[2].data.category;
  return (
    <CategoryStateContainer
      variables={props.variables}
      query={props.query}
      allCategories={allCategories}
      filters={props.filters}
      category={props.category}
    />
  );
};

const mapStateToProps = state => ({
  match: state.common.match,
  allCategories: state.queries.data,
  filters: state.filters.items,
  category: state.filters.category
});

const mapDispatchToProps = dispatch => {
  return {
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    match: history => {
      dispatch(commonActions.routeChange(history));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryReduxContainer);
