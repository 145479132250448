import React from "react";
import "./typography.scss";
import Typography from "../../../utils/typography";
import { product } from "./data";

const TypographyPage = props => {
  return (
    <div id="typographypage">
      <Typography product={product} />
    </div>
  );
};

export default TypographyPage;
