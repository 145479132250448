import { initialState } from "../initialState";

export default (state, action) => {
  return Object.assign({}, state, {
    customer: {
      email: initialState.email,
      token: initialState.token,
      id: initialState.id
    }
  });
};
