import React from "react";
import { useQuery } from "@apollo/react-hooks";
import queryLoader from "../../state/graphql/queryLoader";
import ProductRelated from "./productRelated";
import { isUndefined } from "../../state/helper/functions";

const query = queryLoader("products");

const ProductRelatedQueryContainer = props => {
  let brand_id = props.data.brand;
  let variables;
  variables = { filters: { brand: { eq: brand_id } } };

  const { loading, error, data, refetch } = useQuery(query, {
    variables: variables,
    skip: !variables
  });

  if (isUndefined(data) && !loading) {
    return (
      <ProductRelated {...props} refetch={refetch} data={props.category} />
    );
  }

  if (loading) {
    return (
      <ProductRelated
        {...props}
        showFilters={props.showFilters}
        data={undefined}
      />
    );
  }
  if (error) return `Error! ${error}`;

  return (
    <ProductRelated
      {...props}
      refetch={refetch}
      data={data}
      showFilters={props.showFilters}
    />
  );
};

export default ProductRelatedQueryContainer;
