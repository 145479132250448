import { loader } from "graphql.macro";
export default query => {
  /**
    const mapping = {
      category: loader("src/state/graphql/queries/category.graphql")
    } 
     */
  if (query === "category") {
    return loader("src/state/graphql/queries/category.graphql");
  }

  if (query === "categoryProducts") {
    return loader("src/state/graphql/queries/categoryProducts.graphql");
  }

  if (query === "products") {
    return loader("src/state/graphql/queries/products.graphql");
  }

  if (query === "product") {
    return loader("src/state/graphql/queries/product.graphql");
  }

  if (query === "categoryTree") {
    return loader("src/state/graphql/queries/categoryTree.graphql");
  }

  if (query === "cmsPage") {
    return loader("src/state/graphql/queries/cmsPage.graphql");
  }

  if (query === "cmsBlock") {
    return loader("src/state/graphql/queries/cmsBlock.graphql");
  }

  if (query === "urlResolver") {
    return loader("src/state/graphql/queries/urlResolver.graphql");
  }

  if (query === "userLogin") {
    return loader(
      "src/state/graphql/mutations/customer/session/loginMutation.graphql"
    );
  }

  if (query === "userLogout") {
    return loader(
      "src/state/graphql/mutations/customer/session/logoutMutation.graphql"
    );
  }

  if (query === "getCustomer") {
    return loader("src/state/graphql/queries/customer/customer.graphql");
  }

  if (query === "validateCustomerToken") {
    return loader(
      "src/state/graphql/queries/customer/validateCustomerToken.graphql"
    );
  }

  if (query === "checkCustomerAuthorization") {
    return loader("src/state/graphql/queries/customerCheckToken.graphql");
  }

  if (query === "getCountries") {
    return loader("src/state/graphql/queries/countries.graphql");
  }
  if (query === "searchProducts") {
    return loader("src/state/graphql/queries/searchProducts.graphql");
  }

  if (query === "registerCustomer") {
    return loader(
      "src/state/graphql/mutations/customer/createCustomerMutation.graphql"
    );
  }

  if (query === "createCustomerAddress") {
    return loader(
      "src/state/graphql/mutations/customer/address/createCustomerAddressMutation.graphql"
    );
  }

  if (query === "updateCustomerInformation") {
    return loader(
      "src/state/graphql/mutations/customer/information/updateCustomerInformation.graphql"
    );
  }
  if (query === "editCustomerAddress") {
    return loader(
      "src/state/graphql/mutations/customer/address/editCustomerAddress.graphql"
    );
  }

  if (query === "changeCustomerPassword") {
    return loader(
      "src/state/graphql/mutations/customer/information/changeCustomerPassword.graphql"
    );
  }

  if (query === "generateCache") {
    return loader("src/state/graphql/mutations/generateCache.graphql");
  }

  if (query === "customerExportList") {
    return loader("src/state/graphql/queries/customerExportList.graphql");
  }

  if (query === "createExportList") {
    return loader(
      "src/state/graphql/mutations/exportList/createExportList.graphql"
    );
  }

  if (query === "createFreightRequest") {
    return loader(
      "src/state/graphql/mutations/exportList/createQuoteRequest.graphql"
    );
  }

  if (query === "changeExportListName") {
    return loader(
      "src/state/graphql/mutations/exportList/information/changeExportListName.graphql"
    );
  }

  if (query === "deleteExportList") {
    return loader(
      "src/state/graphql/mutations/exportList/deleteExportList.graphql"
    );
  }

  if (query === "addSkuToList") {
    return loader(
      "src/state/graphql/mutations/exportList/items/addSkuToList.graphql"
    );
  }

  if (query === "removeSkuFromList") {
    return loader(
      "src/state/graphql/mutations/exportList/items/removeSkuFromList.graphql"
    );
  }

  if (query === "generateResetPasswordToken") {
    return loader(
      "src/state/graphql/mutations/customer/generateResetPasswordToken.graphql"
    );
  }

  if (query === "exportListQuery") {
    return loader("src/state/graphql/queries/exportListQuery.graphql");
  }

  if (query === "resetCustomerPassword") {
    return loader(
      "src/state/graphql/mutations/customer/information/resetPassword.graphql"
    );
  }

  if (query === "removeAllItemsFromList") {
    return loader(
      "src/state/graphql/mutations/exportList/removeAllItemsFromList.graphql"
    );
  }

  if (query === "sendEmail") {
    return loader("src/state/graphql/mutations/sendEmail.graphql");
  }

  if (query === "storeConfig") {
    return loader("src/state/graphql/queries/store/storeConfig.graphql");
  }

  if (query === "getDynamicContent") {
    return loader(
      "src/state/graphql/queries/content/getDynamicContentByContentType.graphql"
    );
  }

  if (query === "getFilters") {
    return loader("src/state/graphql/queries/getFilters.graphql");
  }

  throw new Error(
    "Unknown query, you probably forgot to define it in state/graphql/queryLoader.js."
  );
};
