import React from "react";
import { default as AccountInformation } from "./account-information";
import { default as AccountAddresses } from "./account-addresses";
import { pageTitle } from "../../../state/config/customerPages";

const CustomerDashboard = props => {
  let currentLocation = props.location.pathname;
  let thisPageTitle = pageTitle[currentLocation.substring(1)].title;
  return (
    <div id="dashboard">
      <h1>{thisPageTitle}</h1>
      <div className={"content"}>
        <div className="account-greeting">
          Hello, {props.customer.firstname} {props.customer.lastname}
        </div>
        <AccountInformation {...props} />
        <AccountAddresses {...props} />
      </div>
    </div>
  );
};

export default CustomerDashboard;
