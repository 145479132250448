import React, { useState } from "react";
import { Link } from "react-router-dom";
import { pageTitle } from "../../state/config/customerPages";
import { isUndefined } from "../../state/helper/functions";
import "./sidebar.scss";

const CustomerSidebar = props => {
  let currentLocation = props.location.pathname;
  let clientWidth = document.documentElement.getElementsByTagName("body")[0]
    .clientWidth;
  let currentUrl = currentLocation.substring(1);

  const [selectedLink, setSelectedLink] = useState(pageTitle[currentUrl]);
  const [toggle, setToggle] = useState(true);
  const toggleDropdown = () => {
    let currentToggle = toggle;
    setToggle(!currentToggle);
  };
  const selectLinkHandler = value => {
    setSelectedLink(value);
    if (clientWidth < 768) toggleDropdown();
  };

  window.addEventListener("resize", function(e) {
    if (clientWidth < 768) setToggle(false);
    if (clientWidth > 768) setToggle(true);
  });
  return (
    <div className={"sidebar sidebar-mobile-menu"}>
      <div
        className={`dropdown-heading ${!isUndefined(selectedLink) &&
          selectedLink.title.toLowerCase()} ${!isUndefined(selectedLink) &&
          selectedLink.title &&
          "active"}`}
        onClick={toggleDropdown}
      >
        {!isUndefined(selectedLink) && selectedLink.title}
      </div>
      <ul className={`dropdown ${!toggle && "hidden"}`}>
        {Object.keys(pageTitle).map(key => {
          let item = pageTitle[key];
          if (!item.hideFromSidebar) {
            return (
              <li
                key={`${key + 1}`}
                className={`${
                  !isUndefined(pageTitle[currentUrl]) &&
                  pageTitle[currentUrl].title === item.title
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  to={`/${key}`}
                  onClick={e => {
                    selectLinkHandler(item);
                  }}
                >
                  {item.title}
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default CustomerSidebar;
