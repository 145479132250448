import themeConfiguration from "../../state/config/themeConfiguration";
import { isUndefined } from "./functions";
export function findById(category_id, categoryTree) {
  if (categoryTree.id === themeConfiguration.root_category_id) {
    categoryTree = categoryTree.children;
  }
  function iter(a) {
    if (a.id === category_id) {
      result = a;
      return true;
    }

    return Array.isArray(a.children) && a.children.some(iter);
  }

  let result;
  categoryTree.some(iter);
  return result;
}

export function findByName(name, categoryTree) {
  if (categoryTree.name === themeConfiguration.name) {
    categoryTree = categoryTree.children;
  }
  function iter(a) {
    if (a.name === name) {
      result = a;
      return true;
    }

    return Array.isArray(a.children) && a.children.some(iter);
  }

  let result;
  categoryTree.some(iter);
  return result;
}

export function getMinMax(products, attribute) {
  let values = [];
  let min, max;
  Object.keys(products).map((index, value) => {
    if (attribute === "price")
      return (values[index] = products[index].price.regularPrice.amount.value);
    return (values[index] = products[index][attribute]);
  });
  min = getMin(values);
  max = getMax(values);
  return { min: min, max: max };
}

function getMin(values) {
  return values.reduce((min, value) => (value < min ? value : min), values[0]);
}

function getMax(values) {
  return values.reduce((max, value) => (value > max ? value : max), values[0]);
}

export function createCategoryImageUrl(imageUrl) {
  return (
    themeConfiguration.magento_url +
    themeConfiguration.media_url +
    themeConfiguration.category_url +
    "/" +
    imageUrl
  );
}

export function createProductImageUrl(imageUrl) {
  return (
    themeConfiguration.magento_url +
    themeConfiguration.media_url +
    themeConfiguration.catalog_url +
    "/" +
    imageUrl
  );
}

export const getBrandImageByCategoryId = (productCategories, allCategories) => {
  let brands = findByName("Brands", allCategories.children);
  //eslint-disable-next-line
  let foundBrand = productCategories.map(category => {
    let isBrand = findById(category.id, brands.children);
    if (!isUndefined(isBrand)) return isBrand;
  });
  return foundBrand.filter(item => {
    return !isUndefined(item);
  });
};
