import React from "react";
import { Route, Switch } from "react-router-dom";
import staticPages, { DefaultPage } from "../../state/config/customerPages";
import "./pages.scss";

const CustomerContent = props => {
  return (
    <div id="customer-pages">
      <Switch {...props}>
        <Route exact path="/customer" component={DefaultPage} />
        {Object.keys(staticPages).map(urlKey => {
          let CustomerPage = staticPages[urlKey].component;
          return (
            <Route
              key={urlKey}
              path={"/" + urlKey}
              render={() => <CustomerPage {...props} />}
            />
          );
        })}
      </Switch>
    </div>
  );
};

export default CustomerContent;
