import React from "react";
import { connect } from "react-redux";
import commonActions from "../../../../../state/redux/data/common/actions";
import exportListActions from "../../../../../state/redux/data/exportList/actions";
import { messagesActions } from "../../../../../state/redux/data/messages";
import Create from "./create";

const ToolbarCreateReduxContainer = props => {
  return <Create {...props} />;
};

const mapStateToProps = state => ({
  locked: state.common.locked,
  name: state.exportList.createName
});

const mapDispatchToProps = dispatch => {
  return {
    createExportList: name => {
      dispatch(exportListActions.createExportList(name));
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
    addMessage: (messageText, messageType = "danger") => {
      dispatch(messagesActions.addMessage(messageText, messageType));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToolbarCreateReduxContainer);
