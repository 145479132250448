import React from "react";
import CustomerDashboardAccountAddressesAddress from "./address-block";
import "./account-addresses.scss";

const CustomerDashboardAccountAddresses = props => {
  return (
    <div
      id="dashboard-account-information"
      className={"customer-account-information customer-account-section"}
    >
      <h4 className="account-labels">Default Addresses</h4>
      <div className="container account-addresses-container">
        <div className="account-inner">
          <h4 className="address-label">Default Billing Address</h4>
          <CustomerDashboardAccountAddressesAddress
            address={props.defaultBilling}
            type={"billing"}
          />
        </div>
        <div className="account-inner">
          <h4 className="address-label">Default shipping Address</h4>
          <CustomerDashboardAccountAddressesAddress
            address={props.defaultShipping}
            type={"shipping"}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboardAccountAddresses;
