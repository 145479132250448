import React, { useState } from "react";
import Modal from "./modal";
import { CSSTransition } from "react-transition-group";
import { filterIdentifier } from "./functions";

const ModalStateContainer = props => {
  const handleClose = identifier => {
    props.close(identifier);
  };

  const [visibility, setVisibility] = useState(
    props.identifiers[props.modalId]
  );

  let show = filterIdentifier(props);
  let identifier = props.modalId;

  let stopPropagation = e => {
    e.stopPropagation();
  };

  return (
    <CSSTransition
      key={`modal-${props.modalId}`}
      timeout={{
        appear: 500,
        enter: 500,
        exit: 500
      }}
      classNames={"fade"}
      appear={show}
      in={show}
    >
      <div
        id={`modal-${props.modalId}`}
        className={`modal`}
        onClick={e => handleClose(identifier)}
      >
        <div className="modal-container" onClick={stopPropagation}>
          {props.dismissable && (
            <button onClick={e => handleClose(identifier)}>Close</button>
          )}
          <Modal
            handleClose={stopPropagation}
            show={visibility}
            setVisibility={setVisibility}
            {...props}
          />
        </div>
      </div>
    </CSSTransition>
  );
};

export default ModalStateContainer;
