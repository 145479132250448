import React from "react";
import { connect } from "react-redux";
import commonActions from "../../state/redux/data/common/actions";
import ModalStateContainer from "./modal-state-container";
import { isUndefined } from "../../state/helper/functions";

const ModalReduxContainer = props => {
  if (isUndefined(props.identifiers)) return "";
  return (
    <ModalStateContainer
      {...props}
      finalChildren={props.children}
      customerComponent={props.customerRender}
    />
  );
};

const mapStateToProps = state => {
  return {
    identifiers: state.common.modal,
    customer: state.customer.customer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    unlock: () => {
      dispatch(commonActions.unlock());
    },
    close: identifier => {
      dispatch(commonActions.closeModal(identifier));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalReduxContainer);
