export default (state, action) => {
  return Object.assign({}, state, {
    customer: {
      ...action.customer,
      token: state.customer.token,
      email: action.customer.email
    },
    loading: false
  });
};
