import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SearchPage from "./search";
import { isUndefined } from "../../../state/helper/functions";

const SearchProductsReduxContainer = props => {
  if (isUndefined(props.data) || props.data.total_count === 0)
    return <Redirect to={"/"} />;
  return <SearchPage {...props} />;
};

const mapStateToProps = state => ({
  data: state.search.data,
  search: state.search.search,
  loadMoreItems: state.search.loadMoreItems
});

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchProductsReduxContainer);
