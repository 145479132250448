import React from "react";
import "./checkList.scss";
import CheckButton from "../../../../../../utils/checkButton";

const CheckList = props => {
  let {
    checkbox,
    setCheckbox,
    addSkuToList,
    removeSkuFromList,
    thisExportList,
    sku,
    changeValue
  } = props;

  return (
    <div id="checklist">
      <CheckButton
        name={`program-id-${thisExportList.listId}`}
        type={"checkbox"}
        value={checkbox}
        className={"checkmark one"}
        wrapperClass={"checkbox-container"}
        text={thisExportList.name}
        defaultChecked={checkbox}
        onChange={e => {
          changeValue(e.target.value);
          if (e.target.value === false || e.target.value === "false") {
            addSkuToList(thisExportList.listId, sku);
            setCheckbox(true);
          } else {
            removeSkuFromList(thisExportList.listId, sku);
            setCheckbox(false);
          }
        }}
      />
    </div>
  );
};

export default CheckList;
