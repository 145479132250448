import React from "react";
import { useQuery } from "@apollo/react-hooks";
import queryLoader from "../../../state/graphql/queryLoader";
import Listing from "./listing";
import { isEmpty, isUndefined } from "../../../state/helper/functions";
import { createRequestFromFilters, createSortVariable } from "./functions";

const query = queryLoader("products");

const ListingQueryContainer = props => {
  const {
    category_id,
    category,
    filters,
    showFilters,
    addFilteredData,
    customer,
    checkAuthorization
  } = props;
  let variables;
  let defaultPageSize = 20; // todo get default page size from magento*
  let pageSize = defaultPageSize;
  let request = createRequestFromFilters(filters.items, category_id, props);
  let sort = createSortVariable(filters.sort);

  const loadMoreItems = () => {
    if (!isUndefined(props.filters.data)) {
      variables["currentPage"] = props.filters.data.page_info.current_page + 1;
      fetchMore({
        variables: variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          let items = [
            ...prev.products.items,
            ...fetchMoreResult.products.items
          ];
          fetchMoreResult.products.items = items;
          return {
            ...fetchMoreResult
          };
        }
      });
    }
  };

  if (!isEmpty(request)) {
    if (isUndefined(request.filter.category_id)) {
      variables = { filters: request.filter };
    } else if (request.filter.category_id.eq === 0) {
      variables = { filters: request.filter };
      variables["filters"]["category_id"]["eq"] = category_id;
      variables["sort"] = sort;
      variables["pageSize"] = pageSize;
      variables["currentPage"] = 1;
    } else {
      variables = {
        filters: request.filter,
        sort: sort,
        pageSize: pageSize,
        currentPage: 1
      };
    }
  }

  const { loading, error, data, refetch, fetchMore } = useQuery(query, {
    variables: variables,
    skip: !variables
  });
  if (isUndefined(data) && !loading) {
    return (
      <Listing
        {...props}
        loadMoreItems={loadMoreItems}
        refetch={refetch}
        data={props.category}
        checkAuthorization={checkAuthorization}
      />
    );
  }

  if (loading) {
    return (
      <Listing
        {...props}
        showFilters={showFilters}
        loadMoreItems={loadMoreItems}
        checkAuthorization={checkAuthorization}
        data={{
          category: {
            id: 0,
            products: { total_count: 0, items: undefined },
            children: {}
          }
        }}
      />
    );
  }
  if (error) return `<div style={{padding: "20px"}}>It appears that you are offline. Please try again when you have access to the internet.</div>`;
  return (
    <Listing
      {...props}
      loadMoreItems={loadMoreItems}
      refetch={refetch}
      data={data}
      showFilters={showFilters}
      addFilteredData={addFilteredData}
      category={category}
      checkAuthorization={checkAuthorization}
    />
  );
};

export default ListingQueryContainer;
