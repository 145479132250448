import apolloClient from "../../../../graphql/apolloClient";
import { commonActions } from "../../common";
import { freightQuoteActions } from "../../freightQuote";
import queryLoader from "../../../../graphql/queryLoader";
import { isUndefined } from "../../../../helper/functions";
import { messagesActions } from "../../messages";

export default async (store, action) => {
  store.dispatch(freightQuoteActions._reduceFreightStatus(true));
  try {
    if (isUndefined(action.productInformation))
      throw new Error("Product not found.");

    let products = action.productInformation;
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("createFreightRequest"),
      variables: { input: { products } }
    });

    if (!data.createFreightQuote || !data.createFreightQuote.freightQuote) {
      console.log("GraphQL response", data);
      throw new Error("No freight information received");
    }

    if (data.createFreightQuote.freightQuote[0].error) {
      throw new Error(data.createFreightQuote.freightQuote[0].error);
    }

    store.dispatch(
      freightQuoteActions._reduceFreightInformation(
        data.createFreightQuote.freightQuote
      )
    );
  } catch (error) {
    store.dispatch(
      messagesActions.addMessage(
        "Unable to retrieve freight data, please try again later.",
        "danger",
        "pdpFreight"
      )
    );
    store.dispatch(commonActions.unlock());
  }
  store.dispatch(freightQuoteActions._reduceFreightStatus(false));
};
