export const product = {
  id: 17,
  brand: 15,
  name: "Nucast 3 Qt Cast Iron Sauce Pan ",
  sku: "NC1001",
  url_key: "nucast-3-qt-cast-iron-sauce-pan",
  price_1000: 9,
  price_2500: 8,
  price_5000: 7,
  media_gallery_entries: [],
  last_updated_date: "7/8/2019",
  moq: null,
  master_pack_qty: "4",
  master_pack_size: '17.9"W x11"H x 13.7"D',
  master_pack_weight: "36",
  warehouse_qty: "0",
  categories: [
    {
      id: 4,
      __typename: "CategoryTree"
    },
    {
      id: 13,
      __typename: "CategoryTree"
    }
  ],
  image: {
    label: "Main",
    url:
      "https://hglobalinc.joladev.com/pub/media/catalog/product/placeholder/default/product-image-placeholder.jpg",
    __typename: "ProductImage"
  },
  small_image: {
    label: "Main Small",
    url:
      "https://hglobalinc.joladev.com/pub/media/catalog/product/placeholder/default/4804_03_46.jpg",
    __typename: "ProductImage"
  },
  price: {
    regularPrice: {
      amount: {
        currency: "USD",
        value: 12,
        __typename: "Money"
      },
      adjustments: [],
      __typename: "Price"
    },
    __typename: "ProductPrices"
  },
  tier_prices: [
    {
      customer_group_id: "32000",
      percentage_value: null,
      qty: 1000,
      value: 12,
      website_id: null,
      __typename: "ProductTierPrices"
    },
    {
      customer_group_id: "32000",
      percentage_value: null,
      qty: 2500,
      value: 9.25,
      website_id: null,
      __typename: "ProductTierPrices"
    },
    {
      customer_group_id: "32000",
      percentage_value: null,
      qty: 5000,
      value: 8.7,
      website_id: null,
      __typename: "ProductTierPrices"
    }
  ],
  __typename: "SimpleProduct"
};
