import React, { useState } from "react";
import initialState from "./data";
import Header from "./header";
import { isUndefined } from "../state/helper/functions";
import { getAllCategories } from "./functions";

const HeaderStateContainer = props => {
  const [state, setState] = useState(initialState);
  const [mobileActive, setMobileActive] = useState(
    initialState.mobileMenuActive
  );
  const [activePanel, setActivePanel] = useState(0);

  let all = getAllCategories(props.data);

  const [panels, setPanels] = useState(all);

  const setActiveItem = id => {
    //Bug - undefined when mobile version on, after few clicks
    if (state.menuItems === undefined) {
    } else {
      const menuItems = state.menuItems.map(item => {
        if (item.id === id) {
          item.active = true;
        } else {
          item.active = false;
        }
        return item;
      });
      setState({ ...state, menuItems });
    }
  };

  const setActivePanelHandler = newPanel => {
    let newPanels = [];

    Object.keys(panels).map(element => {
      return (newPanels[element] = { id: element, status: false });
    });
    if (!isUndefined(newPanels[newPanel])) {
      newPanels[newPanel].status = true;
    }

    setActivePanel(newPanel);
    setPanels(newPanels);
  };
  const toggleMobileMenu = () => {
    const newValue = !mobileActive;
    setMobileActive(newValue);
  };

  const hideMenu = () => {
    if (!isUndefined(state.menuItems)) {
      const menuItems = state.menuItems.map(item => {
        item.active = false;
        return item;
      });
      let newState = state;
      newState.menuItems = menuItems;
      newState.mobileMenuActive = mobileActive;
      setState(newState);
    }
  };

  const updateActiveTab = id => {
    setState({ activeTab: id });
  };
  const [toggle, setToggle] = useState(false);
  const toggleDropdown = () => {
    let currentToggle = toggle;
    setToggle(!currentToggle);
  };
  const newProps = Object.assign({}, state, {
    updateActiveTab: updateActiveTab,
    toggleMobileMenu: toggleMobileMenu,
    activePanel: activePanel,
    setActivePanel: setActivePanelHandler,
    hideMenu: hideMenu,
    mobileActive: mobileActive,
    setActiveItem: setActiveItem,
    panels: panels,
    toggleDropdown: toggleDropdown
  });
  const finalProps = { ...newProps, ...props };
  return <Header {...finalProps} />;
};

export default HeaderStateContainer;
