import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import customerActions from "../../../../../state/redux/data/customer/actions";
import { filterAddressById } from "./functions";
import CustomerAddressBookEditState from "./edit-state-container";

const CustomerAddressBookEditReduxContainer = props => {
  if (!props.customer) return <Redirect to={"/"} />;

  let addressId = props.location.pathname.split(/[/]/).pop();
  let address = filterAddressById(props, addressId);

  return <CustomerAddressBookEditState {...props} address={address} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer
});

const mapDispatchToProps = dispatch => {
  return {
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    editCustomerAddress: request => {
      dispatch(customerActions.editCustomerAddress(request));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAddressBookEditReduxContainer);
