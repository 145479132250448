import React from "react";
import { connect } from "react-redux";
import commonActions from "../state/redux/data/common/actions";
import Footer from "./footer";

const FooterReduxContainer = props => {
  return <Footer {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer
});

const mapDispatchToProps = dispatch => {
  return {
    showModal: (identifier, show) => {
      dispatch(commonActions.showModal(identifier, show));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterReduxContainer);
