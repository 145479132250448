import apolloClient from "../../../../graphql/apolloClient";
import exportListActions from "../actions";
import commonActions from "../../common/actions";
import queryLoader from "../../../../graphql/queryLoader";
import messagesActions from "../../messages/actions";

export default async (store, action) => {
  try {
    if (action.listId === "") {
      throw Error("There is no list provided. Please select a list.");
    }
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("removeAllItemsFromList"),
      variables: { listId: action.listId }
    });

    if (data) {
      store.dispatch(exportListActions._reduceExportList(data));
      store.dispatch(commonActions.unlock());
      store.dispatch(
        messagesActions.addMessage(
          "Successfully removed all products from " +
            data.removeAllItemsFromList.name,
          "success"
        )
      );
    }
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
