import React from "react";
import CacheBuster from "./cacheBuster";
import { commonActions } from "../../redux/data/common";
import { connect } from "react-redux";

const SET_APP_VERSION = commonActions.setAppVersion;
const GraphQlReduxContainer = props => {
  return <CacheBuster {...props} />;
};

const mapStateToProps = state => ({
  appVersion: state.common.app_version
});

const mapDispatchToProps = dispatch => {
  return {
    setAppVersion: version => {
      dispatch(SET_APP_VERSION(version));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraphQlReduxContainer);
