import React, { useEffect } from "react";
import { isEmpty, isUndefined } from "../../../state/helper/functions";
import ExportListStateContainer from "./exportList-state";
import ReactLoader from "../../../utils/loader";

const ExportListDataContainer = props => {
  useEffect(() => {
    if (isEmpty(props.exportList)) {
      props.getCustomerExportList();
    }
    //eslint-disable-next-line
  }, []);

  let exportList = props.exportList;
  let customerProjects = [];

  if (!isEmpty(exportList)) {
    exportList.map(item => {
      if (item !== null) {
        if (!isUndefined(item)) {
          return customerProjects.push({
            id: item.listId,
            name: item.name,
            products: item.skuList
          });
        }
      }
      return;
    });
  }

  if (isEmpty(customerProjects)) {
    return <ReactLoader />;
  }

  return (
    <ExportListStateContainer
      projectsList={customerProjects}
      project={customerProjects[0].id}
      setCreateProjectName={props.setCreateProjectName}
    />
  );
};

export default ExportListDataContainer;
