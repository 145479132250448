import React from "react";
import { connect } from "react-redux";
import { customerActions } from "../../../state/redux/data/customer";
import commonActions from "../../../state/redux/data/common/actions";
import CustomerLoginForm from "./login";
import { isUndefined } from "../../../state/helper/functions";

const CustomerLoginFormReduxContainer = props => {
  if (!isUndefined(props.customer)) {
    if ("token" in props.customer) {
      if (props.customer.token) {
        return (
          <div className="logged-in-message">
            You have successfully logged in.
          </div>
        );
      }
    }
  }

  return <CustomerLoginForm {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer,
  loading: state.customer.loading
});

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (email, password, callback) => {
      dispatch(commonActions.lock(callback));
      dispatch(customerActions.loginCustomer(email, password));
    },
    onSubmitCallback: () => {},
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    showModal: (identifier, show) => {
      dispatch(commonActions.showModal(identifier, show));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerLoginFormReduxContainer);
