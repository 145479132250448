import React from "react";
import Alert from "../../alert";

const Item = props => {
  let closeTime = 4000;
  if (props.closeTime) closeTime = props.closeTime;

  if (props.autoClose) {
    setTimeout(() => {
      props.onClose();
    }, closeTime);
  }
  // function ScrollToTopOnMount() {
  //   useEffect(() => {
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //   }, []);

  //   return null;
  // }
  let message = props.text;
  message = message.replace("Error: GraphQL error: ", "");

  return (
    <>
      {/* <ScrollToTopOnMount /> */}
      <Alert
        variant={props.type}
        closeLabel="Remove message"
        dismissible
        onClose={props.onClose}
      >
        {message}
      </Alert>
    </>
  );
};

export default Item;
