import React from "react";
import { ReactComponent as Address } from "./address.svg";
import { ReactComponent as ArrowTop } from "./arrowTop.svg";
import { ReactComponent as LeftArrow } from "./leftArrow.svg";
import { ReactComponent as RightArrow } from "./rightArrow.svg";
import { ReactComponent as RightArrowOrange } from "./rightArrowOrange.svg";
import { ReactComponent as Boxes } from "./boxes.svg";
import { ReactComponent as Bulb } from "./bulb.svg";
import { ReactComponent as Business } from "./business.svg";
import { ReactComponent as Cc } from "./cc.svg";
import { ReactComponent as Cell } from "./cell.svg";
import { ReactComponent as CostEstimate } from "./costEstimate.svg";
import { ReactComponent as BrandationCup } from "./brandationCup.svg";
import { ReactComponent as CupBlack } from "./cupBlack.svg";
import { ReactComponent as Email } from "./email.svg";
import { ReactComponent as ExcelOrange } from "./excelOrange.svg";
import { ReactComponent as ExcelGray } from "./excelGray.svg";
import { ReactComponent as ExcelWhite } from "./excelWhite.svg";
import { ReactComponent as Expand } from "./expand.svg";
import { ReactComponent as Fax } from "./fax.svg";
import { ReactComponent as Gift } from "./gift.svg";
import { ReactComponent as GiftBlack } from "./giftBlack.svg";
import { ReactComponent as Globe } from "./globe.svg";
import { ReactComponent as Image } from "./image.svg";
import { ReactComponent as List } from "./list.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as Loading } from "./loading.svg";
import { ReactComponent as LoadingWhite } from "./loadingWhite.svg";
import { ReactComponent as Marker } from "./marker.svg";
import { ReactComponent as NumberOfPallets } from "./numberOfPallets.svg";
import { ReactComponent as Package } from "./package.svg";
import { ReactComponent as Pdf } from "./pdf.svg";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as Programs } from "./programs.svg";
import { ReactComponent as ProgramsGray } from "./programsGray.svg";
import { ReactComponent as ProgramsOrange } from "./programsOrange.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Shipping } from "./shipping.svg";
import { ReactComponent as ShippingBlack } from "./shippingBlack.svg";
import { ReactComponent as User } from "./user.svg";

const icons = {
  address: Address,
  arrowTop: ArrowTop,
  leftArrow: LeftArrow,
  rightArrow: RightArrow,
  rightArrowOrange: RightArrowOrange,
  boxes: Boxes,
  bulb: Bulb,
  business: Business,
  cc: Cc,
  cell: Cell,
  costEstimate: CostEstimate,
  cup: BrandationCup,
  cupBlack: CupBlack,
  email: Email,
  excelOrange: ExcelOrange,
  excelGray: ExcelGray,
  excelWhite: ExcelWhite,
  expand: Expand,
  fax: Fax,
  gift: Gift,
  giftBlack: GiftBlack,
  globe: Globe,
  image: Image,
  list: List,
  lock: Lock,
  logo: Logo,
  loading: Loading,
  loadingWhite: LoadingWhite,
  marker: Marker,
  numberOfPallets: NumberOfPallets,
  package: Package,
  pdf: Pdf,
  phone: Phone,
  programs: Programs,
  programsGray: ProgramsGray,
  programsOrange: ProgramsOrange,
  search: Search,
  shipping: Shipping,
  shippingBlack: ShippingBlack,
  user: User
};

const Icon = props => {
  const ReturnIcon = icons[props.id];

  return icons.hasOwnProperty(props.id) ? <ReturnIcon /> : "";
};

export default Icon;
