import React from "react";
import { connect } from "react-redux";
import commonActions from "./state/redux/data/common/actions";
import queriesActions from "./state/redux/data/queries/actions";
import Body from "./body";
import { ifHourPassed } from "./state/redux/data/common/functions";
import { customerIsLoggedIn } from "./state/helper/customer";

const BodyReduxContainer = props => {
  let cacheVersion = props.cache;
  // props.getCacheVersion(cacheVersion, props.flag);

  let token = false;
  let splash = false;
  token = customerIsLoggedIn(props.customer);
  if (typeof props.splash !== "undefined") {
    splash = props.splash;
    if (props.tasks === 1) {
      splash = false;
    }
  }
  return <Body {...props} token={token} splash={splash} />;
};

const mapStateToProps = state => ({
  thisState: state.customer,
  customer: state.customer.customer,
  modal: state.customer.modal,
  timestamp: state.common.timestamp,
  tasks: state.pendingTasks,
  splash: state.queries.splash,
  data: state.queries.data,
  cache: state.common.cache,
  flag: state.common.flag
});

const mapDispatchToProps = dispatch => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    checkCustomerAuthorization: (timestamp, oldtimestamp) => {
      if (ifHourPassed(oldtimestamp, timestamp)) {
        dispatch(commonActions.checkCustomerAuthorization(timestamp));
      }
    },
    getCacheVersion: (cache, flag) => {
      dispatch(commonActions.getCacheVersion(cache, flag));
    },
    clearAllCache: () => {
      dispatch(queriesActions.removeAllQueries());
    },
    closeAllModals: () => {
      dispatch(commonActions.closeAllModals());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BodyReduxContainer);
