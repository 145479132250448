import React from "react";
import { Link } from "react-router-dom";
import initialState from "./data";

const FooterLinks = props => {
  const links = initialState;
  return (
    <div id={props.id} className={props.className}>
      <div>
        <ul>
          {links.menuItems.map(item => (
            <li key={item.name}>
              {!item.link && <>{item.name}</>}
              {item.link && (
                <Link
                  to={item.link}
                // onClick={e => {
                //   if (item.link === "/products") {
                //     if (props.customer.token === false) {
                //       e.preventDefault();
                //       props.showModal("loginModal", true);
                //     }
                //   }
                // }}
                >
                  {item.name}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FooterLinks;
