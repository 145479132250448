import { determineCurrency } from "../../../state/config/currency";
import themeConfiguration from "../../../state/config/themeConfiguration";

export const getPrice = (
  price,
  currency = themeConfiguration.pricing.defaultCurrency
) => {
  return addCurrency(price, currency);
};

const addCurrency = (price, currency) => {
  return determineCurrency(currency) + price;
};
