import React from "react";
import { connect } from "react-redux";
import commonActions from "../../../../../state/redux/data/common/actions";
import exportListActions from "../../../../../state/redux/data/exportList/actions";
import Edit from "./edit";

const ToolbarEditReduxContainer = props => {
  return <Edit {...props} />;
};

const mapStateToProps = state => ({
  locked: state.common.locked,
  exportLists: state.exportList.items
});

const mapDispatchToProps = dispatch => {
  return {
    renameExportList: (listId, name) => {
      dispatch(exportListActions.renameExportList(listId, name));
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
    deleteExportList: listId => {
      dispatch(exportListActions.deleteExportList(listId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToolbarEditReduxContainer);
