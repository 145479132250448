export const tabsData = {
  0: {
    title: "CASINO PROMOTIONS",
    icon: "gift",
    block_content: "our_services_core_fifth"
  },
  1: {
    title: "PROMOTIONAL & EVENT MERCHANDISE",
    icon: "cup",
    block_content: "our_services_core_third"
  },
  2: {
    title: "MANUFACTURING & WHOLESALE",
    icon: "shipping",
    block_content: "our_services_core_first"
  },
  3: {
    title: "CREATIVE & DESIGN MARKETING",
    icon: "bulb",
    block_content: "our_services_core_fourth"
  },
  4: {
    title: "LOGISTICAL SERVICES",
    icon: "boxes",
    block_content: "our_services_core_second"
  }
};
