import React, { useState } from "react";
import Input from "../../../../../utils/input";
import CheckButton from "../../../../../utils/checkButton";
import Button from "../../../../../utils/buttons";
import "./middleblock.scss";

const MiddleBlock = props => {
  let { projectsList, project, toggleDownload } = props;
  let hasItems;

  let { updateAllQtyZip } = props;
  const [universalQty, setUniversalQty] = useState(1);
  const [universalQtyStatus, setUniversalQtyStatus] = useState("no");
  const [universalZip, setUniversalZip] = useState(10001);
  const [universalZipStatus, setUniversalZipStatus] = useState("no");
  const universalQtyHandler = value => {
    if (value === "no") {
      setUniversalQtyStatus("yes");
      document.getElementsByName("qty-input")[0].focus();
    } else {
      setUniversalQtyStatus("no");
    }
  };
  const focusOnElement = (name, action) => {
    if (name) document.getElementsByName(name)[0].focus();
  };

  const toggleYesNo = value => {
    return value === "no" ? "yes" : "no";
  };

  const filterToDigits = event => {
    return event.target.value === "" ? "" : event.target.value.match(/\d+/);
  };

  let activeList = projectsList.data.filter(list => list.id === project)[0];
  if (activeList !== undefined) {
    hasItems = activeList.products === ("" || null) ? 0 : 1;
  }
  const [applyClicked, setApplyClicked] = useState(true);
  const applyChanges = e => {
    e.preventDefault();
    if (applyClicked) {
      setApplyClicked(false);
    } else {
      setApplyClicked(true);
    }
    setTimeout(() => {
      setApplyClicked(true);
      updateAllQtyZip(universalQty, universalZip);
    }, 1000);
  };

  return (
    <>
      {hasItems > 0 && (
        <div>
          <div className="middle-block-wrapper-top">
            <div className="middle-text">
              <p>
                Using the switches to you can apply the same QTY or Zipcode to
                the list of products below.
              </p>
            </div>
            <div className="middle-btn">
              <div className="middle-one">
                <span style={{ marginRight: 10 }}>
                  <CheckButton
                    name={"check"}
                    type={"checkbox"}
                    value={universalQtyStatus}
                    className={"slider"}
                    text={"FILL IN SAME QTY"}
                    wrapperClass={"switch-container one"}
                    onClick={e => {
                      universalQtyHandler(e.target.value);
                    }}
                  />
                </span>
              </div>
              <div className="middle-two">
                <span style={{ marginRight: 10 }}>
                  <CheckButton
                    name={"check"}
                    type={"checkbox"}
                    value={universalZipStatus}
                    className={"slider"}
                    text={"FILL IN SAME ZIP"}
                    wrapperClass={"switch-container two"}
                    onClick={e => {
                      focusOnElement(
                        "zip-input",
                        setUniversalZipStatus(toggleYesNo(e.target.value))
                      );
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div
            // eslint-disable-next-line
            className={`middle-block-wrapper-bottom ${(universalQtyStatus ==
              "yes" &&
              "visible") ||
              // eslint-disable-next-line
              (universalZipStatus == "yes" && "visible") ||
              "hidden"}`}
          >
            <div
              className={`box-one ${(universalQtyStatus === "yes" &&
                "visible") ||
                "hidden"}`}
              style={{ display: "none" }}
            >
              <p>ENTER UNIVERSAL QTY:</p>
              <Input
                name={"qty-input"}
                type={"number"}
                wrapperClass={"range-field-wrapper"}
                className={"range-field"}
                inputClass={``}
                onInput={e => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
                value={universalQty}
                onChange={e => {
                  setUniversalQty(filterToDigits(e));
                }}
              />
            </div>
            <div
              className={`box-two ${(universalZipStatus === "yes" &&
                "visible") ||
                "hidden"}`}
              style={{ display: "none" }}
            >
              <p>ENTER UNIVERSAL ZIP CODE:</p>
              <Input
                name={"zip-input"}
                type={"number"}
                wrapperClass={"range-field-wrapper"}
                className={"range-field"}
                inputClass={``}
                value={universalZip}
                onChange={e => {
                  setUniversalZip(filterToDigits(e));
                }}
                onInput={e => {
                  e.target.value = e.target.value.slice(0, 5).toUpperCase();
                }}
              />
            </div>
            <div
              // eslint-disable-next-line
              className={`apply-btn ${(universalQtyStatus == "yes" &&
                "visible") ||
                // eslint-disable-next-line
                (universalZipStatus == "yes" && "visible") ||
                "hidden"}`}
              style={{ display: "none" }}
            >
              <Button
                className={`button btn-with-left-icon btn-orange ${
                  applyClicked ? "remove-icon" : "show-icon"
                }`}
                text={`${applyClicked ? "Apply" : ""}`}
                icon={`loadingWhite`}
                onClick={e => {
                  applyChanges(e);
                  toggleDownload(false);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MiddleBlock;
