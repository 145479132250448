import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderMenuLinks from "./links";
import Image from "../../utils/image";
import { default as themeConfiguration } from "../../state/config/themeConfiguration";
import { isUndefined } from "util";

function useComponentVisible(initialIsVisible, setProgram, program) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );

  const ref = useRef(null);

  return { ref, isComponentVisible, setIsComponentVisible };
}


const HeaderRegularMenu = props => {
  let { currentCategory, item, onMouseLeave, setActiveItem } = props;
 const [windowTop, setWindowTop] = useState(0);
  const { ref } = useComponentVisible(true, props.setProgram, props.program);
  let brand = item.brand ? true : false;
  const programScrollFn = e => {
    if (
      (window.scrollY > windowTop + 100 || window.scrollY < windowTop - 100)
    ) {
      setWindowTop(0);
      props.onMouseLeave();
      setActiveItem(0)
    }
  };
useEffect(() => {
    window.addEventListener("scroll", programScrollFn, true);

    return () => {
      window.removeEventListener("scroll", programScrollFn, true);
    };
  }, [programScrollFn]);
  

const initialCategoryImage = (
    <Image
      source={`${themeConfiguration.magento_url +
        themeConfiguration.media_url +
        themeConfiguration.category_url}/${currentCategory.thumbnail_image}`}
      image_alt={`${(currentCategory.description !== null &&
        currentCategory.description) ||
        currentCategory.name}`}
    />
  );

  const [currentImage, setCurrentImage] = useState(initialCategoryImage);
  const [activeTab, setActiveTab] = useState(12);

  const changeCategoryImage = newCategoryImage => {
    if (newCategoryImage === null) return;

    let newImage = `${themeConfiguration.magento_url +
      themeConfiguration.media_url +
      themeConfiguration.category_url}/${newCategoryImage}`;

    let imageComponent = <Image source={newImage} />;
    setCurrentImage(imageComponent);
  };

  const setActiveTabFn = brand => {
    if (brand.include_in_menu && currentCategory.name === "Brands") {
      setActiveTab(brand.id);
      changeCategoryImage(brand.thumbnail_image);
    }
  };

  const resetCategoryImage = () => {
    setCurrentImage(initialCategoryImage);
  };

  return (
    <>
      {item && (
        <div
          onMouseLeave={() => {
            onMouseLeave();
          }}
          className={`menu ${currentCategory.name} ${
            item.active ? "active " : ""
            }`}
	ref={ref}
        >
          <HeaderMenuLinks
            title={item.title}
            brand={brand}
            data={currentCategory}
            categoryId={item.category_id}
            changeCategoryImage={changeCategoryImage}
            resetCategoryImage={resetCategoryImage}
            activeTab={activeTab}
            setActiveTabFn={setActiveTabFn}
            closeMenu={onMouseLeave} 
	 />

          <div className="right">
            <div className="image">{currentImage}</div>
            <div className="additional">
              <Link
                style={{cursor: "pointer"}}
		to={`${item.all}`}
                className="link"
		onMouseEnter={e => {if(window.screen.width < 1024){props.hideMenu()}}}
		onClick={e => {onMouseLeave()}}
              // onClick={e => {
              //   if (isUndefined(props.customer) || !props.customer.token) {
              //     if (item.link !== "/brands") {
              //       e.preventDefault();
              //       props.showModal("loginModal", true);
              //     } else {
              //       props.showModal("loginModal", false);
              //     }
              //   } else {
              //     props.showModal("loginModal", false);
              //   }
              // }}
              >
                View all {item.name}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderRegularMenu;
