import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";

// redux store
import store from "../redux/store";

// fetches local storage state and theme configuration
import { loadState } from "../redux/localStorage";
import { default as themeConfiguration } from "../config/themeConfiguration";
import { customerActions } from "../redux/data/customer";

// react used for redirections

import { messagesActions } from "../redux/data/messages";
import { isUndefined } from "../helper/functions";

const cache = new InMemoryCache();
let token;

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  let reduxState = store.getState();
  let loadedState = loadState();

  token = loadedState ? loadedState.customer.customer.token : false;

  if (typeof reduxState.customer.customer === "object") {
    token =
      token === false || typeof token === "undefined"
        ? reduxState.customer.customer.token
        : token;
  } else {
    token = false;
  }

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ""
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const resetToken = onError(({ graphQLErrors, networkError }) => {
  if (networkError === "TypeError: Failed to fetch") {
    console.log("we should try offline mode");
  }
  if (!isUndefined(graphQLErrors)) {
    let error = graphQLErrors[0];
    if (
      (error.category === "graphql-authorization" &&
        error.message === "The current customer isn't authorized.") ||
      (error.category === "graphql-authorization" &&
        error.message ===
          "Customer is not authorized. Please login and try again.")
    ) {
      token = false;
      store.dispatch(customerActions._reduceCustomer({}));
      store.dispatch(
        messagesActions.addMessage(
          "Your session has expired. Please login to resume.",
          "danger"
        )
      );
    }
  }
});

// implement customerTokenValidation here, in case there is no graphql error
export const apolloClient = new ApolloClient({
  link: authLink.concat(
    ApolloLink.from([
      resetToken,
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${path}`
            )
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),

      createHttpLink({
        uri: themeConfiguration.magento_url + themeConfiguration.graphql_url,
        useGETForQueries: false
      })
    ])
  ),

  cache: cache
});

export default apolloClient;
