import React from "react";
import { connect } from "react-redux";
import { isUndefined } from "../../../../state/helper/functions";
import { commonActions } from "../../../../state/redux/data/common";

import OurProducts from "./ourProducts";
import ReactLoader from "../../../../utils/loader";

const OurProductsReduxContainer = props => {
  let { data, show } = props;
  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }
  if (isUndefined(show)) {
    show = false;
  }

  if (
    isUndefined(props.allCategories.data) ||
    isUndefined(props.allCategories.data[2])
  )
    return "";
  return (
    <OurProducts
      {...props}
      data={data}
      loggedIn={loggedIn}
      allCategories={props.allCategories.data[2]}
    />
  );
};

const mapStateToProps = state => ({
  customer: state.customer.customer,
  allCategories: state.queries
});

const mapDispatchToProps = dispatch => {
  return {
    showModal: (identifier, show) => {
      dispatch(commonActions.showModal(identifier, show));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OurProductsReduxContainer);
