import React, { useEffect, useState } from "react";
import ProductItem from "../../products/listing/productItem";
import ReactLoader from "../../loader";
import Button from "../../buttons";
import { isUndefined, isEmpty } from "../../../state/helper/functions";
import { findById } from "../../../state/helper/category";
import { filterConfiguration } from "../../../state/config/category/filters";
import themeConfiguration from "../../../state/config/themeConfiguration";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import "./listing.scss";

const Listing = props => {
  let {
    data,
    allCategories,
    category_id,
    addFilteredData,
    addInitialCategoryData,
    category
  } = props;

  let total_pages;
  let current_page;
  const [clicked, setClicked] = useState(true);
  const [program, setProgram] = useState("");

  if (data.products !== undefined) {
    current_page = data.products.page_info.current_page;
    total_pages = data.products.page_info.total_pages;
  }

  function handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom === docHeight) {
      if (current_page !== total_pages && total_pages !== 0) {
        if (clicked) {
          setClicked(false);
        } else {
          setClicked(true);
        }
        setTimeout(() => {
          setClicked(true);
        }, 2000);
        props.loadMoreItems();
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    if (!isUndefined(data) && !isUndefined(data.products)) {
      if (
        isUndefined(category) ||
        (!isUndefined(category) && category.id !== category_id)
      ) {
        let filters = {};
        Object.keys(filterConfiguration).map(key => {
          let item = filterConfiguration[key];
          if (item.type === "range") {
            let values = item.values(data.products.items, item.attribute_code);
            return (filters[item.attribute_code] = {
              initialValues: item.values(
                data.products.items,
                item.attribute_code
              ),
              ...item
            });
          }
          return (filters[item.attribute_code] = { ...item });
        });
        addInitialCategoryData({
          ...findById(category_id, allCategories),
          initialFilters: filters
        });
      }
      addFilteredData(data.products);
    }
  }, [data]);

  if (isUndefined(data)) return "";

  let items = [];
  let _items = {};

  if (!isUndefined(props.data)) {
    _items = props.data;
  }

  if (
    isUndefined(props.data) ||
    isUndefined(props.data) ||
    isUndefined(props.data.products)
  ) {
    items.push(<ReactLoader key={"loader-items"} />);
  } else {
    if (
      !isUndefined(_items) &&
      !isUndefined(_items.products) &&
      !isUndefined(_items.products.items)
    ) {
      // check to see if product items have 0 as price
      let ifAllAreZero = _items.products.items.filter(item => {
        return item.price.regularPrice.amount.value === 0;
      });

      if (
        ifAllAreZero.length === _items.products.items.length &&
        _items.products.total_count !== 0
      ) {
	if(typeof props.customer !== 'undefined'){
        props.checkAuthorization(props.customer.token);
      }}

      Object.keys(_items.products.items).map((elem, index) => {
        let product = _items.products.items[index];

        return items.push(
          <ProductItem
            key={`product-list-${product.id}`}
            productName={product.name}
            productNumber={product.sku}
            productItemStock={product.warehouse_qty}
            productArrivalQty={product.arrival_qty}
            productItemDate={product.last_updated_date}
            productLeadTime={product.lead_time}
            productMOQ={product.moq}
            productImage={product.image}
            productImageUrl={product.image.url}
            productUrl={product.url_key}
            tierPrices={product.tier_prices}
            productSmallImage={product.small_image}
            product={product}
            exportList={props.exportList}
            allCategories={allCategories}
            program={program}
            setProgram={setProgram}
          />
        );
      });
    } else {
      items.push(<ReactLoader key={"loader-items"} />);
    }
  }

  if (
    !isUndefined(_items.products) &&
    isEmpty(_items.products.items) &&
    !isUndefined(_items.products.items)
  ) {
    items.push(
      <p key={"no-products-key"} className={`listing-no-products`}>
        There are no available products with these filters.
      </p>
    );
  }

  function LoadMoreItems() {
    if (clicked) {
      setClicked(false);
    } else {
      setClicked(true);
    }
    setTimeout(() => {
      setClicked(true);
    }, 2000);
    props.loadMoreItems();
  }

  // if (!isUndefined(props.customer) && props.customer.token) {
  return (
    <>
      {!isUndefined(category) && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {category.name} - {themeConfiguration.name}
          </title>
          <link
            rel="canonical"
            href={`${themeConfiguration.app_url}${props.data.url_path}`}
          />
        </Helmet>
      )}
      <div id="listing" className={`listing`}>
        <div className="listing-content">{items}</div>
        {current_page !== total_pages && total_pages !== 0 && (
          <Button
            className={`button btn-with-left-icon list-more-products-btn btn-program ${
              clicked ? "remove-icon" : "show-icon"
              }`}
            text={"Load More Items"}
            icon={`loading`}
            onClick={LoadMoreItems}
          />
        )}
      </div>
    </>
  );
  // } else return <Redirect to={"/"} />;
};

export default Listing;
