import { combineReducers } from "redux";
import customerReducer from "./data/customer";
import commonReducer from "./data/common";
import messagesReducer from "./data/messages";
import queryReducer from "./data/queries";
import filtersReducer from "./data/filters";
import searchReducer from "./data/search";
import exportListReducer from "./data/exportList";
import freightQuoteReducer from "./data/freightQuote";
import { pendingTasksReducer } from "react-redux-spinner";

const globalReducers = combineReducers({
  customer: customerReducer,
  common: commonReducer,
  messages: messagesReducer,
  queries: queryReducer,
  filters: filtersReducer,
  search: searchReducer,
  exportList: exportListReducer,
  freightInformation: freightQuoteReducer,
  pendingTasks: pendingTasksReducer
});

export default globalReducers;
