import React from "react";
import { connect } from "react-redux";
import SearchProductsQueryContainer from "./searchProducts-query-container";
import searchActions from "../../../../state/redux/data/search/actions";
import { isEmpty, isUndefined } from "../../../../state/helper/functions";

const SearchProductsReduxContainer = props => {
  if (isEmpty(props.search) || isUndefined(props.search)) return "";
  return <SearchProductsQueryContainer {...props} />;
};

const mapStateToProps = state => ({
  search: state.search.search,
  sort: state.filters.sort
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    saveSearch: data => {
      dispatch(searchActions.saveSearch(data));
    },
    toggleSearch: isShown => {
      dispatch(searchActions.toggleSearch(isShown));
    },
    loadMoreItemsRedux: loadMoreItems => {
      dispatch(searchActions.loadMoreItems(loadMoreItems));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchProductsReduxContainer);
