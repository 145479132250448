import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import staticPages, { DefaultPage } from "../state/config/staticPages";
import guestPages from "../state/config/guestPages";
import DynamicGraphQlWrapperQueryContainer from "../utils/dynamicGraphQlWrapper";
import queryLoader from "../state/graphql/queryLoader";
import DynamicRouter from "./dynamic-content-router";
import { isUndefined } from "../state/helper/functions";

const Router = props => {
  let globalProps = props;

  // if (
  //   !isUndefined(props.customer) &&
  //   !isUndefined(props.customer.token) &&
  //   props.customer.token !== false
  // ) {
  return (
    <Switch>
      <Route key={"index"} path="/" exact component={DefaultPage} />
      {Object.keys(staticPages).map(key => {
        return (
          <Route
            key={key}
            path={"/" + key}
            component={staticPages[key].component}
            exact={staticPages[key].exact}
          />
        );
      })}
      <Route
        path="/:url"
        render={props => {
          return (
            <DynamicGraphQlWrapperQueryContainer
              query={queryLoader("urlResolver")}
              queryIdentifier={"urlResolver"}
              variables={{ url: props.location.pathname.substr(1) }}
              loader={false}
              data={globalProps.data}
            >
              <DynamicRouter
                {...globalProps}
                variables={{ url: props.location.pathname.substr(1) }}
              />
            </DynamicGraphQlWrapperQueryContainer>
          );
        }}
      />
    </Switch>
  );
  // } else {
  //   return (
  //     <Switch>
  //       <Route key={"index"} path="/" exact component={DefaultPage} />
  //       {Object.keys(guestPages).map(key => {
  //         return (
  //           <Route
  //             key={key}
  //             path={"/" + key}
  //             component={guestPages[key].component}
  //             exact={guestPages[key].exact}
  //           />
  //         );
  //       })}
  //       <Redirect key={"redirection"} to="/" component={DefaultPage} />
  //     </Switch>
  //   );
  // }
};

export default Router;
