import React from "react";
import ContentHeader from "../../../../utils/contentHeader";
import CategoryBlockWrapper from "./categoryBlock/categoryBlock-wrapper";
import Button from "../../../../utils/buttons";
import GraphqlWrapper from "../../../../utils/graphQlWrapper";
import queryLoader from "../../../../state/graphql/queryLoader";
import "./ourProducts.scss";

const query = queryLoader("getDynamicContent");

const OurProducts = props => {
  let { loggedIn, showModal } = props;
  return (
    <div className="home-our-products-block">
      <div className="home-our-products-block-inner">
        <div className="home-our-products-top">
          <ContentHeader heading={props.title} subHeading={props.subtitle} />
          <p>{props.description}</p>
        </div>
        <div className="home-category-block">
          <div className="home-category-top-row">
            <GraphqlWrapper
              query={query}
              variables={{ id: 1, identifier: "dynamic-content" }}
              saveQuery={true}
            >
              <CategoryBlockWrapper {...props} />
            </GraphqlWrapper>
          </div>
          <div className="home-category-bottom-row">
            <GraphqlWrapper
              query={query}
              variables={{ id: 2, identifier: "dynamic-content" }}
              saveQuery={true}
            >
              <CategoryBlockWrapper {...props} />
            </GraphqlWrapper>
          </div>
        </div>
        <Button
          className={"button btn-with-right-icon btn-orange right-arrow"}
          text={"View All "}
          icon={"rightArrow"}
          to={props.buttonUrl}
          onClick={e => {
            if (!loggedIn) {
              e.preventDefault();
              showModal("loginModal", true);
              return;
            }
          }}
        />
      </div>
    </div>
  );
};

export default OurProducts;
