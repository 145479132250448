export const initialState = {
  customer: {
    token: false,
    id: false,
    email: false,
    firstname: false,
    lastname: false,
    resetPassword: false
  },
  loading: false
};
