import React from "react";
import { connect } from "react-redux";
import { isUndefined } from "../../../../state/helper/functions";
import ReactLoader from "../../../../utils/loader";
import ProductInfoRightTop from "./productInfoRightTop";
import customerActions from "../../../../state/redux/data/customer/actions";

const ProductInfoRightTopReduxContainer = props => {
  if (isUndefined(props.allCategories) || isUndefined(props.allCategories[2])) {
    return <ReactLoader />;
  }
  let allCategories = props.allCategories[2].data.category;
  return <ProductInfoRightTop {...props} allCategories={allCategories} />;
};

const mapStateToProps = state => ({
  category_data: state.queries,
  customer: state.customer.customer,
  allCategories: state.queries.data
});

const mapDispatchToProps = dispatch => {
  return {
    lock: () => {
      dispatch(customerActions.lock());
    },
    unLock: () => {
      dispatch(customerActions.unlock());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductInfoRightTopReduxContainer);
