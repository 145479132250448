import React, { useState, useEffect } from "react";
import { NumberItems } from "./media-items";
import Carousel from "../../utils/carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import EventListener from "react-event-listener";
import { isUndefined } from "../../state/helper/functions";
import { mousedown, mouseup, mouseleave, mousemove } from "./functions";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getMediaGalleryEntries,
  // getMediaGalleryConfiguration,
  getPlaceholderImage,
  getThumbnailEntries
} from "./functions";
import { isEmpty } from "../../state/helper/functions";
import "./mediaGallery.scss";
import MediaGalleryFullscreen from "./fullscreen";

const MediaGallery = props => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [itemsInSlide] = useState(1);
  const [thumbnailsState, setThumbnailsState] = useState(undefined);

  // useEffect for determining scroll position for thumbnail elements
  useEffect(() => {
    let carousels = document.getElementsByClassName("carousel-thumbnails");
    setThumbnailsState(carousels.item(0));
  }, []);
  const images = props.images;
  // const mediaGalleryConfiguration = getMediaGalleryConfiguration();
  const placeholderImage = getPlaceholderImage(props.placeholderImage);

  let media_gallery = getMediaGalleryEntries(
    images,
    currentIndex,
    setCurrentIndex
  );

  let media_gallery_length = media_gallery.length;

  let thumbnails = getThumbnailEntries(images, currentIndex, setCurrentIndex);

  const slidePrevSlide = () => {
    let newIndex = currentIndex - itemsInSlide;
    if (newIndex < 0) newIndex = 0;
    setCurrentIndex(newIndex);
  };

  const slideNextSlide = () => {
    let newIndex = currentIndex + itemsInSlide;
    if (newIndex === media_gallery_length) newIndex = media_gallery_length - 1;
    setCurrentIndex(newIndex);
  };
  return (
    <div className={"mediaGallery"}>
      {(!isEmpty(media_gallery) && (
        <>
          <div className={`carousel-relative`}>
            <Carousel
              setCurrentIndex={setCurrentIndex}
              responsive={NumberItems}
              carouselId={"media-gallery"}
              autoPlay={false}
              slideToIndex={currentIndex}
              dotsDisabled={true}
              buttonsDisabled={true}
              currentIndex={currentIndex}
              infinite={false}
              mouseDragEnabled={false}
              slidePrevSlide={slidePrevSlide}
              slideNextSlide={slideNextSlide}
              itemsInSlide={itemsInSlide}
            >
              {media_gallery}
            </Carousel>
            <FontAwesomeIcon
              icon="search"
              className={`icon-fullscreen`}
              onClick={e => {
                e.preventDefault();
                setFullScreen(true);
              }}
            />
          </div>

          <CSSTransition
            key={"fullscreen-container"}
            classNames={"slide"}
            appear={fullScreen}
            in={fullScreen}
            timeout={1000}
          >
            <MediaGalleryFullscreen
              media_gallery={media_gallery}
              setCurrentIndex={setCurrentIndex}
              responsive={NumberItems}
              autoPlay={false}
              dotsDisabled={true}
              buttonsDisabled={true}
              currentIndex={currentIndex}
              infinite={false}
              mouseDragEnabled={false}
              thumbnails={thumbnails}
              setFullScreen={setFullScreen}
            />
          </CSSTransition>
        </>
      )) || (
        <>
          <div>
            <Carousel
              setCurrentIndex={setCurrentIndex}
              responsive={NumberItems}
              autoPlay={false}
              slideToIndex={currentIndex}
              dotsDisabled={true}
              buttonsDisabled={true}
              currentIndex={currentIndex}
              infinite={false}
              mouseDragEnabled={false}
            >
              {[placeholderImage]}
            </Carousel>
          </div>
        </>
      )}

      {!isEmpty(thumbnails) && (
        <div className="carousel-thumbnails">
          {Object.keys(media_gallery).map((value, index) => {
            return thumbnails[index];
          })}
        </div>
      )}

      {!isUndefined(thumbnailsState) && (
        <EventListener
          target={thumbnailsState}
          onMouseDown={e => mousedown(e, setThumbnailsState, thumbnailsState)}
          onMouseUp={e => mouseup(e, setThumbnailsState, thumbnailsState)}
          onMouseLeave={e => mouseleave(e, setThumbnailsState, thumbnailsState)}
          onMouseMove={e => mousemove(e, setThumbnailsState, thumbnailsState)}
        />
      )}
    </div>
  );
};

export default MediaGallery;
