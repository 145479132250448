import React, { useState } from "react";
import FilterItem from "./filterItem";
import { calculateProductsByAttribute } from "./functions";
import FilterWrapper from "./filterItem/filterWrapper";
import "./filter.scss";
import { isUndefined } from "../../../../state/helper/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findByName, findById } from "../../../../state/helper/category";

const Filter = props => {
  let {
    filter,
    listWithCloseButton,
    filters,
    products,
    addFilter,
    selected_category,
    useOldFilters,
    allCategories,
    removeFilter
  } = props;
  const items = [];
  const [toggle, setToggle] = useState(true);
  if (!isUndefined(filters) && useOldFilters) {
    Object.keys(filters).map(function(index, value) {
      if (typeof filters[index] === "object") {
        Object.keys(filters[index].children).length > 0 &&
          items.push(
            <FilterItem
              key={`filter-item-${filters[index].name}-${value}`}
              categoryName={filters[index].name}
              listWithCloseButton={listWithCloseButton}
              selectedCategory={true}
              openDropdown={true}
              wrapper={true}
              icon={""}
            >
              {Object.keys(filters[index].children).map(function(
                cindex,
                cvalue
              ) {
                let element = filters[index].children[cindex];

                if (isUndefined(products)) return "";
                let number_of_products = calculateProductsByAttribute(
                  products,
                  filter.attribute_code,
                  element.id
                );
                if (number_of_products > 0) {
                  return (
                    <FilterItem
                      key={`filter-item-${filters[index].name}-${value}-${element.name}-${cvalue}`}
                      categoryName={element.name}
                      selectedCategory={true}
                      openDropdown={true}
                      icon={number_of_products}
                      option_id={index}
                      onClick={e => {
                        addFilter({
                          [filter.attribute_code]: {
                            filter_value: element.id,
                            filter_label: element.name,
                            filter_type: filter.type
                          }
                        });
                      }}
                    />
                  );
                } else {
                  return (
                    // <p
                    //   key={`filter-item-${filters[index].name}-${value}-${element.name}-${cvalue}`}
                    // >
                    //   No products found belonging to this brand.
                    // </p>
                    <> </>
                  );
                }
              })}
            </FilterItem>
          );
      } else {
        if (isUndefined(products)) return "";
        let number_of_products = calculateProductsByAttribute(
          products,
          filter.attribute_code,
          index
        );
        if (number_of_products > 0) {
          items.push(
            <FilterItem
              key={`filter-item-no-object-${filters[index]}-${value}`}
              option_id={index}
              categoryName={filters[index]}
              listWithCloseButton={listWithCloseButton}
              selectedCategory={true}
              selected_category={selected_category}
              openDropdown={true}
              wrapper={false}
              icon={number_of_products}
              onClick={e => {
                addFilter({
                  [filter.attribute_code]: {
                    filter_value: index,
                    filter_label: filters[index],
                    filter_type: filter.type
                  }
                });
              }}
            />
          );
        }
      }
      return true;
    });
  } else {
    if (isUndefined(allCategories)) return "";
    let brands = findByName("Brands", allCategories.children);

    if (!isUndefined(filters) && filter.attribute === "category_id") {
      let type = "category";
      Object.keys(filters).map(index => {
        if (filters[index].value === "2") return "";
        let attribute =
          filter.attribute === "brand" ? "category_id" : filter.attribute;
        let label = filters[index].label;
        let value = parseInt(filters[index].value);
        let brand = findById(value, brands.children);
        let activeFilter = false;
        if (!isUndefined(props.activeFilters)) {
          activeFilter = !isUndefined(props.activeFilters[attribute]);
          if (!isUndefined(props.activeFilters[attribute])) {
            activeFilter =
              props.activeFilters[attribute].filter_value === value;
          }
        }

        if (
          value === 2 ||
          (!isUndefined(brand) && brand.id === value) ||
          label === "Products"
        )
          return "";

        return items.push(
          <FilterItem
            key={`filter-item-no-object-${attribute}-${index}`}
            option_id={index}
            categoryName={label}
            listWithCloseButton={listWithCloseButton}
            selectedCategory={true}
            selected_category={selected_category}
            openDropdown={true}
            wrapper={false}
            icon={false}
            activeFilter={activeFilter}
            removeFilter={e => {
              removeFilter(attribute);
            }}
            onClick={e => {
              addFilter({
                [attribute]: {
                  filter_value: value,
                  filter_label: label,
                  filter_type: type,
                  filter_attribute_label: filter.label
                }
              });
            }}
          />
        );
      });
    } else if (filter.attribute === "brand") {
      let brandIds = [];
      let brandTypeRelation = [];

      Object.keys(filters).map((index, value) => {
        return brandIds.push(filters[index].value);
      });

      Object.keys(brandIds).map((index, value) => {
        let id = parseInt(brandIds[index]);
        let brand = findById(id, brands.children);

        if (!isUndefined(brand)) {
          let path = brand.path.split("/" + id)[0];
          let parent = path.split("/");

          let parentData = findById(
            parseInt(parent[parent.length - 1]),
            brands.children
          );
          if (!isUndefined(parentData)) {
            let parentName = parentData.name;
            if (isUndefined(brandTypeRelation[parentName])) {
              brandTypeRelation[parentName] = [];
            }

            if (!isUndefined(brandTypeRelation[parentName])) {
              brandTypeRelation[parentName].push(brand);
            }
          }
        }
        return true;
      });
      let filterNumber = 0;
      Object.keys(brandTypeRelation).map((index, value) => {
        let filterAttribute = filter.attribute;

        return items.push(
          <FilterItem
            key={`filter-item-${index}-${value}`}
            categoryName={index}
            listWithCloseButton={listWithCloseButton}
            selectedCategory={true}
            openDropdown={true}
            wrapper={true}
            icon={""}
          >
            {Object.keys(brandTypeRelation[index]).map((cindex, cvalue) => {
              let element = brandTypeRelation[index][cindex];
              if (props.multi) {
                filterAttribute = filter.attribute + "_" + cindex;
              }
              let activeFilter = false;
              if (!isUndefined(props.activeFilters)) {
                activeFilter = !isUndefined(
                  props.activeFilters[filter.attribute + "_" + cindex]
                );
              }
              return (
                <FilterItem
                  key={`filter-item-${element.name}-${value}-${element.name}-${cvalue}`}
                  categoryName={element.name}
                  selectedCategory={true}
                  openDropdown={true}
                  icon={0}
                  option_id={index}
                  listWithCloseButton={listWithCloseButton}
                  activeFilter={activeFilter}
                  removeFilter={e => {
                    removeFilter(
                      props.multi && filter.attribute + "_" + cindex
                    );
                  }}
                  onClick={e => {
                    addFilter({
                      [(props.multi && filter.attribute + "_" + cindex) ||
                      filter.attribute]: {
                        filter_value: element.id,
                        filter_label: element.name,
                        filter_type: "multiple",
                        filter_attribute_label: filter.label
                      }
                    });
                  }}
                />
              );
            })}
          </FilterItem>
        );
      });
    }
  }

  const toggleDropdown = props => {
    let currentToggle = toggle;
    setToggle(!currentToggle);
  };

  return (
    <div id="filter" className={`rotate-${!toggle && "arrow"}`}>
      <h4 onClick={toggleDropdown}>
        <span>{props.title}</span>
        {(toggle && <FontAwesomeIcon icon="arrow-down" />) || (
          <FontAwesomeIcon icon="arrow-up" />
        )}
      </h4>
      <div
        className={`filter-inner-content ${!toggle && "hidden"} ${Object.keys(
          items
        ).length >= 5 && "overflow-on"}`}
      >
        {props.mainWrapper && (
          <FilterWrapper wrapper={true}>{items && <>{items}</>}</FilterWrapper>
        )}
        {!props.mainWrapper && items && <>{items}</>}

        {props.children && props.children}
      </div>
    </div>
  );
};

export default Filter;
