import React, { useEffect } from "react";
import Button from "../../../../../utils/buttons";
import CheckButton from "../../../../../utils/checkButton";
import Input from "../../../../../utils/input";
import RemoveAll from "./removeAll";
import "./sidebar.scss";

const SidebarList = props => {
  let {
    project,
    itemStates,
    exportList,
    setShowExportWithMarkup,
    showExportWithMarkup,
    markup,
    setMarkup,
    calculateFreight,
    setCalculateFreight,
    msrp,
    setMsrp,
    csvData,
    toggleDownload,
    downloadable,
    pending,
    pendingExportList,
    customer
  } = props;

  useEffect(() => {
    pendingExportList(false);
  }, [pendingExportList]);

  const exportWithMarkup = value => {
    if (value === "no") {
      setShowExportWithMarkup("yes");
      document.getElementsByName("markup-input")[0].focus();
    } else {
      setShowExportWithMarkup("no");
    }
  };
  //need logic for export list with Export Entered qty & freight estimates.... TODO
  const setDataForExport = () => {
    exportList(project, itemStates, calculateFreight, markup, msrp);
  };

  return (
    <div className={"sidebar-export-wrapper col"}>
      <div className={"export-sidebar-title"}>
        <h2>EXPORT OPTIONS</h2>
      </div>
      <div className={"export-sidebar-content"}>
        <div className={"radio-btn"}>
          {customer.customer_group.jola_can_view_freight_quote && (
            <CheckButton
              // disabled={downloadable}
              name={"radio"}
              type={"radio"}
              defaultChecked={true}
              className={"radio"}
              wrapperClass={"radio-container"}
              text={"Export Entered QTY & Freight Estimates"}
              onChange={e => {
                setCalculateFreight(true);
                toggleDownload(false);
              }}
              value={true}
            />
          )}
          {customer.customer_group.jola_can_view_tier_prices && (
            <CheckButton
              // disabled={downloadable}
              name={"radio"}
              type={"radio"}
              className={"radio"}
              wrapperClass={"radio-container"}
              text={"Export Non Delivered with Price Tiers"}
              onChange={e => {
                setCalculateFreight(false);
                toggleDownload(false);
              }}
              value="false"
            />
          )}
        </div>
        <div className={"check-btn"}>
          <CheckButton
            disabled={downloadable}
            name={"check"}
            type={"checkbox"}
            value={showExportWithMarkup}
            className={"slider"}
            wrapperClass={"switch-container"}
            text={"Export with Markup"}
            onClick={e => {
              exportWithMarkup(e.target.value);
              toggleDownload(false);
            }}
          />
        </div>
        <div
          className={`export-with-markup ${
            //eslint-disable-next-line
            showExportWithMarkup == "yes" ? "visible" : "hidden"
          }`}
        >
          <Input
            disabled={downloadable}
            name={`markup-input`}
            type={"number"}
            wrapperClass={"range-field-wrapper"}
            inputClass={""}
            value={markup}
            onChange={e => {
              setMarkup(e.target.value);
              toggleDownload(false);
            }}
            onInput={e => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 3);
            }}
          />
          <span className={`markup-input-text`}>% MARKUP</span>
          {customer.customer_group.jola_can_view_msrp && (
            <CheckButton
              disabled={downloadable}
              name={"check"}
              type={"checkbox"}
              value={msrp}
              className={"slider"}
              wrapperClass={"switch-container"}
              text={"Include MSRP"}
              onChange={e => {
                msrp === false ? setMsrp(true) : setMsrp(false);
                toggleDownload(false);
              }}
            />
          )}
        </div>
        <div className={"export-btn"}>
          {!downloadable ? (
            <Button
              // disabled={pending}
              icon={"loadingWhite"}
              onClick={e => {
                e.preventDefault();
                setDataForExport();
              }}
              className={`button btn-orange btn-with-left-icon ${pending &&
                "animate"} ${calculateFreight && "is-freight"}`}
              text={`${(!pending && "Export now") ||
                (calculateFreight && "Calculating") ||
                "Generating..."}`}
            />
          ) : (
            <Button
              onClick={e => {
                e.preventDefault();
                props.toggleDownload(false);
                window.open(csvData.link, "_blank");
              }}
              className={`button btn-orange`}
              text={`Download`}
            />
          )}
        </div>
      </div>
      <RemoveAll {...props} />
    </div>
  );
};
export default SidebarList;
