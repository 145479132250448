export default (state, action) => {
  let currentItems = state.items;
  let newItems = [];

  Object.keys(currentItems).map((element, index) => {
    let thisItem = currentItems[index];
    return thisItem.listId === action.data.changeListName.listId
      ? newItems.push(action.data.changeListName)
      : newItems.push(thisItem);
  });

  return Object.assign({}, state, { items: newItems });
};
