import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderBottomLogo from "./logo";
import HeaderBottomSearch from "./search";
import SearchInput from "./search/searchInput";
import "./bottom.scss";

const HeaderBottom = props => {
  let { loggedIn, showModal } = props;
  let currentUrl = window.location.pathname;

  return (
    <>
      <div className={"header-bottom-container"}>
        <div className="bottom">
          <div
            onClick={() => {
              props.toggleMobileMenu();
            }}
            className={`mobile-nav ${props.mobileMenuActive ? "active" : ""}`}
          >
            <FontAwesomeIcon icon="bars" />
          </div>
          <HeaderBottomLogo />
          <div className="nav">
            {props.menuItems.map(item => (
              <div
                className={`item ${props.onMouseEnter ? "active" : ""} ${
                  currentUrl.includes(item.link, 0) ? "active-link" : ""
                  }`}
                key={item.id}
                onMouseEnter={() => {
                  props.onMouseEnter(item.id);
                }}
		onClick={() => {
                  props.onMouseEnter(item.id);
                }}
              >
                {!item.link && <>{item.name}</>}
                {item.link && (
                  <Link
                    to={item.link}
                    // onClick={e => {
                    //   if (item.loggedInCustomer && !loggedIn) {
                    //     if (item.link !== "/brands") {
                    //       e.preventDefault();
                    //       showModal("loginModal", true);
                    //     } else {
                    //       showModal("loginModal", false);
                    //     }
                    //   } else {
                    //     showModal("loginModal", false);
                    //   }
                    // }}
                    alt={`${item.alt && item.alt}`}
                  >
                    {item.name}
                  </Link>
                )}
              </div>
            ))}
          </div>
          <HeaderBottomSearch />
        </div>
      </div>
      <SearchInput />
    </>
  );
};

export default HeaderBottom;
