import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Register from "./register-state-container";
import { customerActions } from "../../../state/redux/data/customer";
import { default as customerActionTypes } from "../../../state/redux/data/customer/types";
import { default as commonActionTypes } from "../../../state/redux/data/common/types";
import { pendingTask, begin, end } from "react-redux-spinner";
import { commonActions } from "../../../state/redux/data/common";

const REGISTER_CUSTOMER = customerActionTypes.REGISTER_CUSTOMER;
const REGISTER_CUSTOMER_SUCCESS = customerActionTypes.REGISTER_CUSTOMER_SUCCESS;
const UNLOCK = commonActionTypes.UNLOCK;

const CustomerRegisterReduxContainer = props => {
  if (props.status) return <Redirect to={"/register/success"} />;
  return <Register {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer,
  loading: state.customer.loading,
  status: state.customer.status,
  locked: state.common.locked
});

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (request, address) => {
      dispatch(commonActions.lock()).then(function(response) {
        dispatch({
          type: REGISTER_CUSTOMER,
          [pendingTask]: begin,
          request: request,
          address: address
        })
          .then(function(response) {
            if (response) {
              dispatch({
                type: REGISTER_CUSTOMER_SUCCESS,
                [pendingTask]: end,
                status: response
              }).then(function(response) {
                dispatch(commonActions.unlock());
              });
            }
          })
          .catch(function(reason) {
            dispatch({
              type: UNLOCK,
              [pendingTask]: end
            });
          });
      });
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
    unLock: () => {
      dispatch(customerActions.unlock());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerRegisterReduxContainer);
