import { createStore, compose } from "redux";
import { createBrowserHistory } from "history";
import { loadState } from "./localStorage";
import globalReducers from "./reducers";
import { globalMiddleware } from "./middleware";
import { throttleIt } from "./throttle";

export const history = createBrowserHistory();

const persistedState = loadState();

const middleware = globalMiddleware;

const enchancements = compose(
  middleware,
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : noop => noop
);

const store = createStore(globalReducers, persistedState, enchancements);

export default store;

throttleIt(store);
