import { isEmpty, isUndefined } from "../../../../helper/functions";

export default (state, action) => {
  let currentFilter = state;
  let newFilter = action.filter;

  Object.keys(currentFilter).map((index, value) => {
    let searchKey = Object.keys(newFilter)[0];

    if (
      !isUndefined(currentFilter["items"]) &&
      searchKey in currentFilter["items"]
    ) {
      return (currentFilter["items"][searchKey] = newFilter[searchKey]);
    } else {
      if (!isUndefined(currentFilter["items"])) {
        return (currentFilter["items"][searchKey] = newFilter[searchKey]);
      }
    }
    return true;
  });
  if (isEmpty(currentFilter["items"])) {
    currentFilter["items"] = newFilter;
  }

  return Object.assign({}, state, currentFilter);
};
