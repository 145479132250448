import React from "react";
import Image from "../../../../../utils/image";

const BrandItem = props => {
  let { item } = props;

  return (
    <div className="brand-logo">
      <Image source={item.content} image_alt={item.name} />
    </div>
  );
};

export default BrandItem;
