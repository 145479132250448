import React from "react";
import Form from "../../../../utils/forms";
import Input from "../../../../utils/input";
import Button from "../../../../utils/buttons";
import CheckButton from "../../../../utils/checkButton";
import "./information.scss";

const CustomerInformationForm = props => {
  return (
    <div className="account-form-edit-container">
      <h1>EDIT ACCOUNT INFORMATION</h1>
      <h4 className={"account-labels account-labels"}>Account Information</h4>
      <Form>
        <div className="form-container">
          <div className="column-fields one">
            <Input
              name={"firstname"}
              type="text"
              className={""}
              placeholder="First name"
              value={props.firstname}
              onChange={event => props.setFirstname(event.target.value)}
              required={true}
              icon="user"
            />
            <Input
              name={"lastname"}
              type={"text"}
              className={""}
              value={props.lastname}
              onChange={event => props.setLastname(event.target.value)}
              required={true}
              icon="user"
              placeholder="Last name"
            />
            {/* NEED TO CHANGE TEXT (add prop text) */}
            <div className="check-buttons">
              <div className="check-button-email btn">
                <span style={{ marginRight: 10 }}>
                  <CheckButton
                    name={"check"}
                    type={"checkbox"}
                    value={props.changeEmail}
                    className={"slider"}
                    wrapperClass={"switch-container"}
                    onChange={e => {
                      let value = e.target.value;
                      return value === "false" || value === false
                        ? props.setChangeEmail(true)
                        : props.setChangeEmail(false);
                    }}
                  />
                </span>
                <h4>Change email</h4>
              </div>
              <div className="check-button-password btn">
                <span style={{ marginRight: 10 }}>
                  <CheckButton
                    name={"check"}
                    type={"checkbox"}
                    value={props.changePassword}
                    className={"slider"}
                    wrapperClass={"switch-container"}
                    defaultChecked={props.changePassword}
                    onChange={e => {
                      let value = e.target.value;
                      return value === "false" || value === false
                        ? props.setChangePassword(true)
                        : props.setChangePassword(false);
                    }}
                  />
                </span>
                <h4>Change password</h4>
              </div>
            </div>
          </div>
          <div className="column-fields two">
            {props.changeEmail && (
              <div className="email-input inner">
                <Input
                  name={"email"}
                  type="text"
                  className={""}
                  placeholder="email"
                  value={props.email}
                  onChange={event => props.setEmail(event.target.value)}
                  required={true}
                  icon="user"
                />
              </div>
            )}

            <div className="password-input inner">
              {(props.changeEmail || props.changePassword) && (
                <Input
                  name={"currentPassword"}
                  type="password"
                  className={""}
                  placeholder="Current Password"
                  value={props.currentPassword}
                  onChange={event =>
                    props.setCurrentPassword(event.target.value)
                  }
                  required={true}
                  icon="user"
                />
              )}
              {props.changePassword && (
                <>
                  <Input
                    name={"password"}
                    type="password"
                    className={""}
                    placeholder="Password"
                    value={props.password}
                    onChange={event => props.setPassword(event.target.value)}
                    required={true}
                    icon="user"
                  />
                  <Input
                    name={"confirmPassword"}
                    type="password"
                    className={""}
                    placeholder="Confirm Password"
                    value={props.confirmPassword}
                    onChange={event =>
                      props.setConfirmPassword(event.target.value)
                    }
                    required={true}
                    icon="user"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="save-btn">
          <Button
            className={`${(props.locked === false && "button btn-orange") ||
              "btn button btn-white-disabled btn-save"}`}
            onClick={e => {
              e.preventDefault();
              if (props.locked === false) {
                let email_request = {};
                let password_request = {};
                if (props.changeEmail) {
                  email_request = {
                    email: props.email,
                    password: props.currentPassword
                  };
                }
                if (props.changePassword) {
                  password_request = {
                    currentPassword: props.currentPassword,
                    newPassword: props.password
                  };
                }
                props.onSubmit({
                  firstname: props.firstname,
                  lastname: props.lastname,
                  ...email_request,
                  ...password_request
                });
              }
            }}
            text={`${(props.locked && "Processing") || "Save"}`}
          />
        </div>
      </Form>
    </div>
  );
};

export default CustomerInformationForm;
