// Action types
const LOCK = "jola/common/LOCK";
const UNLOCK = "jola/common/UNLOCK";
const CHECK_CUSTOMER_AUTHORIZATION = "jola/common/CHECK_CUSTOMER_AUTHORIZATION";
const FORCE_CHECK_CUSTOMER_AUTHORIZATION =
  "jola/common/FORCE_CHECK_CUSTOMER_AUTHORIZATION";
const CLOSE_ALL_MODALS = "jola/common/CLOSE_ALL_MODALS";
const SHOW_MODAL = "jola/common/SHOW_MODAL";
const CLOSE_MODAL = "jola/common/CLOSE_MODAL";
const ADD_QUERY = "jola/common/ADD_QUERY";
const ADD_QUERY_SUCCESS = "jola/common/ADD_QUERY_SUCCESS";
const ROUTE_CHANGE = "jola/common/ROUTE_CHANGE";
const ROUTE_CHANGE_SUCCESS = "jola/common/ROUTE_CHANGE_SUCCESS";
const GET_CACHE_VERSION = "jola/common/GET_CACHE_VERSION";
const SET_CACHE_VERSION = "jola/common/SET_CACHE_VERSION";
const SET_FLAG = "jola/common/SET_FLAG";
const SEND_EMAIL = "jola/common/SEND_EMAIl";
const SET_APP_VERSION = "jola/common/SET_APP_VERSION";

export default {
  LOCK,
  UNLOCK,
  CHECK_CUSTOMER_AUTHORIZATION,
  FORCE_CHECK_CUSTOMER_AUTHORIZATION,
  CLOSE_ALL_MODALS,
  SHOW_MODAL,
  CLOSE_MODAL,
  ADD_QUERY,
  ADD_QUERY_SUCCESS,
  ROUTE_CHANGE,
  ROUTE_CHANGE_SUCCESS,
  GET_CACHE_VERSION,
  SET_CACHE_VERSION,
  SET_FLAG,
  SEND_EMAIL,
  SET_APP_VERSION
};
