import React from "react";
import { connect } from "react-redux";
import commonActions from "../../state/redux/data/common/actions";
import GraphQlQueryWrapper from "./dynamicGraphQlWrapper-query-container";
import { pendingTask, begin } from "react-redux-spinner";
import { default as queryActionTypes } from "../../state/redux/data/queries/types";
import { default as queriesActions } from "../../state/redux/data/queries/actions";
import store from "../../state/redux/store";
import { default as graphQlConfig } from "../../state/config/graphQlConfig";
import { customerIsLoggedIn } from "../../state/helper/customer";
import { isQueryCached } from "../../state/helper/queries";
// import DynamicGraphQlWrapperCachedQueryContainer from "./cached-query-container";

const ADD_QUERY = queryActionTypes.ADD_QUERY;

const DynamicGraphQlWrapperReduxContainer = props => {
  let token = false;
  let isCached = false;
  console.log(props);
  
  token = customerIsLoggedIn(props.customer);
  isCached = isQueryCached(props);

  return (
    <GraphQlQueryWrapper
      {...props}
      token={token}
      store={store}
      isCached={isCached}
    />
  );

  // TODO: see if this part needs to be refactored?
  // return <DynamicGraphQlWrapperCachedQueryContainer {...props} isCached={isCached}/>
};

const mapStateToProps = state => ({
  thisState: state.customer,
  customer: state.customer.customer,
  modal: state.customer.modal,
  timestamp: state.common.timestamp,
  data: state.queries.data,
  queries: state.queries
});

const mapDispatchToProps = dispatch => {
  return {
    addQueryData: (data, identifier, key) => {
      dispatch(queriesActions.addQueryData(data, identifier, key));
    },
    addQuery: (
      id,
      url,
      query,
      variables = [],
      type = graphQlConfig.types["query"]
    ) => {
      dispatch({
        type: ADD_QUERY,
        [pendingTask]: begin, // Make sure you embrace `pendingTask` in brackets [] to evaluate it,
        // Any additional key/values may be included here
        id: id,
        query: query,
        variables: variables,
        queryType: type
      });
    },
    closeAllModals: () => {
      dispatch(commonActions.closeAllModals());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicGraphQlWrapperReduxContainer);
