import React from "react";
import "./listing.scss";
import SidebarList from "./sidebar";
import MainContentList from "./mainContent";

const Listing = props => {
  return (
    <div id="listing">
      <MainContentList {...props} />
      <SidebarList {...props} />
    </div>
  );
};

export default Listing;
