import React from "react";
import { connect } from "react-redux";
import Links from "./links";
import commonActions from "../../../state/redux/data/common/actions";
import customerActions from "../../../state/redux/data/customer/actions";

const LinksRedux = props => {
  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }

  return <Links {...props} loggedIn={loggedIn} />;
};
const mapStateToProps = state => ({
  customer: state.customer.customer,
  locked: state.common.locked
});

const mapDispatchToProps = dispatch => {
  return {
    logout: callback => {
      dispatch(commonActions.lock());
      dispatch(customerActions.logoutCustomer());
    },
    showModal: (identifier, show) => {
      dispatch(commonActions.showModal(identifier, show));
    },
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    lock: () => {
      dispatch(customerActions.lock());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinksRedux);
