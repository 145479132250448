import React from "react";
import Image from "../../../../../utils/image";
import { Link } from "react-router-dom";
import "./categoryBlock.scss";

const CategoryBlock = props => {
  return (
    <Link
      className={"home-category-item"}
      to={props.categoryUrl}
      onClick={props.onClick}
    >
      <div className="home-category-item-inner">
        <div className="home-category-item-img">
          <Image source={props.categoryImage} image_alt={props.categoryTitle} />
        </div>
        <h2>{props.categoryTitle}</h2>
      </div>
    </Link>
  );
};

export default CategoryBlock;
