import React, { useState } from "react";
import BrandItem from "./brandItem";
import Carousel from "../../../../utils/carousel";

import "./brands.scss";
import { isUndefined } from "../../../../state/helper/functions";
const Brands = props => {
  const [currentIndex, setCurrentIndex] = useState(0);
  let { data } = props;
  if (isUndefined(data)) return "";
  let items = data.getDynamicContentByContentType.map(item => {
    return <BrandItem key={`${item.content_id}`} item={item} />;
  });
  let anotheItemsArray = data.getDynamicContentByContentType.map(item => {
    return <BrandItem key={`${item.content_id + 10}`} item={item} />;
  });
  let itemsClone = [...items, ...anotheItemsArray];
  items = items + anotheItemsArray;
  const responsiveData = {
    0: {
      items: 1
    },
    640: {
      items: 3
    },
    768: {
      items: 4
    },
    1024: {
      items: 7
    }
  };

  return (
    <div className={`brands-dynamic`}>
      <>
        <Carousel
          carouselId={"brands"}
          mouseDragEnabled={false}
          responsive={responsiveData}
          infinite={true}
          autoPlay={false}
          autoPlayInterval={1000}
          currentIndex={currentIndex}
          dotsDisabled={true}
          setCurrentIndex={setCurrentIndex}
          onSlideChangedOverwrite={true}
          stopAutoPlayOnHover={false}
          duration={0}
          buttonsDisabled={true}
        >
          {itemsClone}
        </Carousel>
      </>
    </div>
  );
};

export default Brands;
