import React from "react";
import { useQuery } from "@apollo/react-hooks";
import queryLoader from "../state/graphql/queryLoader";
import ProductReduxContainer from "./product-redux-container";
import { isUndefined } from "../state/helper/functions";
import ReactLoader from "../utils/loader";

const query = queryLoader("product");

const ProductQueryContainer = props => {
  let filter = { filters: { url_key: { eq: props.variables.url } } };
  let product;

  const { loading, error, data, refetch } = useQuery(query, {
    variables: filter,
    skip: !filter
  });

  if (loading) return <ReactLoader />;
  if (error) return `Error! ${error}`;

  product = !isUndefined(data.products.items[0]) ? data.products.items[0] : {};

  return (
    <ProductReduxContainer
      {...props}
      refetch={refetch}
      data={product}
      showFilters={props.showFilters}
    />
  );
};

export default ProductQueryContainer;
