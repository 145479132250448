import React from "react";
import Image from "../../../../utils/image";
import SanitizedHTML from "react-sanitized-html";
import { Link } from "react-router-dom";
import { default as tc } from "../../../../state/config/themeConfiguration";
import "./brandItem.scss";

const BrandItem = props => {
  let image = "../../../../images/mainbanner.jpg";

  if (props.image) {
    image = tc.magento_url + tc.media_url + tc.category_url + "/" + props.image;
  }
  let { loggedIn, showModal } = props;
  return (
    <>
      <div className="brand-item">
        <div className="brand-item-top">
          <Link
            to={props.url}
          // onClick={e => {
          //   if (!loggedIn) {
          //     e.preventDefault();
          //     showModal("loginModal", true);
          //     return;
          //   }
          // }}
          >
            <Image
              source={image}
              imgContainer="brand-item-image"
              image_alt={props.title}
            />
            {props.description && (
              <p className="brand-item-description">
                <SanitizedHTML
                  allowedAttributes={{ div: ["class"] }}
                  allowedTags={["h1", "h2", "h3", "h4", "h5", "a", "p", "div"]}
                  html={props.description}
                />
              </p>
            )}
          </Link>
        </div>
        <h3 className="brand-item-title">{props.title}</h3>
      </div>
    </>
  );
};
export default BrandItem;
