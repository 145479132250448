import React, { useState } from "react";
import Breadcrumbs from "../../../utils/breadcrumbs";
import HeroImage from "../../../utils/heroImage";
import { getTabs, getBrandItems } from "./functions";
import themeConfiguration from "../../../state/config/themeConfiguration";
import { Helmet } from "react-helmet";
import "./brands.scss";

const Brands = props => {
  const brands = props.brands;
  const brandsChildren = brands.children;
  const [countTab, setCountTab] = useState(0);
  const brandItemsComponents = getBrandItems(brandsChildren, props);
  let tabs = getTabs(brandsChildren, countTab, setCountTab);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Brands - {themeConfiguration.name}</title>
        <link rel="canonical" href={`${themeConfiguration.app_url}/brands`} />
      </Helmet>
      <div className="brands-container">
        <div className="main-banner">
          <HeroImage source={"BrandsPage.jpg"} alt={"Brands banner image"} />
        </div>
        <div className={`page-main-content`}>
          <div className="breadcrumbs">
            <Breadcrumbs
              links={[
                { url: "/", title: "Home | " },
                { url: "#", title: "Brands" }
              ]}
            />
          </div>
          <h1>Brands</h1>
          <div className="brand-tabs-container">{tabs}</div>
          <div className="brand-items-container">
            {Object.keys(brandsChildren).map((value, index) => {
              return (
                <div
                  key={`brands-container-${brandsChildren[value].id}`}
                  className={`brand-items-inner-container brand-items-${index} ${(index ===
                    countTab &&
                    "brand-items-active") ||
                    ""}`}
                >
                  {brandItemsComponents[index]}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default Brands;
