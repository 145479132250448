import React from "react";
import CheckButton from "../checkButton";
import CloseButton from "../closeButton";
import Button from "../buttons";
import Select from "../select";
import RangeSlider from "../rangeSlider";
import PricingInformation from "../../utils/products/pricingInformation";
import Input from "../input";
import { onlyNumbers } from "../../state/helper/functions";

import "./typography.scss";

const Typography = props => {
  let { product } = props;
  let countrySelect = "";
  let sortByOptions = [
    { label: "Name", value: "name" },
    { label: "Position", value: "pos" },
    { label: "Price", value: "prc" }
  ];
  return (
    <div className={`page-main-content`}>
      <div className={`typography-container`}>
        <div className={`typography-block fonts`}>
          <div style={{ marginBottom: 10 }}>
            <h1>Title H1 Halant Medium</h1>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h2>Title H2 Halant Medium</h2>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h3>Title H3 Nunito Sans Regular</h3>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h4>Title H4 Halant SemiBold</h4>
          </div>
          <div style={{ marginBottom: 10 }}>
            <p>Paragraph Nunito Sans Regular</p>
          </div>
        </div>
        <div
          className={`typography-block buttons`}
          style={{ marginBottom: 50 }}
        >
          <div className={`save-button`} style={{ marginBottom: 20 }}>
            <span style={{ marginRight: 10 }}>
              <Button
                className={"button btn-white-disabled btn-save"}
                text={"Save Disabled"}
              />
            </span>
            <Button
              className={
                "button btn-with-left-icon btn-white-disabled btn-save plp-button"
              }
              text={"Save PLP"}
              icon={"excelGray"}
            />
            <span style={{ marginRight: 10 }}>
              <Button
                className={
                  "button btn-with-left-icon btn-white-disabled btn-save"
                }
                text={"Save Disabled"}
                icon={"excelGray"}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button
                className={
                  "button btn-with-left-icon btn-white-orange-border btn-save"
                }
                text={"Save Active"}
                icon={"excelOrange"}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button
                className={"button btn-with-left-icon btn-orange btn-save"}
                text={"Save Selected"}
                icon={"excelWhite"}
              />
            </span>
          </div>
          <div className={`program-button`} style={{ marginBottom: 20 }}>
            <span style={{ marginRight: 10 }}>
              <Button
                className={
                  "button btn-with-left-icon btn-white-disabled btn-program"
                }
                text={"Program Disabled"}
                icon={"programsGray"}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button
                className={
                  "button btn-with-left-icon btn-white-orange-border btn-program"
                }
                text={"Program"}
                icon={"programsOrange"}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button
                className={
                  "button btn-with-left-icon btn-white-orange-border btn-program"
                }
                text={"Program"}
                icon={"programsOrange"}
              />
            </span>
          </div>
          <div className={`save-button`} style={{ marginBottom: 20 }}>
            <span style={{ marginRight: 10 }}>
              <Button
                className={
                  "button btn-with-left-icon btn-white-orange-border btn-save"
                }
                text={"Save Active"}
                icon={"excelOrange"}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button
                className={"button btn-with-left-icon btn-orange btn-save"}
                text={"Save Hover"}
                icon={"excelWhite"}
              />
            </span>
          </div>
          <div className={`program-button`} style={{ marginBottom: 20 }}>
            <span style={{ marginRight: 10 }}>
              <Button
                className={
                  "button btn-with-left-icon btn-white-orange-border btn-program"
                }
                text={"Add to Program"}
                icon={"programsOrange"}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button
                className={
                  "button btn-with-left-icon btn-white-orange-border btn-program"
                }
                text={"Add to Program Colapsable"}
                icon={"programsOrange"}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button
                className={
                  "button btn-with-left-icon btn-white-orange-border btn-program"
                }
                text={"Add to Program Colapsable With Checkobox"}
                icon={"programsOrange"}
              />
            </span>
          </div>
          <div className={`learn-more-button`} style={{ marginBottom: 20 }}>
            <span style={{ marginRight: 10 }}>
              <Button
                className={"button btn-with-right-icon btn-orange right-arrow"}
                text={"Learn More "}
                icon={"rightArrow"}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button
                className={"button btn-with-right-icon btn-black right-arrow"}
                text={"Learn More Hover"}
                icon={"rightArrow"}
              />
            </span>
          </div>
          <div className={`learn-more-button`} style={{ marginBottom: 20 }}>
            <span style={{ marginRight: 10 }}>
              <Button
                className={
                  "button btn-with-right-icon btn-white-border-shadow right-arrow"
                }
                text={"Register to purchase"}
                icon={"rightArrowOrange"}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button
                className={
                  "button btn-with-right-icon btn-white-border-shadow right-arrow"
                }
                text={"Register to purchase Hover"}
                icon={"rightArrowOrange"}
              />
            </span>
          </div>
          <div className={`create-button`} style={{ marginBottom: 20 }}>
            <span style={{ marginRight: 10 }}>
              <Button
                className={"button btn-white-orange-border"}
                text={"Create"}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button className={"button btn-orange"} text={"Create Hover"} />
            </span>
          </div>
          <div className={`create-button`} style={{ marginBottom: 20 }}>
            <span style={{ marginRight: 10 }}>
              <Button
                className={"button btn-orange-disabled"}
                text={"Complete Disabled"}
              />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button className={"button btn-orange"} text={"Next"} />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button className={"button btn-black"} text={"Next Hover"} />
            </span>
          </div>
          <div className={`create-button`} style={{ marginBottom: 20 }}>
            <span style={{ marginRight: 10 }}>
              <Button className={"button btn-orange"} text={"Reset Password"} />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button
                className={"button btn-black"}
                text={"Reset Password Hover"}
              />
            </span>
          </div>
          <div className={`create-button`} style={{ marginBottom: 20 }}>
            <span style={{ marginRight: 10 }}>
              <Button className={"button btn-orange"} text={"Login"} />
            </span>
            <span style={{ marginRight: 10 }}>
              <Button className={"button btn-black"} text={"Login Hover"} />
            </span>
          </div>
          <div style={{ marginBottom: 30 }}>
            <Button className={"button btn-gray"} text={"NATIONAL BRANDS"} />
          </div>
          <div className={`chek-buttons`} style={{ marginBottom: 40 }}>
            <CheckButton
              name={"radio"}
              type={"radio"}
              value={"no"}
              className={"radio"}
              wrapperClass={"radio-container"}
              text={"Radio"}
            />
            <CheckButton
              name={"radio"}
              type={"radio"}
              value={"no"}
              className={"radio"}
              wrapperClass={"radio-container"}
              text={"Radio"}
            />
            <CheckButton
              name={"radio"}
              type={"radio"}
              value={"no"}
              className={"radio"}
              wrapperClass={"radio-container"}
              text={"Radio"}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <CheckButton
              name={"check"}
              type={"checkbox"}
              value={"no"}
              className={"checkmark"}
              wrapperClass={"checkbox-container"}
              text={"Check"}
            />
            <CheckButton
              name={"check"}
              type={"checkbox"}
              value={"no"}
              className={"checkmark"}
              wrapperClass={"checkbox-container"}
              text={"Check"}
            />
            <CheckButton
              name={"check"}
              type={"checkbox"}
              value={"no"}
              className={"checkmark"}
              wrapperClass={"checkbox-container"}
              text={"Check"}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <CheckButton
              name={"check"}
              type={"checkbox"}
              value={"no"}
              className={"slider"}
              wrapperClass={"switch-container"}
              text={"Switch"}
            />
            <CheckButton
              name={"check"}
              type={"checkbox"}
              value={"no"}
              className={"slider"}
              wrapperClass={"switch-container"}
              text={"Switch"}
            />
            <CheckButton
              name={"check"}
              type={"checkbox"}
              value={"no"}
              className={"slider"}
              wrapperClass={"switch-container"}
              text={"Switch"}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <CloseButton className={"close-btn"} />
          </div>
          <div style={{ marginBottom: 40 }}>
            <RangeSlider minId={"range1"} maxId={"range2"} min={1} max={2500} />
          </div>
          <div style={{ marginBottom: 40 }}>
            <PricingInformation product={product} />
          </div>
          <div style={{ marginBottom: 40 }}>
            <PricingInformation product={product} />
          </div>
          <div style={{ marginBottom: 40 }}>
            <PricingInformation product={product} />
          </div>
          <div style={{ marginBottom: 40 }}>
            <Select
              name={"sorter"}
              type={"text"}
              placeholder={"Program"}
              wrapperClass={"select-down-arrow"}
              value={countrySelect}
              icon={"list"}
              options={sortByOptions}
            />
          </div>
          <div style={{ marginRight: 10, marginBottom: 40 }}>
            <Button
              className={
                "button btn-with-left-icon list-more-products-btn btn-program"
              }
              text={"Loading More Items"}
              icon={"programsOrange"}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <Input
              name={`markup-input`}
              type={"number"}
              wrapperClass={"range-field-wrapper"}
              inputClass={""}
              onKeyPress={e => {
                onlyNumbers(e);
              }}
            />
          </div>
          <div className={"input-fields input-fields-left"}>
            <div style={{ marginBottom: 20 }}>
              <Input
                name={"firstname"}
                type="text"
                className={""}
                placeholder="First name"
                // value={firstname}
                // onChange={event => setFirstname(event.target.value)}
                required={true}
                icon="user"
                // validation={validator.message("firstname", firstname, "required")}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <Input
                name={"lastname"}
                type={"text"}
                className={""}
                // value={lastname}
                // onChange={event => setLastname(event.target.value)}
                required={true}
                icon="user"
                placeholder="Last name"
                // validation={validator.message("lastname", lastname, "required")}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <Input
                name={"officeNumber"}
                type={"text"}
                className={""}
                // value={officeNumber}
                // onChange={event => setOfficeNumber(event.target.value)}
                required={true}
                icon="phone"
                placeholder="Direct Office Number"
                // validation={validator.message(
                //   "officeNumber",
                //   officeNumber,
                //   "required|phone"
                // )}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <Input
                name={"mobile"}
                type={"text"}
                className={""}
                // value={mobile}
                // onChange={event => setMobile(event.target.value)}
                icon="cell"
                placeholder="Mobile Phone Number"
                // validation={validator.message("mobile", mobile, "phone")}
              />
            </div>
          </div>
          <div className={"input-fields input-fields-right"}>
            <div style={{ marginBottom: 20 }}>
              <Input
                name={"email"}
                type={"text"}
                className={""}
                // value={email}
                // onChange={event => setEmail(event.target.value)}
                required={true}
                icon="email"
                placeholder="Email"
                // validation={validator.message("email", email, "required|email")}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <Input
                name={"password"}
                type={"password"}
                className={""}
                // value={password}
                // onChange={event => setPassword(event.target.value)}
                required={true}
                icon="lock"
                placeholder="Password"
                // validation={validator.message(
                //   "password",
                //   password,
                //   "required|min:8,string"
                // )}
                // onBlur={e => {
                //   validator.message(
                //     "confirmpassword",
                //     confirmPassword,
                //     `required|min:8,string|match:${password}`
                //   );
                // }}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <Input
                name={"confirmPassword"}
                type={"password"}
                className={""}
                // value={confirmPassword}
                // onChange={event => setConfirmPassword(event.target.value)}
                required={true}
                icon="lock"
                placeholder="Confirm Password"
                // validation={validator.message(
                //   "confirmPassword",
                //   confirmPassword,
                //   `required|min:8,string|match:${password}`
                // )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Typography;
