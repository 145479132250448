import React, { useState } from "react";
import "./rangeSlider.scss";
import Input from "./../input";
import { selectInput } from "./functions";

const brightColor = "#c4c6c9";
const darkColor = "#62666a";

const RangeSlider = props => {
  const [min] = useState(Math.round(props.min));
  const [max] = useState(Math.round(props.max));
  const [minValue, setMinValue] = useState(Math.round(props.min));
  const [maxValue, setMaxValue] = useState(Math.round(props.max));
  const [minPreviousState] = useState(minValue);
  const [maxPreviousState] = useState(maxValue);
  const [backgroundImage, setBackgroundImage] = useState(null);

  const changeMinSliderColor = value => {
    let val = value;
    let valMax;
    let slidermaxValue = parseInt(maxValue);
    let sliderminValue = parseInt(minValue);

    val = Math.abs(sliderminValue - min) / (max - min);
    valMax = Math.abs(slidermaxValue) / (max - min);

    if (val > valMax) val = valMax;
    setBackgroundImage(
      `-webkit-gradient(linear, left top, right top,
        color-stop(${val}, ${brightColor}),
        color-stop(${val}, ${darkColor}),
        color-stop(${valMax}, ${darkColor}),
        color-stop(${valMax}, ${brightColor})
        `
    );
  };

  const changeMaxSliderColor = value => {
    let val = value;

    let slidermaxValue = parseInt(maxValue);
    let sliderminValue = parseInt(minValue);
    val = Math.abs(slidermaxValue - min) / (max - min);
    let valMin = Math.abs(sliderminValue) / (max - min);
    if (val < valMin) val = valMin;
    setBackgroundImage(
      `-webkit-gradient(linear, left top, right top,
        color-stop(${valMin}, ${brightColor}),
        color-stop(${valMin}, ${darkColor}),
        color-stop(${val}, ${darkColor}),
        color-stop(${val}, ${brightColor})
        `
    );
  };

  const handleCalculation = (e, element, closest) => {
    e.preventDefault();

    let value = parseInt(e.target.value);
    let _maxValue = parseInt(maxValue);
    let _minValue = parseInt(minValue);

    if (value > max) value = max - 1;
    if (value < min || Number.isNaN(value)) value = min;

    if (closest) {
      let minimum = Math.abs(minPreviousState - maxValue / 2);
      let maximum = Math.abs(maxPreviousState - maxValue / 2);
      if (minimum > maximum) {
        setMinValue(parseInt(value));
        changeMinSliderColor(value, element);
      }

      if (maximum > minimum) {
        setMaxValue(parseInt(value));
        changeMaxSliderColor(value, element);
      }
      return;
    }

    if (element === props.minId) {
      // setMinPreviousState(minValue);
      if (value) {
        if (value > _maxValue) {
          setMinValue(parseInt(_maxValue));
        } else if (_minValue < _maxValue) {
          setMinValue(parseInt(value));
        } else {
          setMinValue(parseInt(value));
        }
      }
      changeMinSliderColor(value, element);
    }
    if (element === props.maxId) {
      // setMaxPreviousState(maxValue);
      if (value) {
        if (value < _minValue) {
          setMaxValue(parseInt(_minValue));
        } else if (_maxValue > _minValue) {
          setMaxValue(parseInt(value));
        } else {
          setMaxValue(parseInt(value));
        }
      }
      changeMaxSliderColor(value);
    }
  };

  return (
    <div className="rangeslider-container">
      <div className="rangetext-content">
        <span className="range-min-value left">{minValue}</span>
        <span className="range-max-value right">{maxValue}</span>
      </div>
      <div className="rangeslide-content">
        <Input
          name={props.minId}
          type={"range"}
          className={"range-min-slide"}
          value={minValue}
          style={{ backgroundImage: backgroundImage }}
          onClick={e => {
            handleCalculation(e, props.minId);
          }}
          onChange={e => {
            handleCalculation(e, props.minId);
          }}
          min={min}
          max={max}
          usewrapper={false}
        />
        <Input
          name={props.maxId}
          type={"range"}
          className={"range-max-slide"}
          value={maxValue}
          style={{ backgroundImage: backgroundImage }}
          onClick={e => {
            handleCalculation(e, props.maxId);
            // changeMaxSliderColor(e);
          }}
          onChange={e => handleCalculation(e, props.maxId, true)}
          min={min}
          max={max}
          usewrapper={false}
        />
      </div>
      <div className="rangefield-content">
        <Input
          name={props.minId}
          type={"number"}
          wrapperClass={"range-field-wrapper"}
          className={"range-field"}
          inputClass={`range-slider range-slider-min range-slider-${props.minId}`}
          pera={"pera"}
          value={minValue}
          onChange={e => {
            handleCalculation(e, props.minId);
          }}
          onClick={e => {
            selectInput(e);
          }}
          min={min}
          max={max}
        />
        <span>To</span>
        <Input
          name={props.maxId}
          type={"number"}
          wrapperClass={"range-field-wrapper"}
          className={"range-field"}
          inputClass={`range-slider range-slider-min range-slider-${props.minId}`}
          value={maxValue}
          onChange={e => {
            handleCalculation(e, props.maxId);
          }}
          onClick={e => {
            selectInput(e);
          }}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};

export default RangeSlider;
