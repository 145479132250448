import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import ContactForm from "./contactform";

const ContactFormState = props => {
  let { showModal, loggedIn, show } = props;
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [directOfficeNumber, setDirectOfficeNumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [company, setCompany] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [message, setMessage] = useState("");
  const [validator] = useState(
    new SimpleReactValidator({
      locale: "en",
      messages: {
        email: "Please enter a valid email address"
      },
      validators: {
        match: {
          message: "Confirm password needs to match password.",
          rule: (val, params, validator) => params.includes(val),
          messageReplace: (message, params) =>
            message.replace(":values", validator.helpers.toSentence(params))
        },
        phone: {
          message:
            "The office number must be a valid phone number (ex:(123) 123-1234)",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", validator.helpers.toSentence(params))
        }
      },
      autoForceUpdate: true
    })
  );
  const [validate, setValidate] = useState(false);

  return (
    <ContactForm
      email={email}
      setEmail={setEmail}
      firstname={firstname}
      setFirstname={setFirstname}
      lastname={lastname}
      setLastname={setLastname}
      directOfficeNumber={directOfficeNumber}
      setDirectOfficeNumber={setDirectOfficeNumber}
      mobile={mobile}
      setMobile={setMobile}
      company={company}
      setCompany={setCompany}
      privacyPolicy={privacyPolicy}
      setPrivacyPolicy={setPrivacyPolicy}
      sendEmail={props.sendEmail}
      validator={validator}
      validate={validate}
      setValidate={setValidate}
      message={message}
      setMessage={setMessage}
      showModal={showModal}
      loggedIn={loggedIn}
      show={show}
    />
  );
};

export default ContactFormState;
