import React from "react";
import { connect } from "react-redux";
import ProductFreightInfo from "./productFreightInfo";
import { freightQuoteActions } from "../../../state/redux/data/freightQuote";
import { messagesActions } from "../../../state/redux/data/messages";

const ProductFreightInfoReduxContainer = props => {
  return <ProductFreightInfo {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer,
  freightInformation: state.freightInformation.data,
  freightStatus: state.freightInformation.status
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    freightRequest: productInformation => {
      dispatch(freightQuoteActions.freightRequest(productInformation));
    },
    removeFreightInformation: () => {
      dispatch(freightQuoteActions._reduceFreightInformation(undefined));
      dispatch(freightQuoteActions._reduceFreightStatus(undefined));
    },
    addMessage: (message, type, target) => {
      dispatch(messagesActions.addMessage(message, type, target));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductFreightInfoReduxContainer);
