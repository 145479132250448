import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../utils/breadcrumbs";
import ProductItem from "../../../utils/products/listing/productItem";
import { isUndefined } from "../../../state/helper/functions";
import ReactLoader from "../../../utils/loader";
import Button from "../../../utils/buttons";
import themeConfiguration from "../../../state/config/themeConfiguration";
import { Helmet } from "react-helmet";
import Toolbar from "../../../utils/products/toolbar";
import "./search.scss";

const SearchPage = props => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const [program, setProgram] = useState("");

  const [clicked, setClicked] = useState(true);

  if (isUndefined(props.data)) {
    return <ReactLoader />;
  }

  let { data } = props;
  let total_pages;
  let current_page;
  if (data.page_info !== undefined) {
    current_page = data.page_info.current_page;
    total_pages = data.page_info.total_pages;
  }
  if (isUndefined(data)) return "";
  let products = props.data.items;
  let searchResultsTitle = props.search;
  let count = props.data.total_count;

  function handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom === docHeight) {
      if (current_page !== total_pages && total_pages !== 0) {
        if (clicked) {
          setClicked(false);
        } else {
          setClicked(true);
        }
        setTimeout(() => {
          setClicked(true);
        }, 2000);
        props.loadMoreItems(props.data.page_info.current_page);
      }
    }
  }

  function LoadMoreItems() {
    if (clicked) {
      setClicked(false);
    } else {
      setClicked(true);
    }
    setTimeout(() => {
      setClicked(true);
    }, 2000);
    props.loadMoreItems(props.data.page_info.current_page);
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search Results Page - {themeConfiguration.name}</title>
        <link
          rel="canonical"
          href={`${themeConfiguration.app_url}/search-page}`}
        />
      </Helmet>
      <div id="searchpage">
        <div className={"page-main-content"}>
          <div className={"breadcrumbs"}>
            <Breadcrumbs
              links={[
                { url: "/", title: "Home | " },
                { url: "#", title: `Results from "${searchResultsTitle}"` }
              ]}
            />
          </div>
          <h1
            className={`search-product ${products.id}`}
            key={`search-product-item-${products.id}`}
          >
            Search Results For "{searchResultsTitle}"
          </h1>
          <div className={`toolbar-container`}>
            <div className={`toolbar-search-container`}>
              <div className={"toolbar-search count-items"}>
                {(count > 1 && <h4>{count} items</h4>) || <h4>{count} item</h4>}
              </div>
            </div>
            <Toolbar {...props} onlySort={true} searchData={data} />
          </div>
        </div>
        <div
          className={"page-main-content items-list"}
          key={`search-product-item-${products.id}`}
        >
          <div className={"search-items-wrapper"}>
            {products.map(item => {
              let product = item;
              return (
                <ProductItem
                  key={`product-list-${product.id}`}
                  product={product}
                  program={program}
                  setProgram={setProgram}
                />
              );
            })}
          </div>
          {current_page !== total_pages && total_pages !== 0 && (
            <Button
              className={`button btn-with-left-icon list-more-products-btn btn-program ${
                clicked ? "remove-icon" : "show-icon"
              }`}
              text={"Load More Items"}
              icon={`loading`}
              onClick={LoadMoreItems}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SearchPage;
