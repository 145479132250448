import React, { useState } from "react";
import ProductInfoContainer from "./productInfoContainer";
import Breadcrumbs from "../utils/breadcrumbs";
import "./product.scss";
import ProductFreight from "./productFreight";
import ProductRelated from "./productRelated";
import themeConfiguration from "../state/config/themeConfiguration";
import { Helmet } from "react-helmet";
import { isUndefined } from "../state/helper/functions";
import { Redirect } from "react-router-dom";

const Product = props => {
  let { customer } = props;
  let productData = props.data;

  let customerCanSeeFreight = false;
  // customer.customer_group.jola_can_view_freight_quote;
  // if (!isUndefined(props.customer) && props.customer.token) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {productData.name} - {themeConfiguration.name}
        </title>
        <link
          rel="canonical"
          href={`${themeConfiguration.app_url}/${productData.url_key}`}
        />
      </Helmet>
      <div className={"product-page-container"}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            links={[
              { url: "/", title: "Home | " },
              { url: "#", title: productData.name }
            ]}
          />
        </div>
        <ProductInfoContainer
          {...productData}
          customerCanSeeFreight={customerCanSeeFreight}
        />
        {customerCanSeeFreight && <ProductFreight {...props} />}
        <ProductRelated {...props} />
      </div>
    </>
  );
  // } else return <Redirect to={"/"} />;
};

export default Product;
