import { isUndefined, isEmpty } from "../../../state/helper/functions";

export function getBrands(props) {
  let { filtered_category, selected_category, allCategories } = props;
  if (isUndefined(selected_category) || isUndefined(filtered_category))
    return [];

  let current_category_items = filtered_category.items;
  let sanitized_brands = [];
  let _brands = filterBrandsCategory(allCategories.children);
  let product_brands = [];

  let i = 0;
  current_category_items.map(item => {
    if (item.brand !== null) {
      product_brands[i] = item.brand;
      i++;
    }
    return true;
  });
  product_brands = getUnique(product_brands);
  if (product_brands) {
    Object.keys(_brands).map((element, index) => {
      let children = _brands[index].children;
      let name = _brands[index].name;
      let id = _brands[index].id;
      if (id in product_brands) {
        sanitized_brands[index] = { id, name, children };
      } else {
        _brands[index].children.map(child => {
          if (product_brands.includes(child.id)) {
            sanitized_brands[index] = { id, name, children };
          }
          return true;
        });
      }
      return true;
    });
  }

  return sanitized_brands;
}

export function filterBrandsCategory(categories) {
  return categories.filter(el => {
    return el.url_key === "brands";
  })[0].children;
}

export function getCategoryFilter(props) {
  let children_categories = props.category.children;
  let category_filter = {};
  if (isEmpty(children_categories)) return category_filter;

  Object.keys(children_categories).map((element, index) => {
    category_filter[children_categories[index].id] =
      children_categories[index].name;
    return true;
  });

  return category_filter;
}

function getUnique(array) {
  return array.filter((v, i, a) => a.indexOf(v) === i);
}
