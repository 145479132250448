import React, { useState } from "react";
import CheckList from "./checkList";
const ProductItemProgramState = props => {
  let { thisExportList, sku } = props;

  let isChecked =
    thisExportList.skuList !== null &&
    thisExportList.skuList.indexOf(sku) !== -1
      ? true
      : false;
  const [checkbox, setCheckbox] = useState(isChecked);
  return <CheckList {...props} checkbox={checkbox} setCheckbox={setCheckbox} />;
};

export default ProductItemProgramState;
