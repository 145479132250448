import React from "react";
import Slider from "./slider";

export function getSlidesFromItems(items, currentIndex, setCurrentIndex) {
  let carousel_items = [];

  Object.keys(items).map((elem, index) => {
    let style = {
      sliderBackground: {
        backgroundImage: "url(" + items[index].backgroundImageUrl + ")"
      }
    };
    return carousel_items.push(
      <div key={`slider-${index}`}>
        <Slider
          key={"hp-slider-" + index}
          backgroundImage={style.sliderBackground}
          // subheading={items[index].subheading}
          // heading={items[index].heading}
          // description={items[index].description}
          currentIndex={currentIndex}
          buttonUrl={items[index].buttonUrl}
          buttonText={items[index].buttonText}
        />
      </div>
    );
  });

  return carousel_items;
}

export function defaultSlide(items) {
  let style = {
    sliderBackground: {
      backgroundImage: "url(" + items[0].backgroundImageUrl + ")"
    }
  };
  return (
    <div key={`slider-0`}>
      <Slider
        key={"hp-slider-" + 0}
        backgroundImage={style.sliderBackground}
        // subheading={items[index].subheading}
        // heading={items[index].heading}
        // description={items[index].description}
        currentIndex={0}
        buttonUrl={items[0].buttonUrl}
        buttonText={items[0].buttonText}
      />
    </div>
  );
}
