import React, { ElementConfig } from "react";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./select2.scss";

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      {(props.selectProps.menuIsOpen && (
        <FontAwesomeIcon icon="arrow-up" />
      )) || <FontAwesomeIcon icon="arrow-down" />}
    </components.DropdownIndicator>
  );
};
const indicatorSeparatorStyle = {
  width: 0
};
const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};
const Select2 = props => {
  return (
    <div className={`select2 ` + props.wrapperClass}>
      <Select
        disabled={props.disabled}
        name={props.name}
        type={props.type}
        icon={props.icon}
        value={props.value}
        defaultInputValue={props.defaultInputValue}
        onChange={event => props.onChange(event)}
        required={props.required}
        wrapperClass={props.wrapperClass}
        placeholder={props.placeholder}
        options={props.options}
        useCustom={props.useCustom}
        className={`react-select-container`}
        classNamePrefix={`react-select`}
        menuIsOpen={props.menuIsOpen}
        maxMenuHeight={230}
        isSearchable={props.isSearchable}
        components={{ DropdownIndicator, IndicatorSeparator }}
      />
    </div>
  );
};

export default Select2;
