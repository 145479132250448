// Action Creators
import types from "./types";

const loginCustomer = (email, password) => {
  return {
    type: types.LOGIN_CUSTOMER,
    email: email,
    password: password
  };
};

const loginCustomerFinish = () => {
  return {
    type: types.LOGIN_CUSTOMER_FINISH
  };
};

const logoutCustomer = (hideMessage = false, logoutMessage = null) => {
  return {
    type: types.LOGOUT_CUSTOMER,
    hideMessage: hideMessage,
    logoutMessage: logoutMessage
  };
};

const setCustomerToken = token => {
  return {
    type: types.SET_CUSTOMER_TOKEN,
    token: token
  };
};

const setCustomerTokenRegistration = token => {
  return {
    type: types.SET_CUSTOMER_TOKEN_REGISTRATION,
    token: token
  };
};

const getCustomerInformation = (token, isLoggedIn = false, email = "") => {
  return {
    type: types.GET_CUSTOMER_INFORMATION,
    token: token,
    isLoggedIn: isLoggedIn,
    email: email
  };
};

const setCustomerInformation = (customer, isLoggedIn = false) => {
  return {
    type: types.SET_CUSTOMER_INFORMATION,
    customer: customer,
    isLoggedIn: isLoggedIn
  };
};

const clearCustomerInformation = () => {
  return {
    type: types.CLEAR_CUSTOMER_INFORMATION
  };
};

const registerCustomer = (request, address) => {
  return {
    type: types.REGISTER_CUSTOMER,
    request: request,
    address: address
  };
};

const registerCustomerSuccess = status => {
  return {
    type: types.REGISTER_CUSTOMER_SUCCESS,
    status: status
  };
};

const updateCustomerInformation = request => {
  return {
    type: types.UPDATE_CUSTOMER_INFORMATION,
    request: request
  };
};

const forgotCustomerPassword = email => {
  return {
    type: types.FORGOT_CUSTOMER_PASSWORD,
    email: email
  };
};

const resetCustomerPassword = (token, password, confirmPassword) => {
  return {
    type: types.RESET_CUSTOMER_PASSWORD,
    token: token,
    password: password,
    confirmPassword: confirmPassword
  };
};

const resetCustomerPasswordSuccess = status => {
  return {
    type: types.RESET_CUSTOMER_PASSWORD_SUCCESS,
    status: status
  };
};

const editCustomerAddress = request => {
  return {
    type: types.EDIT_CUSTOMER_ADDRESS,
    request: request
  };
};

const _reduceCustomer = data => {
  return {
    type: types._REDUCE_CUSTOMER,
    data: data
  };
};

const _reduceCustomerAddress = data => {
  return {
    type: types._REDUCE_CUSTOMER_ADDRESS,
    data: data
  };
};

const validateCustomerToken = token => {
  return {
    type: types._VALIDATE_CUSTOMER_TOKEN,
    token: token
  };
};

export default {
  loginCustomer,
  logoutCustomer,
  setCustomerToken,
  setCustomerTokenRegistration,
  getCustomerInformation,
  setCustomerInformation,
  clearCustomerInformation,
  loginCustomerFinish,
  registerCustomer,
  registerCustomerSuccess,
  updateCustomerInformation,
  forgotCustomerPassword,
  resetCustomerPassword,
  resetCustomerPasswordSuccess,
  editCustomerAddress,
  _reduceCustomer,
  _reduceCustomerAddress,
  validateCustomerToken
};
