import React from "react";
import MediaGallery from "../../../utils/mediaGallery";
import "./productInfoLeft.scss";
import ProductInfoLeftLinks from "./productInfoLeftLinks";

const ProductInfoLeft = props => {
  const images = props.media_gallery_entries;

  return (
    <div id="productinfoleft">
      <MediaGallery images={images} placeholderImage={props.image} />
      <ProductInfoLeftLinks images={images} {...props} />
    </div>
  );
};

export default ProductInfoLeft;
