import React from "react";
import { useQuery } from "@apollo/react-hooks";
import queryLoader from "../../../state/graphql/queryLoader";
import { isUndefined } from "../../../state/helper/functions";
import Sidebar from "./sidebar";

const query = queryLoader("getFilters");

const SidebarQueryContainer = props => {
  let { selected_category } = props;

  let variables = { filter: selected_category };
  const { loading, error, data } = useQuery(query, {
    variables: variables,
    skip: !variables
  });

  if (loading) return <></>;

  if (error) return "Something went wrong.";

  if (
    isUndefined(data) ||
    isUndefined(data.getFilters) ||
    isUndefined(data.getFilters.allProducts) ||
    isUndefined(data.getFilters.allProducts[0])
  )
    return "Error!";

  let categoryFilters = data.getFilters.allProducts[0];
  return <Sidebar {...props} categoryFilters={categoryFilters} />;
};

export default SidebarQueryContainer;
