import React from "react";
import { connect } from "react-redux";
import { isUndefined, isEmpty } from "../../../state/helper/functions";
import Brands from "./brands";
import ReactLoader from "../../../utils/loader";
import commonActions from "../../../state/redux/data/common/actions";

const BrandsReduxContainer = props => {
  if (isUndefined(props.brands) || isEmpty(props.brands))
    return <ReactLoader />;

  let brands = {};
  let { customer } = props;
  if (!isUndefined(props.brands[2])) {
    props.brands[2].data.category.children.map(child => {
      if (child.name.indexOf("Brands") > -1) {
        return (brands = child);
      }
      return [];
    });
  }

  if (isEmpty(brands)) return <ReactLoader />;

  return <Brands brands={brands} customer={customer} />;
};

const mapStateToProps = state => ({
  brands: state.queries.data,
  customer: state.customer.customer
});

const mapDispatchToProps = dispatch => {
  return {
    showModal: (identifier, show) => {
      dispatch(commonActions.showModal(identifier, show));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandsReduxContainer);
