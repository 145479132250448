import React from "react";
import { connect } from "react-redux";
import { filterActions } from "../../../state/redux/data/filters";
import { commonActions } from "../../../state/redux/data/common";
import SidebarQueryContainer from "./sidebar-query-container";

const SidebarReduxContainer = props => {
  return (
    <SidebarQueryContainer
      variables={props.variables}
      query={props.query}
      allCategories={props.allCategories}
      filters={props.filters}
      category={props.category}
      selected_category={props.category_id}
      addFilter={props.addFilter}
      removeFilter={props.removeFilter}
      clearFilters={props.clearFilters}
      filtered_category={props.filtered_category}
      locked={props.locked}
    />
  );
};

const mapStateToProps = state => ({
  filters: state.filters,
  filtered_category: state.filters.data,
  category: state.filters.category,
  locked: state.common.locked
});

const mapDispatchToProps = dispatch => {
  return {
    addFilter: filter => {
      dispatch(commonActions.lock());
      dispatch(filterActions.addFilter(filter));
    },
    removeFilter: filter => {
      dispatch(filterActions.removeFilter(filter));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarReduxContainer);
