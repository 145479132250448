import apolloClient from "../../../../graphql/apolloClient";

import commonActions from "../../common/actions";
import queryLoader from "../../../../graphql/queryLoader";
import exportListActions from "../actions";
import messagesActions from "../../messages/actions";

export default async (store, action) => {
  if (action.name === "") {
    store.dispatch(messagesActions.addMessage("Name is required", "danger"));
  }
  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("deleteExportList"),
      variables: { listId: action.listId }
    });

    if (data) {
      await store.dispatch(
        exportListActions.deleteExportListSuccess(action.listId)
      );
    }

    // store.dispatch(exportListActions.createExportListName(""));
    // store.dispatch(commonActions.unlock());
    store.dispatch(
      messagesActions.addMessage(data.removeExportList.message, "success")
    );
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
