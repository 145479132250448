import React from "react";
import Header from "./header";
import { Link } from "react-router-dom";
import ContentRouter from "./content";
import ScrollUpButton from "react-scroll-up-button";
import Footer from "./footer";
import "./styles/style.scss";
import { createTeleporter } from "react-teleporter";
import themeConfiguration from "./state/config/themeConfiguration";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faSearch,
  faBars,
  faCaretRight,
  faTimes,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faArrowDown,
  faArrowUp,
  faTimesCircle,
  faCheckCircle,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

import Modal from "./utils/modal";

import Login from "./content/forms/login";
import { Spinner } from "react-redux-spinner";
import SplashScreen from "./utils/splashScreen";
import CacheBuster from "./state/redux/cacheBuster";
import AddToHomescreen from 'react-add-to-homescreen';
library.add(
  fab,
  faSearch,
  faChevronRight,
  faChevronLeft,
  faBars,
  faCaretRight,
  faTimes,
  faArrowRight,
  faArrowDown,
  faArrowUp,
  faTimesCircle,
  faCheckCircle,
  faTrash
);
const handleAddToHomescreenClick = () => {
  alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`);
};
const Body = props => {
  let backToTop = "../../images/arrowTop.png";
  let Messages = createTeleporter();
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <>
            {/* <Spinner /> */}
            <SplashScreen {...props}>
              <Header messages={Messages} />
              <Modal
                show={false}
                title={"Welcome to " + themeConfiguration.name}
                subHeading={"Log in to view products"}
                dismissable={false}
                modalId={"loginModal"}
                stateIdentifier={"loginPopup"}
                footer={"Don't have an account?"}
                footerCTA={
                  <Link to={"/register"} onClick={props.closeAllModals}>
                    Register Now
                  </Link>
                }
                changeOnCustomerLogin={true}
                customerRender={
                  <>
                    <div className={"modal-heading"}>
                      <h2>{"Welcome to " + themeConfiguration.name}</h2>
                    </div>
                    <p className={"modal-success-message"}>
                      You have successfully logged in.
                    </p>
                  </>
                }
              >
                <Login key={"loginModal"} {...props} />
              </Modal>
              <Messages.Target />
              <div id={"content-container"} className={"main-content"}>
                <ContentRouter {...props} />
              </div>
              <Footer />
              <ScrollUpButton
                ContainerClassName="back-to-top-button"
                ShowAtPosition={1200}
                style={{
                  backgroundColor: "transparent",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: "url(" + backToTop + ")",
                  height: "70px",
                  outline: "none",
                  bottom: "60px"
                }}
              ></ScrollUpButton>
            </SplashScreen>
	<AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />
          </>
        );
      }}
    </CacheBuster>
  );
};

export default Body;
