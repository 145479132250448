import React, { useEffect, useState } from "react";
import Loadable from "react-loadable";
import HeaderTop from "./top";
import HeaderBottom from "./bottom";
import HeaderRegularMenu from "./regular-menu";
import { menuItemsContent } from "./data";
import MessagesContainer from "../utils/messages/messages-redux-container";
import "./header.scss";
import ReactLoader from "../utils/loader";

const LoadableHeaderMobileMenu = Loadable({
  loader: () => import("./mobile-menu"),
  loading() {
    return "";
  }
});

const Header = props => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let items = menuItemsContent;
  let Messages = props.messages;
  // let windowWidth = 0;

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, true);
    window.addEventListener("load", handleResize, true);
    return () => {
      window.removeEventListener("scroll", handleResize, true);
      window.removeEventListener("load", handleResize, true);
    };
  });

  return (
    <>
      <HeaderTop phonenumber="(646) 791-7165" {...props} />
      {windowWidth < 769 && (
        <LoadableHeaderMobileMenu
          data={props.data}
          accountItems={items.account}
          menuItems={items.menuItems}
          activeTab={props.activeTab}
          updateActiveTab={props.updateActiveTab}
          mobileMenuActive={props.mobileActive}
          toggleMobileMenu={props.toggleMobileMenu}
          activePanel={props.activePanel}
          setActivePanel={props.setActivePanel}
          panels={props.panels}
          showModal={props.showModal}
        />
      )}
      <Messages.Source>
        <div
          id="session-messages-container"
          className={`${
            window.location.pathname === "/" ||
            window.location.pathname === "/brands" ||
            window.location.pathname === "/services" ||
            window.location.pathname === "/about-us" ||
            window.location.pathname === "/contact" ||
            window.location.pathname === "/forgot-password" ||
            window.location.pathname === "/register/success"
              ? "session-message-home"
              : ""
          }`}
        >
          <MessagesContainer target={"global"} />
        </div>
      </Messages.Source>

      <div
        onMouseLeave={() => {
          props.setActiveItem(0);
        }}
        className="header"
        id="bottom-header"
      >
        <HeaderBottom
          mobileMenuActive={props.mobileMenuActive}
          toggleMobileMenu={props.toggleMobileMenu}
          onMouseEnter={props.setActiveItem}
          menuItems={items.menuItems}
          customer={props.customer}
	  hideMenu={props.hideMenu}
        />

        {items.menuItems.map(item => {
          return (
            <HeaderRegularMenu
              data={props.data}
              mobileMenuActive={props.mobileMenuActive}
              onMouseLeave={props.hideMenu}
              key={item.id}
              item={item}
              brand={false}
              customer={props.customer}
              showModal={props.showModal}
              setActiveItem={props.setActiveItem}
	      hideMenu={props.hideMenu}
            />
          );
        })}
      </div>
    </>
  );
};

export default Header;
