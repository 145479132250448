import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import customerActions from "../../../state/redux/data/customer/actions";
import Form from "../../../utils/forms";
import Input from "../../../utils/input";
import Button from "../../../utils/buttons";
import Messages from "../../../utils/messages";
import ReactLoader from "../../../utils/loader";
import "./login.scss";

function submitHandle(event) {
  event.preventDefault();
}

const Login = props => {
  let { loading, showModal } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = e => {
    e.preventDefault();
    props.onSubmit(email, password, () => {
      if (props.onSubmitCallback) {
        props.onSubmitCallback();
      }
    });
  };

  return (
    <div>
      <div id="session-messages-container" className={""}>
        <Messages target={"login"} />
      </div>
      {!loading && (
        <Form formId={"login-form"} onSubmit={submitHandle}>
          <Input
            name={"email"}
            type={"text"}
            className={""}
            value={email}
            onChange={event => setEmail(event.target.value)}
            required={true}
            icon="email"
            placeholder="Email"
          />
          <Input
            name={"password"}
            type={"password"}
            className={""}
            value={password}
            onChange={event => setPassword(event.target.value)}
            required={true}
            icon="lock"
            placeholder="Password"
          />
          <div className="login-btn">
            <Button
              className="button btn-orange"
              onClick={e => {
                onSubmit(e);
              }}
              text={"Login"}
            />
            <Link
              className={"forgot-pw"}
              to={"/forgot-password"}
              onClick={e => {
                showModal("loginModal", false);
              }}
            >
              Forgot Password
            </Link>
          </div>
        </Form>
      )}
      {loading && <ReactLoader />}
    </div>
  );
};
const mapState = state => {
  return state;
};
const actionCreators = {
  login: customerActions.login,
  logout: customerActions.logout
};

export default connect(mapState, actionCreators)(Login);
