import apolloClient from "../../../../graphql/apolloClient";
import commonActions from "../../common/actions";
import queryLoader from "../../../../graphql/queryLoader";
import exportListActions from "../actions";
import messagesActions from "../../messages/actions";

export default async (store, action) => {
  try {
    if (action.name === "") {
      throw Error("Name is required");
    }
    if (!action.listId) {
      throw Error("No list id supplied.");
    }

    const { data } = await apolloClient.mutate({
      mutation: queryLoader("changeExportListName"),
      variables: { listId: parseInt(action.listId), name: action.name }
    });

    if (data) {
      store.dispatch(exportListActions.renameExportListSuccess(data));
      store.dispatch(commonActions.unlock());
      store.dispatch(
        messagesActions.addMessage(
          "Successfully created export list with name " + action.name,
          "success"
        )
      );
    }
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
