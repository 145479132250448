// Action types
const LOGIN_CUSTOMER = "jola/customer/LOGIN_CUSTOMER";
const LOGOUT_CUSTOMER = "jola/customer/LOGOUT_CUSTOMER";
const SET_CUSTOMER_TOKEN = "jola/customer/SET_CUSTOMER_TOKEN";
const SET_CUSTOMER_TOKEN_REGISTRATION =
  "jola/customer/SET_CUSTOMER_TOKEN_REGISTRATION";
const LOCK = "jola/customer/LOCK";
const UNLOCK = "jola/customer/UNLOCK";
const GET_CUSTOMER_INFORMATION = "jola/customer/GET_CUSTOMER_INFORMATION";
const SET_CUSTOMER_INFORMATION = "jola/customer/SET_CUSTOMER_INFORMATION";
const CLEAR_CUSTOMER_INFORMATION = "jola/customer/CLEAR_CUSTOMER_INFORMATION";
const LOGIN_CUSTOMER_FINISH = "jola/customer/LOGIN_CUSTOMER_FINISH";
const REGISTER_CUSTOMER = "jola/customer/REGISTER_CUSTOMER";
const REGISTER_CUSTOMER_SUCCESS = "jola/customer/REGISTER_CUSTOMER_SUCCESS";
const UPDATE_CUSTOMER_INFORMATION = "jola/customer/UPDATE_CUSTOMER_INFORMATION";
const FORGOT_CUSTOMER_PASSWORD = "jola/customer/FORGOT_CUSTOMER_PASSWORD";
const RESET_CUSTOMER_PASSWORD = "jola/customer/RESET_PASSWORD";
const RESET_CUSTOMER_PASSWORD_SUCCESS = "jola/customer/RESET_PASSWORD_SUCCESS";
const EDIT_CUSTOMER_ADDRESS = "jola/customer/EDIT_CUSTOMER_ADDRESS";
const _REDUCE_CUSTOMER = "jola/customer/_REDUCE_CUSTOMER";
const _REDUCE_CUSTOMER_ADDRESS = "jola/customer/_REDUCE_CUSTOMER_ADDRESS";
const _VALIDATE_CUSTOMER_TOKEN = "jola/customer/_VALIDATE_CUSTOMER_TOKEN";

export default {
  LOGIN_CUSTOMER,
  LOGOUT_CUSTOMER,
  SET_CUSTOMER_TOKEN,
  SET_CUSTOMER_TOKEN_REGISTRATION,
  LOCK,
  UNLOCK,
  GET_CUSTOMER_INFORMATION,
  SET_CUSTOMER_INFORMATION,
  CLEAR_CUSTOMER_INFORMATION,
  LOGIN_CUSTOMER_FINISH,
  REGISTER_CUSTOMER,
  REGISTER_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_INFORMATION,
  FORGOT_CUSTOMER_PASSWORD,
  RESET_CUSTOMER_PASSWORD,
  RESET_CUSTOMER_PASSWORD_SUCCESS,
  EDIT_CUSTOMER_ADDRESS,
  _REDUCE_CUSTOMER,
  _REDUCE_CUSTOMER_ADDRESS,
  _VALIDATE_CUSTOMER_TOKEN
};
