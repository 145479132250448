import React from "react";
import { connect } from "react-redux";
// import HeaderTopState from "./header-state-container";
import SearchInput from "./searchInput";
import { searchActions } from "../../../../state/redux/data/search";
import { CSSTransition } from "react-transition-group";
import { isUndefined } from "../../../../state/helper/functions";
import commonActions from "../../../../state/redux/data/common/actions";

const SearchInputReduxContainer = props => {
  let show = props.show;
  let toggleSearch = props.toggleSearch;
  if (isUndefined(show)) {
    show = false;
  }

  let loggedIn = false;

  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }

  return (
    <CSSTransition
      key={"search-input"}
      in={show}
      classNames={"slide"}
      appear={show}
      timeout={0}
    >
      <SearchInput
        name={"text"}
        type={"type"}
        placeholder={"Search Products"}
        changeSearch={props.changeSearch}
        loggedIn={loggedIn}
        show={show}
        toggleSearch={toggleSearch}
      />
    </CSSTransition>
  );
};
const mapStateToProps = state => ({
  show: state.search.show,
  search: state.search,
  customer: state.customer.customer
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    showModal: (identifier, show) => {
      dispatch(commonActions.showModal(identifier, show));
    },
    changeSearch: search => {
      if (search.length > 0) dispatch(searchActions.changeName(search));
    },
    toggleSearch: isShown => {
      dispatch(searchActions.toggleSearch(isShown));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchInputReduxContainer);
