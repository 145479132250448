// Action Creators
import types from "./types";
import { default as graphQlConfig } from "../../../config/graphQlConfig";

const addQuery = (
  query,
  variables = [],
  queryType = graphQlConfig.types.query
) => {
  return {
    type: types.ADD_QUERY,
    query: query,
    variables: variables,
    queryType: queryType
  };
};

const addQueryData = (data, identifier, key) => {
  return {
    type: types.ADD_QUERY_DATA,
    data: data,
    identifier: identifier,
    key: key
  };
};

const addQueryDataSuccess = () => {
  return {
    type: types.ADD_QUERY_DATA_SUCCESS
  };
};

const removeQuery = (query, variables = []) => {
  return {
    type: types.REMOVE_QUERY
  };
};

const removeSpecificQuery = (query, variables = []) => {
  return {
    type: types.REMOVE_QUERY,
    query_identifier: query
  };
};

const addQuerySuccess = (data, id) => {
  return {
    type: types.ADD_QUERY_SUCCESS,
    data: data,
    id: id
  };
};

const removeAllQueries = () => {
  return {
    type: types.REMOVE_ALL_QUERIES
  };
};

const hideSplash = () => {
  return {
    type: types.HIDE_SPLASH
  };
};

export default {
  addQuery,
  addQuerySuccess,
  addQueryData,
  addQueryDataSuccess,
  removeQuery,
  removeAllQueries,
  removeSpecificQuery,
  hideSplash
};
