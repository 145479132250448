import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Customer from "./customer";
import { isUndefined } from "../state/helper/functions";

const CustomerReduxContainer = props => {
  if (isUndefined(props.customer) || !props.customer.token)
    return <Redirect to={"/"} />;
  return <Customer {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer
});

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerReduxContainer);
