import React from "react";
import Usp from "../../home/services/usp";
import "./ourServicesCoreTab.scss";

const OurServicesCoreTabs = props => {
  return (
    <div
      className={`our-services-core-tabs-item ${props.itemClass}`}
      onClick={props.onClick}
    >
      <Usp i={props.icon} title={props.title} />
    </div>
  );
};
export default OurServicesCoreTabs;
