export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function isUndefined(variable) {
  if (typeof variable === "undefined") return true;
  return false;
}

export function isObject(element) {
  return typeof element === "object" ? true : false;
}

export function isFunction(variable) {
  if (typeof variable === "function") return true;
  return false;
}

export function onlyNumbers(event) {
  if (
    (event.which !== 8 && event.which !== 0 && event.which < 48) ||
    event.which > 57
  ) {
    event.preventDefault();
  }
}
