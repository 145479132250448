import throttle from "lodash.throttle";
import {
  getCommonState,
  getCustomerState,
  getMessagesState,
  getQueriesState,
  getExportListState
} from "./initialStates";
import { saveState } from "./localStorage";

export function throttleIt(store) {
  store.subscribe(
    throttle(() => {
      let storeState = store.getState();
      let allStates = {
        customerState: getCustomerState(storeState),
        commonState: getCommonState(storeState),
        messagesState: getMessagesState(storeState),
        queriesState: getQueriesState(storeState),
        exportListState: getExportListState(storeState)
      };
      saveState({
        customer: allStates.customerState,
        common: allStates.commonState,
        messages: allStates.messagesState,
        queries: allStates.queriesState,
        exportList: allStates.exportListState
      });
    }, 1000)
  );
}
