import React from "react";
import Listing from "./listing";
import Toolbar from "./toolbar";

const CustomerExportList = props => {
  return (
    <div className={"content"}>
      <h1>My Programs</h1>
      <Toolbar
        {...props}
        project={props.project}
        selectedProject={props.selectedProject}
        projectName={props.projectName}
        setProjectName={props.setProjectName}
        projectsList={props.projectsList}
        changeProjectHandler={props.changeProjectHandler}
        createProjectHandler={props.createProjectHandler}
        deleteById={props.deleteById}
        changeProjectInformation={props.changeProjectInformation}
        setId={props.setId}
      />
      <Listing
        {...props}
        project={props.project}
        selectedProject={props.selectedProject}
      />
    </div>
  );
};

export default CustomerExportList;
