// customer attributes
export default {
  customer: {
    id: "id",
    email: "email",
    firstname: "firstname",
    lastname: "lastname",
    password: "password",
    dob: "dob",
    tax: "tax",
    gender: "gender",
    addresses: "addresses",
    accounting_email: "accounting_email",
    contact_name: "contact_name",
    ups_fedex_account_number: "ups_fedex_account_number",
    zip_code_ups_fedex_account: "zip_code_ups_fedex_account",
    website: "website",
    direct_office_number: "direct_office_number",
    mobile_phone_number: "mobile_phone_number",
    preferred_invoice_payment: [
      "ACH",
      "CHECK",
      "CREDIT CARD (3% FEE)",
      "WIRE TRANSFER"
    ]
  }
};
