import React from "react";
import "./productInfoRightTop.scss";
import PriceBox from "./priceBox";
import Image from "../../../../utils/image";
import HtmlParser from "../../../../utils/htmlParser";
import {
  getBrandImageByCategoryId,
  createCategoryImageUrl
} from "../../../../state/helper/category";
import { isUndefined } from "../../../../state/helper/functions";
const ProductInfoRightTop = props => {
  let data = props;
  let brandImage = getBrandImageByCategoryId(
    data.categories,
    props.allCategories
  ).filter(item => {
    return item.path.split("/").length === 5;
  })[0];
  return (
    <div id="productinforighttop">
      <div className={"product-title"}>
        <h2>{data.name}</h2>
        <p>{data.sku}</p>
      </div>
      <div className={`stock-info-and-brand`}>
        <div className={"stock-info"}>
          <div className={"stock-info-inner"}>
            {/* {data.master_pack_qty && (
              <div className={"stock-info-row"}>
                <div className="product-item-stock">
                  <p>
                    Stock <span>{data.master_pack_qty}</span>
                  </p>
                </div>
                {data.last_updated_date && (
                  <div className="product-item-date">
                    <p className="product-item-date-inner">
                      <span>{"Updated | " + data.last_updated_date}</span>
                    </p>
                  </div>
                )}
              </div>
            )} */}
            {/* {data.next_arrival_date && (
              <div className={"stock-info-row"}>
                <p>
                  Next avail <span>{data.next_arrival_date}</span>
                </p>
              </div>
            )}
            {data.master_pack_qty && (
              <div className={"stock-info-row"}>
                <p>
                  Next avail.qty <span>{data.master_pack_weight}</span>
                </p>
              </div>
            )} */}
          </div>
        </div>
        <div className={`brand-image`}>
          {!isUndefined(brandImage) &&
            !isUndefined(brandImage.thumbnail_image) && (
              <div className={"brand"}>
                <Image
                  source={createCategoryImageUrl(brandImage.thumbnail_image)}
                  image_alt={brandImage.name}
                />
              </div>
            )}
        </div>
      </div>
      <div className={"short-description"}>
        <HtmlParser data={data.description.html} />
      </div>
      <div className={"price-box"}>
        <PriceBox {...data} />
      </div>
    </div>
  );
};

export default ProductInfoRightTop;
