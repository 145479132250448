import React from "react";
import ImageContainer from "./imageContainer";

const Image = props => {
  let { source, style, image_alt } = props;
  source = source.includes("http") ? source : "../images/" + source;

  return (
    <ImageContainer {...props}>
      <img
        className={props.className}
        src={source}
        style={style}
        alt={image_alt}
        loading={"lazy"}
      />
    </ImageContainer>
  );
};

export default Image;
