import React from "react";
import { connect } from "react-redux";
import customerActions from "../../../../state/redux/data/customer/actions";
// import { default as customerActionTypes } from "../../../../state/redux/data/customer/types";
import { commonActions } from "../../../../state/redux/data/common";
import CustomerInformationFormStateContainer from "./information-state-container";
// import { pendingTask, begin } from "react-redux-spinner";

// const LOGIN_CUSTOMER = customerActionTypes.LOGIN_CUSTOMER;

const CustomerLoginFormReduxContainer = props => {
  if (typeof props.customer !== "undefined") {
    if ("token" in props.customer) {
      if (!props.customer.token) {
        return "Redirect to homepage.";
      }
    }
  }

  return <CustomerInformationFormStateContainer {...props} />;
};

const mapStateToProps = state => ({
  customer: state.customer.customer,
  loading: state.customer.loading,
  locked: state.common.locked
});

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: request => {
      dispatch(commonActions.lock()).then(
        dispatch(customerActions.updateCustomerInformation(request))
      );
    },
    onSubmitCallback: () => {},
    unLock: () => {
      dispatch(commonActions.unlock());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerLoginFormReduxContainer);
