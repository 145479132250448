import React from "react";
import { Link } from "react-router-dom";

const FooterBottom = props => {
  return (
    <div id={props.id} className={props.className}>
      <div>
        <div>© 2019 by H Global Inc. All Rights Reserved</div>
        <div>
          <Link to={"/privacy-policy"}>PRIVACY POLICY</Link>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
