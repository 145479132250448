import React from "react";
import "./productInfoContainer.scss";
import ProductInfoRight from "./productInfoRight";
import ProductInfoLeft from "./productInfoLeft";
const ProductInfoContainer = props => {
  return (
    <div id="productinfocontainer">
      <ProductInfoLeft {...props} />
      <ProductInfoRight {...props} />
    </div>
  );
};

export default ProductInfoContainer;
