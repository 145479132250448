import React from "react";
import "./map.scss";
import CmsBlock from "../cmsBlock";

const Map = props => {
  return (
    <div className={`map`}>
      <CmsBlock dangHtml={true} id="home-map" />
    </div>
  );
};

export default Map;
