import React from "react";
import "./productInfoLeftLinks.scss";
import { default as themeConfiguration } from "../../../../state/config/themeConfiguration";

const ProductInfoLeftLinks = props => {
  if (!props.images) {
    return "";
  }

  const product_url =
    themeConfiguration.magento_url +
    themeConfiguration.media_url +
    themeConfiguration.catalog_url;
  return (
    <div id="productinfoleftlinks">
      <h4> Downloads: </h4>
      {props.images[2] && (
        <div className={"bottom-links sheet"}>
          <a
            href={product_url + props.images[2].file}
            target="_blank"
            rel="noopener norefferer"
          >
            SELL SHEETS
          </a>
        </div>
      )}
      {props.images[0] && (
        <div className={"bottom-links package"}>
          <a
            href={product_url + props.images[0].file}
            target="_blank"
            rel="noopener norefferer"
          >
            PACKAGING IMAGE
          </a>
        </div>
      )}
      {props.images[1] && (
        <div className={"bottom-links image"}>
          <a
            href={product_url + props.images[1].file}
            target="_blank"
            rel="noopener norefferer"
          >
            IMAGE
          </a>
        </div>
      )}
      {/* <div className={"bottom-links detail"}>
        <Link to={"/register"}>PRODUCT DETAILS</Link>
      </div> */}
    </div>
  );
};

export default ProductInfoLeftLinks;
