import apolloClient from "../../../../graphql/apolloClient";
import messagesActions from "../../messages/actions";
import commonActions from "../../common/actions";
import queryLoader from "../../../../graphql/queryLoader";
import { customerActions } from "..";

export default async (store, action) => {
  let token = action.token;
  try {
    const { data } = await apolloClient
      .mutate({
        mutation: queryLoader("validateCustomerToken"),
        variables: { token: token }
      })
      .then();
    if (!data.validateCustomerToken) {
      store.dispatch(
        customerActions.logoutCustomer(false, "Your session has expired.")
      );
    }
    return data;
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
