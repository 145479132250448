import React from "react";
import Button from "../buttons";
import ContentHeader from "../contentHeader";
import "./callToAction.scss";

const CallToAction = props => {
  return (
    <div className="gray-box" style={props.backgroundImage}>
      <div className="gray-box-inner">
        <ContentHeader heading={props.title} subHeading={props.subtitle} />
        <Button
          className={
            "button btn-with-right-icon btn-white-border-shadow right-arrow"
          }
          text={props.buttonText}
          icon={"rightArrowOrange"}
          to={props.url}
        />
      </div>
    </div>
  );
};

export default CallToAction;
