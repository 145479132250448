import React from "react";
import Input from "../input";
import { isEmpty } from "../../state/helper/functions";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { Track, Handle, Tick } from "./functions";
import "./compoundSlider.scss";

const CompoundSlider = props => {
  let CurrentValues = props.currentValues;

  if (isEmpty(CurrentValues)) {
    if (!isNaN(props.min) || props.min !== null) {
      props.setCurrentValues({ min: props.min, max: props.max });
      return "";
    }
  }
  if (CurrentValues.min === null || CurrentValues.max === null) return "";
  if (CurrentValues === {}) return;

  return (
    <>
      <Slider
        className={`compound-slider`}
        domain={[props.min, props.max]}
        step={props.step}
        mode={2}
        values={[CurrentValues.min, CurrentValues.max]}
        onChange={e => {
          let minValue = e[0];
          let maxValue = e[1];
          props.valueChangeHandler(
            { min: minValue, max: maxValue },
            props.addFilter
          );
          props.setCurrentValues({ min: minValue, max: maxValue });
        }}
      >
        <Rail>
          {({ getRailProps }) => <div className={`rail`} {...getRailProps()} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  getHandleProps={getHandleProps}
                  useDecimal={props.useDecimal}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        {props.useTicks && (
          <Ticks
            count={
              props.ticksCount /* generate approximately props.ticksCount ticks within the domain */
            }
          >
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map(tick => (
                  <Tick key={tick.id} tick={tick} count={ticks.length} />
                ))}
              </div>
            )}
          </Ticks>
        )}
      </Slider>
      {props.useInputs && (
        <div className="rangefield-content">
          <Input
            id={props.minId}
            name={props.minId}
            type={"number"}
            wrapperClass={"range-field-wrapper"}
            className={"range-field"}
            inputClass={`range-slider range-slider-min range-slider-${props.minId}`}
            value={CurrentValues.min}
            onChange={e => {
              let thisValue = e.target.value;
              if (
                isNaN(thisValue) ||
                thisValue === "" ||
                thisValue < CurrentValues.min
              ) {
                thisValue = props.min;
              }
              props.setCurrentValues({
                min: parseFloat(thisValue),
                max: parseFloat(CurrentValues.max)
              });
              props.valueChangeHandler(
                { min: thisValue, max: CurrentValues.min },
                props.addFilter
              );
            }}
          />
          <span>To</span>
          <Input
            id={props.maxId}
            name={props.maxId}
            type={"number"}
            wrapperClass={"range-field-wrapper"}
            className={"range-field"}
            inputClass={`range-slider range-slider-min range-slider-${props.minId}`}
            value={CurrentValues.max}
            onChange={e => {
              let thisValue = e.target.value;
              if (
                isNaN(thisValue) ||
                thisValue === "" ||
                thisValue < CurrentValues.min
              ) {
                thisValue = CurrentValues.min;
              }

              props.setCurrentValues({
                min: parseFloat(CurrentValues.min),
                max: parseFloat(thisValue)
              });
              props.valueChangeHandler(
                { min: CurrentValues.min, max: thisValue },
                props.addFilter
              );
            }}
          />
        </div>
      )}
    </>
  );
};

export default CompoundSlider;
