import { isUndefined } from "../../../../helper/functions";
export default (state, action) => {
  let token =
    isUndefined(state) ||
    isUndefined(state.customer) ||
    isUndefined(state.customer.token)
      ? action.token
      : state.customer.token;
  return Object.assign({}, state, {
    customer: { token: token, email: action.email },
    loading: true
  });
};
