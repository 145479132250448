import apolloClient from "../../../../graphql/apolloClient";
import customerActions from "../actions";
import queryLoader from "../../../../graphql/queryLoader";
import messagesActions from "../../messages/actions";
import commonActions from "../../common/actions";
import { isUndefined } from "../../../../helper/functions";

const UPDATE_CUSTOMER_INFORMATION = "updateCustomerInformation";
const CHANGE_CUSTOMER_PASSWORD = "changeCustomerPassword";

export default async (store, action) => {
  try {
    if (!action.request) {
      store.dispatch(
        messagesActions.addMessage("No request was recieved.", "danger")
      );
      store.dispatch(commonActions.unlock());
      return;
    }

    try {
      let request = action.request;
      let changePassword;

      if (!isUndefined(request.newPassword)) {
        changePassword = {
          currentPassword: request.currentPassword,
          newPassword: request.newPassword
        };
      }

      if (!isUndefined(changePassword)) {
        delete request.newPassword;
      }
      if (!isUndefined(request.email)) {
        delete request.currentPassword;
      } else {
        delete request.currentPassword;
      }

      const { data } = await apolloClient.mutate({
        mutation: queryLoader(UPDATE_CUSTOMER_INFORMATION),
        variables: { customer: request }
      });

      if (
        !isUndefined(changePassword) &&
        changePassword.newPassword !== "" &&
        changePassword.currentPassword !== ""
      ) {
        const { newPassword } = await apolloClient.mutate({
          mutation: queryLoader(CHANGE_CUSTOMER_PASSWORD),
          variables: changePassword
        });
      }

      if (data) {
        let token = store.getState().customer.customer.token;
        await store
          .dispatch(
            customerActions.getCustomerInformation(token, true, request.email)
          )
          .then(
            store.dispatch(
              messagesActions.addMessage(
                "Successfully updated account information.",
                "success"
              )
            )
          );
      }
    } catch (error) {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
      store.dispatch(commonActions.unlock());
    }
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
