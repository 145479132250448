import React, { useState } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Input from "../../../utils/input";
import CheckButton from "../../../utils/checkButton";
import CustomerAttributes from "../../../state/config/customer/attributes";
import { findMaskByCountry } from "./functions";
import Messages from "../../../utils/messages";

import "./register.scss";
import Form from "../../../utils/forms";
import {
  getCountries,
  getPrefferedPaymentMethod,
  getRegionData
} from "../../../state/config/data/dataMapping";
import ValidationWrapper from "../../../utils/validationWrapper";
import { isUndefined, isEmpty } from "../../../state/helper/functions";
import Button from "../../../utils/buttons";
import { CSSTransition } from "react-transition-group";
import themeConfiguration from "../../../state/config/themeConfiguration";
import { Helmet } from "react-helmet";
import Select2 from "../../../utils/select2";
import MaskedInput from "react-text-mask";
import { getRecaptchaByLiveDev } from "./recaptcha";
import ReCAPTCHA from "react-google-recaptcha";

let customerAttributes = CustomerAttributes.customer;

let paymentMethods = [];
let countriesData = [];
const Register = props => {
  let countries = props.countries;

  let recaptchaKey = getRecaptchaByLiveDev();
  const [validator, setValidator] = useState(
    new SimpleReactValidator({
      locale: "en",
      messages: {
        email: "Please enter a valid email address",
        confirmPassword: "Password & Confirm Password do not match."
      },
      validators: {
        match: {
          message: "Confirm password needs to match password.",
          rule: (val, params, validator) => params.includes(val),
          messageReplace: (message, params) =>
            message.replace(":values", validator.helpers.toSentence(params))
        },
        phone: {
          message: "The office number must be a valid phone number.",
          rule: val => {
            if (val.split("").length > 12) return true;
            return false;
          },
          messageReplace: (message, params) =>
            message.replace(":values", validator.helpers.toSentence(params))
        }
      },
      autoForceUpdate: true
    })
  );
  const [secondStepValidator] = useState(
    new SimpleReactValidator({
      locale: "en",
      messages: {},
      validators: {},
      autoForceUpdate: true
    })
  );
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [officeNumber, setOfficeNumber] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [countrySelect, setCountrySelect] = useState({
    label: "United States",
    value: "US"
  });
  const [state, setState] = useState("");
  const [stateTextValue, setStateTextValue] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountZip, setAccountZip] = useState("");
  const [payment, setPayment] = useState("");
  const [contactName, setContactName] = useState("");
  const [accountEmail, setAccountEmail] = useState("");
  const [validate, setValidate] = useState(false);
  const [validate2, setValidate2] = useState(false);
  const [regionsData, setRegionsData] = useState(props.regionsDataObject);
  const [secondStepValidation, setSecondStepValidation] = useState(true);
  const [mask, setMask] = useState(findMaskByCountry("US"));
  const [maskSanitizedArray, setMaskSanitizedArray] = useState([]);

  let maskSanitized = mask.mask.split("");

  const recaptchaRef = React.createRef();
  if (isEmpty(maskSanitizedArray)) {
    setMaskSanitizedArray(
      Object.keys(maskSanitized).map(key => {
        let regexChar = maskSanitized[key];
        if (maskSanitized[key] === "+") regexChar = "+";
        if (maskSanitized[key] === "#") regexChar = /\d/;
        return regexChar;
      })
    );
  }

  let countryDataObject = getCountries(countries);
  countriesData = Object.keys(countryDataObject).map(key => {
    return countryDataObject[key];
  });
  paymentMethods = getPrefferedPaymentMethod(
    customerAttributes.preferred_invoice_payment
  );

  const setCountrySelectHandler = event => {
    let value = event;
    let mask = findMaskByCountry(value.value);
    setCountrySelect(value);
    updateRegion(value);
    setMask(mask);
    let maskSanitized = mask.mask.split("");
    let array = setMaskSanitizedArray(
      Object.keys(maskSanitized).map(key => {
        let regexChar = maskSanitized[key];
        if (maskSanitized[key] === "+") regexChar = "+";
        if (maskSanitized[key] === "#") regexChar = /\d/;
        return regexChar;
      })
    );
  };

  const updateRegion = value => {
    let data = getRegionData(countriesData, value);
    setRegionsData(data);
  };

  const setPaymentHandle = event => {
    let value = event;
    setPayment(value);
  };

  const validateFirstStep = event => {
    event.preventDefault();
    if (validator.allValid()) {
      setSecondStepValidation(false);
      props.setSecondStep(true);
      props.setFirstStep(false);
    } else {
      setValidate(true);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    let regionObject = {};

    if (!isUndefined(regionsData[state.value])) {
      regionObject = {
        region_code: "",
        region: "",
        region_id: parseInt(state.value)
      };
    } else {
      regionObject = {
        region_code: "",
        region: stateTextValue,
        region_id: 0
      };
    }
    let firstStepValidation = validator.allValid();
    let secondStepValidation = secondStepValidator.allValid();

    if (firstStepValidation && secondStepValidation) {
      let customerRequest = {
        [customerAttributes.firstname]: firstname,
        [customerAttributes.lastname]: lastname,
        [customerAttributes.password]: password,
        [customerAttributes.email]: email,
        [customerAttributes.accounting_email]: accountEmail,
        [customerAttributes.contact_name]: contactName,
        [customerAttributes.ups_fedex_account_number]: accountNumber,
        [customerAttributes.zip_code_ups_fedex_account]: accountZip,
        preferred_invoice_payment: Number(payment.value)
      };
      let addressRequest = {
        city: city,
        company: company,
        country_id: countrySelect.value,
        default_billing: true,
        default_shipping: true,
        fax: "",
        firstname: firstname,
        lastname: lastname,
        postcode: zip,
        region: regionObject,
        street: [address1, address2],
        telephone: officeNumber,
        [customerAttributes.website]: website,
        [customerAttributes.direct_office_number]: officeNumber,
        [customerAttributes.mobile_phone_number]: mobile
      };

      props.onSubmit(customerRequest, addressRequest);
    } else {
      if (!firstStepValidation) setValidate(true);
      if (firstStepValidation && !secondStepValidation) setValidate2(true);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Registration - {themeConfiguration.name}</title>
        <link
          rel="canonical"
          href={`${themeConfiguration.app_url}/registration`}
        />
      </Helmet>
      <Form
        formId={"registration-form"}
        onSubmit={handleSubmit}
        allowCompletion={"disable"}
      >
        <section className="legend">
          <h1>Register</h1>
          <h3>Welcome to the new Brandation site</h3>
          <p>To Register, complete the form below.</p>
        </section>
        <CSSTransition
          key={"first-step-registration"}
          timeout={{
            appear: 1,
            enter: 1,
            exit: 500
          }}
          classNames={"slide"}
          in={props.firstStep}
        >
          <section className={`section-item first`}>
            <div className="section-title">
              <div className="number">1</div>
              <h4>Personal Information</h4>
            </div>
            <div className="inputs">
              <div className={"input-fields input-fields-left"}>
                <ValidationWrapper validator={validator} validate={validate}>
                  <Input
                    name={"firstname"}
                    type="text"
                    className={""}
                    placeholder="First name"
                    value={firstname}
                    onChange={event => setFirstname(event.target.value)}
                    required={true}
                    icon="user"
                    validation={validator.message(
                      "firstname",
                      firstname,
                      "required"
                    )}
                  />
                  <Input
                    name={"lastname"}
                    type={"text"}
                    className={""}
                    value={lastname}
                    onChange={event => setLastname(event.target.value)}
                    required={true}
                    icon="user"
                    placeholder="Last name"
                    validation={validator.message(
                      "lastname",
                      lastname,
                      "required"
                    )}
                  />
                </ValidationWrapper>

                <div className="officeNumberMask">
                  <MaskedInput
                    mask={maskSanitizedArray}
                    guide={false}
                    onBlur={() => {}}
                    name={"officeNumber"}
                    type={"text"}
                    className={"magicBox office"}
                    value={officeNumber}
                    onChange={event => {
                      setOfficeNumber(event.target.value);
                    }}
                    required={true}
                    icon="phone"
                    placeholder="Direct Office Number"
                    validation={validator.message(
                      "officeNumber",
                      officeNumber,
                      "required|phone"
                    )}
                  />
                  <ValidationWrapper validator={validator} validate={validate}>
                    <Input
                      name={"officeNumber"}
                      type={"text"}
                      className={""}
                      value={officeNumber}
                      onChange={event => setOfficeNumber(event.target.value)}
                      required={true}
                      icon="phone"
                      placeholder="Direct Office Number"
                      validation={validator.message(
                        "officeNumber",
                        officeNumber,
                        "required|phone"
                      )}
                      tabIndex={"-1"}
                    />
                  </ValidationWrapper>
                </div>
                <div className="officeNumberMask">
                  <MaskedInput
                    mask={maskSanitizedArray}
                    guide={false}
                    onBlur={() => {}}
                    name={"mobile"}
                    type={"text"}
                    className={"magicBox mobile"}
                    value={mobile}
                    onChange={event => setMobile(event.target.value)}
                    icon="cell"
                    placeholder="Mobile Phone Number"
                    validation={validator.message("mobile", mobile, "phone")}
                  />
                  <ValidationWrapper validator={validator} validate={validate}>
                    <Input
                      name={"mobile"}
                      type={"text"}
                      className={""}
                      value={mobile}
                      onChange={event => setMobile(event.target.value)}
                      icon="cell"
                      placeholder="Mobile Phone Number"
                      validation={validator.message("mobile", mobile, "phone")}
                      tabIndex={"-1"}
                    />
                  </ValidationWrapper>
                </div>
              </div>
              <div className={"input-fields input-fields-right"}>
                <ValidationWrapper validator={validator} validate={validate}>
                  <Input
                    name={"email"}
                    type={"text"}
                    className={""}
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    required={true}
                    icon="email"
                    placeholder="Email"
                    validation={validator.message(
                      "email",
                      email,
                      "required|email"
                    )}
                  />
                  <Input
                    name={"password"}
                    type={"password"}
                    className={""}
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                    required={true}
                    icon="lock"
                    placeholder="Password"
                    validation={validator.message(
                      "password",
                      password,
                      "required|min:6,string"
                    )}
                    onBlur={e => {
                      validator.message(
                        "confirmpassword",
                        confirmPassword,
                        `required|min:6,string|match:${password}`
                      );
                    }}
                  />
                  <Input
                    name={"confirmPassword"}
                    type={"password"}
                    className={""}
                    value={confirmPassword}
                    onChange={event => setConfirmPassword(event.target.value)}
                    required={true}
                    icon="lock"
                    placeholder="Confirm Password"
                    validation={validator.message(
                      "confirmPassword",
                      confirmPassword,
                      `required|min:6,string|match:${password}`
                    )}
                  />
                </ValidationWrapper>
              </div>
            </div>
            <div className="section-control">
              <Button
                className={`button btn-orange`}
                text={"Next"}
                onClick={e => {
                  validateFirstStep(e);
                }}
              />
            </div>
          </section>
        </CSSTransition>
        <CSSTransition
          key={"second-step-registration"}
          appear={true}
          in={props.secondStep}
          timeout={{
            appear: 1,
            enter: 1,
            exit: 500
          }}
          classNames={"slide"}
        >
          <section className={`section-item second`}>
            <div className="section-title">
              <div className="number">2</div>
              <h4>Company Information</h4>
            </div>
            <div className="inputs">
              <div className={"input-fields input-fields-left"}>
                <ValidationWrapper
                  validator={secondStepValidator}
                  validate={validate2}
                >
                  <Input
                    disabled={secondStepValidation}
                    name={"company"}
                    type={"text"}
                    className={""}
                    placeholder="Company"
                    value={company}
                    onChange={event => setCompany(event.target.value)}
                    required={true}
                    icon="business"
                    validation={secondStepValidator.message(
                      "company",
                      company,
                      "required"
                    )}
                  />
                  <Input
                    disabled={secondStepValidation}
                    name={"website"}
                    type={"text"}
                    className={""}
                    value={website}
                    onChange={event => setWebsite(event.target.value)}
                    required={true}
                    icon="globe"
                    placeholder="Website"
                    validation={secondStepValidator.message(
                      "website",
                      website,
                      "required"
                    )}
                  />
                  <Input
                    disabled={secondStepValidation}
                    name={"address1"}
                    type={"text"}
                    wrapperClass={"partial"}
                    value={address1}
                    onChange={event => setAddress1(event.target.value)}
                    required={true}
                    icon="address"
                    placeholder="Shipping Address 1"
                    validation={secondStepValidator.message(
                      "address1",
                      address1,
                      "required"
                    )}
                  />
                  <Input
                    disabled={secondStepValidation}
                    name={"address2"}
                    type={"text"}
                    onChange={event => setAddress2(event.target.value)}
                    value={address2}
                    icon="address"
                    placeholder="Shipping Address 2"
                  />
                </ValidationWrapper>
                <div className="small-inputs">
                  {/* <Select2 data={countrySelect} /> */}
                  <Select2
                    disabled={secondStepValidation}
                    name={"country"}
                    type={"text"}
                    icon="address"
                    value={countrySelect}
                    onChange={event => setCountrySelectHandler(event)}
                    required={true}
                    wrapperClass="small"
                    placeholder="Country"
                    options={countriesData}
                    useCustom={true}
                    isSearchable={true}
                    // menuIsOpen={true}
                  />
                  {(!isEmpty(regionsData) && (
                    <Select2
                      disabled={secondStepValidation}
                      name={"state"}
                      type={"text"}
                      icon="address"
                      required={true}
                      value={state}
                      onChange={event => setState(event)}
                      wrapperClass="small"
                      placeholder="State"
                      options={regionsData}
                      isSearchable={true}
                    />
                  )) || (
                    <Input
                      name={"region_text"}
                      type={"text"}
                      wrapperClass={"small"}
                      required={true}
                      value={stateTextValue}
                      onChange={event => setStateTextValue(event.target.value)}
                      icon="address"
                      placeholder="State"
                    />
                  )}
                  <ValidationWrapper
                    validator={secondStepValidator}
                    validate={validate2}
                  >
                    <Input
                      disabled={secondStepValidation}
                      name={"city"}
                      type={"text"}
                      wrapperClass={"small"}
                      required={true}
                      value={city}
                      onChange={event => setCity(event.target.value)}
                      icon="address"
                      placeholder="City"
                      validation={secondStepValidator.message(
                        "city",
                        city,
                        "required|string"
                      )}
                    />
                    <Input
                      disabled={secondStepValidation}
                      name={"zip"}
                      type={"text"}
                      wrapperClass={"small"}
                      required={true}
                      value={zip}
                      onChange={event => setZip(event.target.value)}
                      icon="address"
                      placeholder="ZIP Code"
                      validation={secondStepValidator.message(
                        "zip",
                        zip,
                        "required|numeric"
                      )}
                    />
                  </ValidationWrapper>
                </div>
              </div>
              <div className={"input-fields input-fields-right"}>
                <ValidationWrapper
                  validator={secondStepValidator}
                  validate={validate2}
                >
                  <Input
                    disabled={secondStepValidation}
                    name={"accountNumber"}
                    type={"text"}
                    wrapperClass={"partial ups"}
                    required={false}
                    value={accountNumber}
                    onChange={event => setAccountNumber(event.target.value)}
                    icon="email"
                    placeholder="UPS/Fedex Account Number"
                  />
                  <Input
                    disabled={secondStepValidation}
                    name={"accountZip"}
                    type={"text"}
                    wrapperClass={"small"}
                    required={false}
                    value={accountZip}
                    onChange={event => setAccountZip(event.target.value)}
                    icon="address"
                    placeholder="Zip code associated with the UPS/Fedex account"
                  />
                </ValidationWrapper>
                <Select2
                  disabled={secondStepValidation}
                  name={"payment"}
                  type={"text"}
                  icon="cc"
                  required={false}
                  value={payment}
                  onChange={event => {
                    setPaymentHandle(event);
                  }}
                  wrapperClass="small preffered"
                  placeholder="Preffered Invoice Payment Method"
                  options={paymentMethods}
                  isSearchable={true}
                />
                <ValidationWrapper
                  validator={secondStepValidator}
                  validate={validate2}
                >
                  <Input
                    disabled={secondStepValidation}
                    name={"contactName"}
                    type={"text"}
                    required={false}
                    value={contactName}
                    onChange={event => setContactName(event.target.value)}
                    icon="user"
                    placeholder="Contact Name"
                  />
                  <Input
                    disabled={secondStepValidation}
                    name={"accountingEmail"}
                    type={"text"}
                    required={false}
                    value={accountEmail}
                    onChange={event => setAccountEmail(event.target.value)}
                    icon="email"
                    placeholder="Accounting email"
                  />
                </ValidationWrapper>
                <CheckButton
                  disabled={secondStepValidation}
                  name={"check"}
                  type={"checkbox"}
                  value={"no"}
                  className={"slider"}
                  wrapperClass={"switch-container"}
                  text={
                    <span>
                      I have read the sites{"  "}
                      <Link to={"/privacy-policy"} target="_blank">
                        privacy policy
                      </Link>
                    </span>
                  }
                />
                <div id="session-messages-container" className={""}>
                  <Messages target={"register"} />
                </div>
              </div>
            </div>
            <div className="section-control register">
              <Button
                className={"button btn-orange previous"}
                type={"submit"}
                onClick={e => {
                  e.preventDefault();
                  props.setSecondStep(false);
                  props.setFirstStep(true);
                }}
                text={"Previous"}
              />

              <Button
                className={`button ${(!secondStepValidation &&
                  "btn-orange" &&
                  !props.locked &&
                  " btn-orange") ||
                  ""} `}
                type={"submit"}
                onClick={handleSubmit}
                text={`${(!props.locked && "Register") || "Processing..."}`}
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={recaptchaKey}
              />
            </div>
          </section>
        </CSSTransition>
      </Form>
    </>
  );
};

export default Register;
