import messagesActions from "../../messages/actions";
import commonActions from "../../common/actions";
import apolloClient from "../../../../graphql/apolloClient";
import queryLoader from "../../../../graphql/queryLoader";
import { customerActions } from "../index";
import { exportListActions } from "../../exportList";

export default async (store, action) => {
  if (action.token) {
    store.dispatch(
      messagesActions.addMessage(
        "Unable to logout, token is present.",
        "danger"
      )
    );
    store.dispatch(commonActions.unlock());
    return;
  }

  try {
    // if(store.getState().state.customer && 'id' in store.getState().state.customer && store.getState().state.customer.id === false) return;

    const { data } = await apolloClient.mutate({
      mutation: queryLoader("userLogout"),
      variables: action
    });

    if (!data.revokeCustomerToken && !data.revokeCustomerToken.result) {
      console.log("GraphQL response", data);
      throw new Error("Unable to revoke customer token.");
    }

    if (data.revokeCustomerToken.result) {
      store.dispatch(customerActions.clearCustomerInformation());
      store.dispatch(exportListActions._reduceExportListLogout());
      store.dispatch(commonActions.unlock());
      if (!action.hideMessage) {
        store.dispatch(
          messagesActions.addMessage("You have logged out.", "success")
        );
      }
    }
  } catch (error) {
    store.dispatch(customerActions.clearCustomerInformation());
    if (action.logoutMessage !== null) {
      store.dispatch(
        messagesActions.addMessage(
          "Your session has expired. Please login to resume.",
          "danger"
        )
      );
    } else {
      store.dispatch(
        messagesActions.addMessage(action.logoutMessage, "danger")
      );
    }

    store.dispatch(commonActions.unlock());
    return;
  }
};
