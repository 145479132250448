export default (state, action) => {
  let items = state.items;
  let newItems = [];
  Object.keys(items).map((item, index) => {
    if (items[index] !== null) {
      if (items[index].listId !== action.listId) {
        newItems.push(items[index]);
      }
    }
  });

  return Object.assign({}, state, { items: newItems });
};
