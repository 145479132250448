import apolloClient from "../../../../graphql/apolloClient";
import customerActions from "../actions";
// import customerActionTypes from "../types";
import queryLoader from "../../../../graphql/queryLoader";
import messagesActions from "../../messages/actions";
import queryActions from "../../queries/actions";
// const LOGOUT_CUSTOMER = customerActionTypes.LOGOUT_CUSTOMER;
const customerMutation = queryLoader("registerCustomer");
const addressMutation = queryLoader("createCustomerAddress");

export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: customerMutation,
      variables: {
        input: action.request
      }
    });

    if (!data.createCustomer || !data.createCustomer.customer) {
      console.log("GraphQL response", data);
      throw new Error("No customer information retrieved.");
    }

    const tokenResult = await apolloClient.mutate({
      mutation: queryLoader("userLogin"),
      variables: {
        email: action.request.email,
        password: action.request.password
      }
    });

    await store.dispatch(
      customerActions.setCustomerTokenRegistration(
        tokenResult.data.generateCustomerToken.token
      )
    );

    const address = await apolloClient.mutate({
      mutation: addressMutation,
      variables: { input: action.address }
    });

    if (!address.data || !address.data.createCustomerAddress) {
      console.log("GraphQL response", data);
      throw new Error("No address information retrieved.");
    }

    store.dispatch(customerActions.logoutCustomer(true));

    store.dispatch(queryActions.removeQuery());

    return new Promise(function(resolve, reject) {
      resolve(true);
    });
  } catch (error) {
    store.dispatch(
      messagesActions.addMessage(error.toString(), "danger", "register")
    );
    store.dispatch(customerActions._reduceCustomer());
    return new Promise(function(resolve, reject) {
      reject(error.toString());
    });
  }
};
