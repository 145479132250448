import React from "react";
import CompoundSlider from "../../compoundSlider";
import { isEmpty, isUndefined } from "../../../state/helper/functions";
import { findByName, findById } from "../../../state/helper/category";
import Filter from "./filter";
import { filterConfiguration } from "../../../state/config/category/filters";

export const generateFilterRender = (
  props,
  category,
  filtered_category,
  selected_category,
  addFilter
) => {
  const { initialFilters } = category;
  return Object.keys(initialFilters).map(item => {
    let _filter = initialFilters[item];
    if (_filter.type === "category") {
      let values = _filter.values(props);
      return (
        <div
          key={_filter.attribute_code}
          className={`filter-by-category ${Object.keys(_filter.values).length >=
            5 && "overflow-on"} filter-content `}
        >
          {isEmpty(values) && <React.Fragment />}
          {!isEmpty(values) && (
            <Filter
              key={_filter.attribute_code}
              filter={_filter}
              filter_id={"category"}
              filters={values}
              title={"Shop by Category"}
              listWithCloseButton={true}
              selected_category={selected_category}
              addFilter={addFilter}
              products={filtered_category.items}
              mainWrapper={true}
            />
          )}
        </div>
      );
    }
    if (_filter.type === "select") {
      return (
        <div
          key={_filter.attribute_code}
          className={`filter-${_filter.attribute_code}`}
        >
          <Filter
            filter={_filter}
            title={_filter.label}
            filters={_filter.values(props)}
            filter_id={"brand"}
            addFilter={addFilter}
            products={filtered_category.items}
          />
        </div>
      );
    }
    if (_filter.type === "range") {
      return (
        <div
          key={_filter.attribute_code}
          className={`filter-${_filter.attribute_code}`}
        >
          <Filter title={`${_filter.label}`} filter={_filter}>
            <CompoundSlider
              {..._filter}
              items={filtered_category.items}
              step={1}
              useInputs={true}
              valueChangeHandler={_filter.valueChangeHandler}
              addFilter={addFilter}
            />
          </Filter>
        </div>
      );
    }
    return "";
  });
};

export const getFiltersRender = (
  filters,
  selected_category,
  filtered_category,
  addFilter,
  removeFilter,
  allCategories,
  activeFilters
) => {
  // hardcoded filters, needs to search through configuration for correct display type
  let _filters = filters;
  let brandsTree = findByName("Brands", allCategories.children);
  let brands = [];

  if (isUndefined(filters[3])) {
    Object.keys(_filters).map(index => {
      if (_filters[index].attribute === "category_id") {
        _filters[index].items.map(item => {
          let foundAsBrand = findById(
            parseInt(item.value),
            brandsTree.children
          );
          if (!isUndefined(foundAsBrand))
            brands.push({
              label: foundAsBrand.name,
              value: foundAsBrand.id.toString()
            });
        });
      }
    });
    _filters.push({ attribute: "brand", label: "Brands", items: brands });
  }

  return Object.keys(_filters).map(index => {
    let _filter = _filters[index];
    let values = _filter.items;
    if (_filter.attribute === "category_id") {
      let attribute = "category_id";
      let filterTitle =
        _filter.attribute === "category_id"
          ? "Shop By Category"
          : "Shop By Brands";
      return (
        <div
          key={_filter.attribute}
          className={`filter-by-category ${Object.keys(values).length >= 5 &&
            "overflow-on"} filter-content `}
        >
          {isEmpty(values) && <React.Fragment />}
          {!isEmpty(values) && (
            <Filter
              key={`${attribute + "-" + index}`}
              filter={_filter}
              filter_id={"category"}
              filters={_filter.items}
              title={filterTitle}
              listWithCloseButton={true}
              selected_category={selected_category}
              addFilter={addFilter}
              products={filtered_category.items}
              mainWrapper={true}
              allCategories={allCategories}
              activeFilters={activeFilters.items}
              removeFilter={removeFilter}
            />
          )}
        </div>
      );
    }
    if (_filter.attribute === "brand") {
      let { attribute } = _filter;
      return (
        <div key={_filter.attribute} className={`filter-${_filter.attribute}`}>
          <Filter
            key={`${attribute + "-" + index}`}
            filter={_filter}
            title={_filter.label}
            filters={values}
            filter_id={"brand"}
            addFilter={addFilter}
            products={filtered_category.items}
            allCategories={allCategories}
            multi={true}
            activeFilters={activeFilters.items}
            removeFilter={removeFilter}
          />
        </div>
      );
    }
    if (
      _filter.attribute === "price" ||
      _filter.attribute === "warehouse_qty"
    ) {
      if (
        !isUndefined(_filter.items) &&
        !isEmpty(_filter.items) &&
        _filter.items[0].value !== _filter.items[1].value &&
        _filter.items[0].value !== null &&
        _filter.items[1].value !== null
      ) {
        return (
          <div
            key={_filter.attribute}
            className={`filter-${_filter.attribute}`}
          >
            <Filter
              title={`${_filter.label}`}
              filter={_filter}
              allCategories={allCategories}
            >
              <CompoundSlider
                {..._filter}
                items={filtered_category.items}
                minId={`min-${_filter.attribute}`}
                maxId={`max-${_filter.attribute}`}
                step={1}
                useInputs={true}
                valueChangeHandler={
                  filterConfiguration[_filter.attribute].valueChangeHandler
                }
                addFilter={addFilter}
                initialValues={{
                  min: _filter.items[0].value,
                  max: _filter.items[1].value
                }}
              />
            </Filter>
          </div>
        );
      }
    }
  });
};
