import React from "react";
import ListingStateContainer from "./listing-state-container";
import ReactLoader from "../../../../utils/loader";
import queryLoader from "../../../../state/graphql/queryLoader";
import { useQuery } from "@apollo/react-hooks";
import { isEmpty } from "../../../../state/helper/functions";

const query = queryLoader("products");

const ListingQueryContainer = props => {
  let { variables } = props;

  variables["pageSize"] = 300;

  const { loading, error, data } = useQuery(query, {
    variables: variables,
    skip: !variables
  });

  if (loading) {
    return <ReactLoader />;
  }

  if (error) {
    return error.message;
  }
  if (isEmpty(data.products.items)) return <h2>This list is empty.</h2>;
  return <ListingStateContainer {...props} items={data.products.items} />;
};

export default ListingQueryContainer;
