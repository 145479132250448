import { isEmpty } from "../../../../state/helper/functions";

export const calculateProductsByAttribute = (products, attribute, value) => {
  let count = 0;
  let _attribute = attribute;
  products.map(element => {
    if (typeof element[attribute] !== "object") {
      if (element[attribute] === value) {
        return count++;
      }
    }

    if (attribute === "category") {
      _attribute = "categories";
    }
    if (!isEmpty(element[_attribute]) && element[_attribute] !== null) {
      element[_attribute].map(_element => {
        if (_element.id === parseInt(value)) {
          return count++;
        }
        return true;
      });
    }
    return true;
  });
  return count;
};
