import React, { useState, useEffect } from "react";
import Listing from "./listing";

const ListingStateContainer = props => {
  let { items } = props;
  let _itemStates = [];
  const [itemStates, setItemStates] = useState(_itemStates);

  useEffect(() => {
    items.map(item => {
      return _itemStates.push({
        id: item.id,
        name: item.name,
        qty: props.universalQty,
        zip: props.universalZip
      });
    });
    setItemStates(_itemStates);
  }, [props.universalQty, props.universalZip, _itemStates, items]);
  return (
    <Listing {...props} itemStates={itemStates} setItemStates={setItemStates} />
  );
};

export default ListingStateContainer;
