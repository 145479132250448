import React, { useState, useEffect } from "react";
import ProductItem from "./productItem";

const ProductItemStateContainer = props => {
  let { item, itemStates, setItemStates } = props;
  const [shipQty, setShipQty] = useState(props.universalQty);
  const [shipTo, setShipTo] = useState(props.universalZip);
  useEffect(() => {
    props.itemStates.map(
      item => setShipQty(props.universalQty),
      setShipTo(props.universalZip)
    );
    //eslint-disable-next-line
  }, [props.universalQty, props.universalZip]);

  const manageItemState = (value, target) => {
    let item_id = item.id;
    if (target === "qty") {
      setShipQty(value);
      Object.keys(itemStates).map(index => {
        if (itemStates[index].id === item_id) {
          itemStates[index][target] = value;
        }
      });
    } else {
      setShipTo(value);
      Object.keys(itemStates).map(index => {
        if (itemStates[index].id === item_id) {
          itemStates[index][target] = value;
        }
      });
    }
    setItemStates(itemStates);
    props.addExportListData(itemStates);
  };
  return (
    <ProductItem
      {...props}
      shipQty={shipQty}
      setShipQty={setShipQty}
      shipTo={shipTo}
      setShipTo={setShipTo}
      manageItemState={manageItemState}
    />
  );
};

export default ProductItemStateContainer;
