import React, { useState } from "react";
import CustomerExportList from "./exportList";
import { filterProjectById, deleteByIDFn } from "./functions";

const CustomerExportListState = props => {
  //const forceUpdate = useForceUpdate();
  const [universalQty, setUniversalQty] = useState(1);
  const [universalZip, setUniversalZip] = useState(10001);
  const [project, setProject] = useState(props.project);
  const [selectedProject, setSelectedProject] = useState(props.project);
  // const [projectsList, setProjectsList] = useState({
  //   data: props.projectsList
  // });
  const [projectName, setProjectName] = useState(props.projectsList[0].name);

  const [firstItem] = useState(props.projectsList[0].id);

  const changeProjectHandler = id => {
    setProject(id);
    setProjectName(filterProjectById(props.projectsList, id).name);
    setSelectedProject(filterProjectById(props.projectsList, id));
  };

  const changeProjectInformation = name => {
    // let result = changeProjectInformationFn(projectsList, project, name);
    // setProjectsList(result.projects);
    // forceUpdate();
  };

  const createProjectHandler = name => {
    // let result = createProjectHandlerFn(projectsList, name);
    // setProjectsList(result.projectsList);
    // setProject(result.id);
    // setProjectName(result.name);
    // setSelectedProject(filterProjectById(result.projectsList, result.id));
    // changeProjectHandler(result.id);
  };

  const deleteByID = id => {
    let fictional = props.projectsList;
    let result = deleteByIDFn(fictional, id);
    //setProjectsList(result.props.projectsList);
    changeProjectHandler(result.select);
  };

  const updateAllQtyZip = (qty, zip) => {
    if (qty[0] === undefined) {
      setUniversalQty(qty);
    } else {
      setUniversalQty(qty[0]);
    }
    if (zip[0] === undefined) {
      setUniversalZip(zip);
    } else {
      setUniversalZip(zip[0]);
    }
  };

  return (
    <CustomerExportList
      {...props}
      firstItem={firstItem}
      project={project}
      selectedProject={selectedProject}
      projectName={projectName}
      setProjectName={setProjectName}
      projectsList={{ data: props.projectsList }}
      changeProjectHandler={changeProjectHandler}
      createProjectHandler={createProjectHandler}
      changeProjectInformation={changeProjectInformation}
      deleteByID={deleteByID}
      updateAllQtyZip={updateAllQtyZip}
      universalQty={universalQty}
      universalZip={universalZip}
    />
  );
};

export default CustomerExportListState;
