import types from "./types";

const getExportList = listId => {
  return {
    type: types.GET_EXPORT_LIST,
    listId: listId
  };
};

const getCustomerExportList = () => {
  return {
    type: types.GET_CUSTOMER_EXPORT_LIST
  };
};

const getCustomerExportListSuccess = data => {
  return {
    type: types.GET_CUSTOMER_EXPORT_LIST_SUCCESS,
    data: data
  };
};

const createExportList = name => {
  return {
    type: types.CREATE_EXPORT_LIST,
    name: name
  };
};

const createExportListSuccess = data => {
  return {
    type: types.CREATE_EXPORT_LIST_SUCCESS,
    data: data
  };
};

const renameExportList = (listId, name) => {
  return {
    type: types.RENAME_EXPORT_LIST,
    listId: listId,
    name: name
  };
};

const renameExportListSuccess = data => {
  return {
    type: types.RENAME_EXPORT_LIST_SUCCESS,
    data
  };
};

const deleteExportList = listId => {
  return {
    type: types.DELETE_EXPORT_LIST,
    listId: listId
  };
};

const deleteExportListSuccess = listId => {
  return {
    type: types.DELETE_EXPORT_LIST_SUCCESS,
    listId: listId
  };
};

const removeItemFromList = (sku, listId) => {
  return {
    type: types.REMOVE_ITEM_FROM_LIST,
    sku: sku,
    listId: listId
  };
};

const exportList = (listId, data) => {
  return {
    type: types.EXPORT_LIST,
    listId: listId,
    data: data
  };
};
const createExportListName = name => {
  return {
    type: types.CREATE_EXPORT_LIST_NAME,
    name: name
  };
};
const addSkuToList = (listId, sku, name) => {
  return {
    type: types.ADD_SKU_TO_LIST,
    sku: sku,
    listId: listId,
    name: name
  };
};
const addSkuToListSuccess = data => {
  return {
    type: types.ADD_SKU_TO_LIST_SUCCESS,
    data
  };
};
const removeSkuFromList = (listId, sku, name) => {
  return {
    type: types.REMOVE_SKU_FROM_LIST,
    sku: sku,
    listId: listId,
    name: name
  };
};
const removeSkuFromListSuccess = data => {
  return {
    type: types.REMOVE_SKU_FROM_LIST_SUCCESS,
    data: data
  };
};

const exportListData = data => {
  return {
    type: types.EXPORT_LIST_DATA,
    data: data
  };
};

const _reduceCSVData = data => {
  return {
    type: types._REDUCE_CSV_DATA,
    data: data
  };
};
const saveListBtn = data => {
  return {
    type: types.SAVE_EXPORT_LIST_BTN,
    data: data
  };
};

const saveListBtnSuccess = data => {
  return {
    type: types.SAVE_EXPORT_LIST_BTN_SUCCESS,
    data: data
  };
};

const removeAllItemsFromList = listId => {
  return {
    type: types.REMOVE_ALL_ITEMS_FROM_LIST,
    listId: listId
  };
};

const _reduceExportList = data => {
  return {
    type: types._REDUCE_EXPORT_LIST,
    data: data
  };
};

const _reduceExportListLogout = data => {
  return {
    type: types._REDUCE_EXPORT_LIST_LOGOUT,
    data: data
  };
};

const toggleExportListDownloadAvailability = newStatus => {
  return {
    type: types.TOGGLE_EXPORT_LIST_DOWNLOAD_AVAILABILITY,
    status: newStatus
  };
};

const pendingExportList = status => {
  return {
    type: types.PENDING_EXPORT_LIST,
    status: status
  };
};

export default {
  getExportList,
  getCustomerExportList,
  getCustomerExportListSuccess,
  createExportList,
  createExportListSuccess,
  createExportListName,
  renameExportList,
  renameExportListSuccess,
  deleteExportList,
  exportList,
  deleteExportListSuccess,
  removeItemFromList,
  addSkuToList,
  addSkuToListSuccess,
  removeSkuFromList,
  removeSkuFromListSuccess,
  exportListData,
  _reduceCSVData,
  saveListBtn,
  saveListBtnSuccess,
  removeAllItemsFromList,
  _reduceExportList,
  _reduceExportListLogout,
  toggleExportListDownloadAvailability,
  pendingExportList
};
